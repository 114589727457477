import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { makeStyles } from "tss-react/mui";
import { subStringEx } from "../../helpers/helpers";
import { getMostRecentUpdatedTasks } from "../../services/tasksService";
import { claimsState } from "../../state/claimsState";
import LoadingIndicator from "../LoadingIndicator";
import StandardTable from "../StandardTable";
import TaskStatusChip from "../Tasklist/TaskStatusChip";

const useStyles = makeStyles()((theme) => ({
  root: {
    "& .MuiTableCell-root": {
      fontSize: "12px;",
    },
  },
}));

export default function LastUpdatedTasks(props) {
  const [claims] = useRecoilState(claimsState);
  const [isLoading, setIsloading] = useState(false);
  const [tasks, setTasks] = useState([]);
  const {classes} = useStyles();

  useEffect(() => {
    setIsloading(true);

    getMostRecentUpdatedTasks(claims.companyId).then((tasks) => {
      setTasks(tasks);
      setIsloading(false);
    });
  }, [claims.companyId]);

  let columns = [
    {
      id: "name",
      Header: "Task Name",
      accessor: "name",
      Cell: ({ cell }) => (
        <div title={cell.row.values.name}>
          {subStringEx(cell.row.values.name, 20)}
        </div>
      ),
    },
    {
      id: "client",
      Header: "Client",
      accessor: "client",
      Cell: ({ cell }) => (
        <div title={cell.row.values.client}>
          {subStringEx(cell.row.values.client, 20)}
        </div>
      ),
    },
    {
      id: "status",
      Header: "Status",
      accessor: "status",
      Cell: ({ cell }) => (
        <TaskStatusChip
          compact="true"
          label={cell.row.values.status}
        ></TaskStatusChip>
      ),
    },
    {
      id: "assigned",
      Header: "Assigned",
      accessor: "assigned",
    },
    {
      id: "updatedAt",
      Header: "Updated",
      accessor: "updatedAt",
    },
  ];

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <div className={classes.root}>
      <Typography variant="h6">Last 10 updated Tasks</Typography>
      <StandardTable
        canSearch={false}
        data={tasks}
        columns={columns}
        //title="Last"
        canEdit={false}
        canAdd={false}
        canDelete={false}
      />
    </div>
  );
}
