import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { useRecoilState } from "recoil";
import { makeStyles } from "tss-react/mui";
import ErrorIndicator from "../../../components/ErrorIndicator";
import { checkUserExists } from "../../../services/authService";
import { addUserToCompanyByEmail } from "../../../services/companyService";
import { checkInviteExist, inviteUser } from "../../../services/invitesService";
import { claimsState } from "../../../state/claimsState";

const useStyles = makeStyles()((theme) => ({
  paper: {
    width: "auto",
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(620 + theme.spacing(6))]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto",
    },
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing(2)} ${theme.spacing(3)} ${theme.spacing(3)}`,
  },
  form: {
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: `100%`,
  },
}));

const UsersInvite = (props) => {
  const { onClose } = props;
  const [claims] = useRecoilState(claimsState);
  const {classes} = useStyles();
  const [email, setEmail] = useState("");
  const [inviteSent, setInviteSent] = useState(false);
  const [userAddedToOrg, setUserAddedToOrg] = useState(false);
  const [inviteAlreadyExist, setInviteAlreadyExist] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleInvite = (event) => {
    event.preventDefault();

    setLoading(true);
    setError({ message: "" });

    checkUserExists(email).then((exists) => {
      if (exists) {
        addUserToCompanyByEmail(claims.companyId, email)
          .then((result) => {
            setUserAddedToOrg(true);
            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
            setError({ message: "Could not invite user" });
            setLoading(false);
          });
      } else {
        checkInviteExist(claims.companyId, email).then((exists) => {
          if (!exists) {
            inviteUser(claims.companyId, email)
              .then((invite) => {
                setInviteSent(true);
                setLoading(false);
              })
              .catch((err) => {
                console.error(err);
                setError({ message: "Could not invite user" });
                setLoading(false);
              });
          } else {
            setInviteAlreadyExist(true);
            setLoading(false);
          }
        });
      }
    });
  };

  if (userAddedToOrg) {
    //Existing user was added to company
    return (
      <Paper className={classes.paper} elevation={6}>
        <div className={classes.container}>
          <Typography component="h1" variant="h6">
            User successfully added to {claims.companyName}
          </Typography>
          <Button
            onClick={onClose}
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Close
          </Button>
        </div>
      </Paper>
    );
  }

  if (inviteSent) {
    //First invite was sent to email address
    return (
      <Paper className={classes.paper} elevation={6}>
        <div className={classes.container}>
          <Typography component="h1" variant="h6">
            Invite link successfully sent to {email}
          </Typography>
          <Button
            onClick={onClose}
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Close
          </Button>
        </div>
      </Paper>
    );
  }

  if (inviteAlreadyExist) {
    //No action as invite already existed
    return (
      <Paper className={classes.paper} elevation={6}>
        <div className={classes.container}>
          <Typography component="h1" variant="h6">
            An invite already exists for {email}
          </Typography>
          <Button
            onClick={onClose}
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Close
          </Button>
        </div>
      </Paper>
    );
  }

  return (
    <Paper className={classes.paper} elevation={6}>
      <div className={classes.container}>
        <ValidatorForm className={classes.form} onSubmit={handleInvite}>
          <TextValidator
            value={email}
            onInput={(e) => setEmail(e.target.value)}
            variant="outlined"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            autoFocus
            validators={["required", "isEmail"]}
            errorMessages={["this field is required", "email is not valid"]}
          />
          <ErrorIndicator message={error.message} />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={loading}
            className={classes.submit}
          >
            Invite Now
          </Button>
        </ValidatorForm>
      </div>
    </Paper>
  );
};

export default UsersInvite;
