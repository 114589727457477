import { db, auth, analytics } from "./firestore";
import firebase from "firebase/compat/app";
import moment from "moment";
import { getFromCache } from "../cache/cache";
import { getFinancialYear } from "../helpers/helpers";

export const createTask = (task) => {
  const companyDocRef = firebase
    .firestore()
    .collection("companies")
    .doc(task.companyRef);

  const assignedDocRef = firebase
    .firestore()
    .collection("users")
    .doc(task.assignedRef);

  if (task.clientRef) {
    const clientDocRef = firebase
      .firestore()
      .collection("clients")
      .doc(task.clientRef);
    task.clientRef = clientDocRef;
  }

  task.companyRef = companyDocRef;
  task.assignedRef = assignedDocRef;

  task.due = firebase.firestore.Timestamp.fromDate(task.due);
  task.createdAt = firebase.firestore.FieldValue.serverTimestamp();
  task.createdBy = firebase
    .firestore()
    .collection("users")
    .doc(auth.currentUser.uid);

  return firebase.firestore().collection("tasks").add(task);
};

export const assignTask = (task) => {
  analytics.logEvent("task_updated", { reason: "assigned" });

  const assignedDocRef = firebase
    .firestore()
    .collection("users")
    .doc(task.assignedRef);

  task.assignedByUser = true;
  task.assignedRef = assignedDocRef;
  task.openOptions = false;
  task.updatedAt = firebase.firestore.FieldValue.serverTimestamp();
  task.updatedBy = firebase
    .firestore()
    .collection("users")
    .doc(auth.currentUser.uid);

  return firebase.firestore().collection("tasks").doc(task.uid).update(task);
};

export const updateTask = (task, reason) => {
  analytics.logEvent("task_updated", { reason: reason });

  task.openOptions = false;

  if (task.timeEntries !== undefined) {
    task.timeEntries.forEach((entry) => {
      delete entry.isDirty;
    });
  }

  task.updatedAt = firebase.firestore.FieldValue.serverTimestamp();
  task.updatedBy = firebase
    .firestore()
    .collection("users")
    .doc(auth.currentUser.uid);

  return firebase.firestore().collection("tasks").doc(task.uid).update(task);
};

export const deleteTask = (task) => {
  const taskRef = firebase.firestore().collection("tasks").doc(task.uid);

  return taskRef.delete();
};

export const getMostRecentUpdatedTasks = (companyId) => {
  const companyDocRef = firebase
    .firestore()
    .collection("companies")
    .doc(companyId);

  let query = db
    .collection("tasks")
    .where("companyRef", "==", companyDocRef)
    .orderBy("updatedAt", "desc")
    .limit(10);

  let cacheKey = "Company_Tasks_" + companyId + "_last10";

  return getFromCache(cacheKey, query, (snapshot) => {
    return snapshot.docs.map((taskRef) => {
      let dueDate = moment(taskRef.data().due.toDate());

      let task = taskRef.data();

      let elapsedTime = 0;

      if (task.timeEntries !== undefined) {
        let userEntries = task.timeEntries.filter((entry) => {
          return entry.recordedBy.id === auth.currentUser.uid;
        });

        userEntries.forEach(function (entry, index) {
          let eTime = entry.endTime - entry.startTime;
          elapsedTime = elapsedTime + eTime;

          entry.startTime = entry.startTime.toDate();
          entry.endTime = entry.endTime.toDate();
          entry.id = index;
          entry.duration = entry.endTime - entry.startTime;
        });

        task.duration = elapsedTime;
      } else {
        task.duration = 0;
      }

      task.updatedAt = moment(task.updatedAt.toDate()).fromNow();

      return {
        ...task,
        uid: taskRef.id,
        dueDate: dueDate.format("MMM Do"),
      };
    });
  });
};

export const getAllTasksForClient = (clientId, year) => {
  const clientDocRef = firebase.firestore().collection("clients").doc(clientId);

  let query = db
    .collection("tasks")
    .where("clientRef", "==", clientDocRef)
    .orderBy("due");

  if (year !== undefined) {
    //Only return Tasks for the financial year
    let financialYear = getFinancialYear(year);

    query = query.where("due", ">=", financialYear.from);
    query = query.where("due", "<=", financialYear.to);
  }

  let cacheKey = "Client_Tasks_" + clientId + "_" + year;

  return getFromCache(cacheKey, query, (snapshot) => {
    return snapshot.docs.map((taskRef) => {
      let dueDate = moment(taskRef.data().due.toDate());

      let task = taskRef.data();

      let elapsedTime = 0;

      if (task.timeEntries !== undefined) {
        let userEntries = task.timeEntries.filter((entry) => {
          return entry.recordedBy.id === auth.currentUser.uid;
        });

        userEntries.forEach(function (entry, index) {
          let eTime = entry.endTime - entry.startTime;
          elapsedTime = elapsedTime + eTime;

          entry.startTime = entry.startTime.toDate();
          entry.endTime = entry.endTime.toDate();
          entry.id = index;
          entry.duration = entry.endTime - entry.startTime;
        });

        task.duration = elapsedTime;
      } else {
        task.duration = 0;
      }

      return {
        ...task,
        uid: taskRef.id,
        dueDate: dueDate.format("MMM Do, 'YY"),
      };
    });
  });
};

export const getTasksSnapshot = (companyId, where, callback) => {
  const companyDocRef = firebase
    .firestore()
    .collection("companies")
    .doc(companyId);

  let currrentDate = new Date();

  var firstDayOfLastMonth = new Date(
    currrentDate.getFullYear(),
    currrentDate.getMonth() - 1,
    1
  );

  var lastDayOfMonth = new Date(
    currrentDate.getFullYear(),
    currrentDate.getMonth() + 2,
    0
  );

  let tasksRef = db
    .collection("tasks")
    .where("companyRef", "==", companyDocRef)
    .where("due", "<", lastDayOfMonth)
    .where("due", ">=", firstDayOfLastMonth)
    .orderBy("due");

  where.forEach((element) => {
    tasksRef = tasksRef.where(element.field, "==", element.value);
  });

  let filterOnClient = where.filter((item) => {
    return item.field === "client";
  });

  if (filterOnClient.length === 0) {
    tasksRef = tasksRef.orderBy("client");
  }

  return tasksRef.onSnapshot(function (querySnapshot) {
    var tasks = querySnapshot.docs.map((taskRef) => {
      let dueDate = moment(taskRef.data().due.toDate());

      let task = taskRef.data();

      let elapsedTime = 0;

      if (task.timeEntries !== undefined) {
        let userEntries = task.timeEntries.filter((entry) => {
          return entry.recordedBy.id === auth.currentUser.uid;
        });

        userEntries.forEach(function (entry, index) {
          let eTime = entry.endTime - entry.startTime;
          elapsedTime = elapsedTime + eTime;

          entry.startTime = entry.startTime.toDate();
          entry.endTime = entry.endTime.toDate();
          entry.id = index;
          entry.duration = entry.endTime - entry.startTime;
        });

        task.duration = elapsedTime;
      } else {
        task.duration = 0;
      }

      return { ...task, uid: taskRef.id, dueDate: dueDate.format("MMM Do") };
    });

    var firstDayOfThisMonth = new Date(
      currrentDate.getFullYear(),
      currrentDate.getMonth(),
      1
    );
    //Remove Completed Tasks from Previous Month
    let filteredTasks = tasks.filter((task) => {
      return !(
        task.status === "completed" && task.due.toDate() < firstDayOfThisMonth
      );
    });

    callback(filteredTasks);
  });
};
