import React, { useState, useEffect } from "react";
import { getUsers } from "../services/userService";
import { claimsState } from "../state/claimsState";
import { useRecoilState } from "recoil";
import Dropdown from "./Dropdown";

export default function AssignedDropdown(props) {
  const { assignedRef, className, onChange, disabled } = props;
  const [users, setUsers] = useState([]);
  const [claims] = useRecoilState(claimsState);
  const [defaultValue, setDefaultValue] = useState(assignedRef);

  function userComparer(a, b) {
    if (a.display > b.display) return -1;
    if (b.display > a.display) return 1;

    return 0;
  }

  useEffect(() => {
    getUsers(claims.companyId).then((users) => {
      let uu = users.map((user) => {
        return { value: user.uid, display: user.displayName };
      });

      uu.sort(userComparer);

      setUsers(uu);

      if (assignedRef === undefined) {
        handleOnChange(users[0].uid, users[0].displayName);
      } else {
        let defaultUser = users.find((user) => user.uid === assignedRef);
        if (defaultUser !== undefined) {
          handleOnChange(defaultUser.uid, defaultUser.displayName);
        } else {
          setDefaultValue(assignedRef);
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignedRef, claims.companyId]);

  function handleOnChange(value, displayName) {
    let name =
      displayName !== undefined
        ? displayName
        : users.find((c) => c.value === value).display;
    onChange(value, name);
    setDefaultValue(value);
  }

  return (
    <Dropdown
      name="assigned"
      label="Assigned"
      disabled={disabled}
      className={className}
      value={defaultValue}
      items={users}
      onChange={handleOnChange}
    />
  );
}
