export function groupBy(xs, key) {
  return xs.reduce(function (rv, x) {
    let v = key instanceof Function ? key(x) : x[key];
    let el = rv.find((r) => r && r.key === v);
    if (el) {
      el.values.push(x);
    } else {
      rv.push({ key: v, values: [x] });
    }

    return rv;
  }, []);
}

export function valueOrDefault(value, defaultValue) {
  return value !== undefined ? value : defaultValue;
}

export function sum(items, prop) {
  return items.reduce(function (a, b) {
    if (b[prop] !== undefined) return a + b[prop];
    else return a;
  }, 0);
}

export function toHashMap(array, key) {
  var _hashMap = {},
    getKey =
      key instanceof Function
        ? key
        : function (_obj) {
            return _obj[key];
          };
  array.forEach(function (obj) {
    _hashMap[getKey(obj)] = obj;
  });
  return _hashMap;
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function subStringEx(string, length) {
  if (string.length <= length) return string;

  return string.substring(0, length - 3) + "...";
}

export function toCurrency(number) {
  if (number) {
    let numberString = number.toFixed(2);

    if (numberString.length > 6) {
      const startIndex = numberString.length - 6;

      numberString =
        numberString.slice(0, startIndex) +
        " " +
        numberString.slice(startIndex);
    }

    return "R " + numberString;
  }
  else {
    return "-";
  }
}

export function getFinancialYear(year) {
  if (year === undefined) {
    year = new Date().getFullYear();
  }

  let from = new Date(year + "-01-01");
  let to = new Date(year + 1 + "-02-29");

  return { from: from, to: to };
}
