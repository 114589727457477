import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/analytics";
import 'firebase/compat/storage';
import 'firebase/compat/auth';
import 'firebase/compat/functions'

const config = {
  development: {
    apiKey: "AIzaSyBXnaF-xAHUHCMDUKfcGy81Om4P7tzo6X4",
    authDomain: "accountuit-prod.firebaseapp.com",
    databaseURL: "https://accountuit-prod.firebaseio.com",
    projectId: "accountuit-prod",
    storageBucket: "accountuit-prod.appspot.com",
    messagingSenderId: "1021187644509",
    appId: "1:1021187644509:web:15cb626f65e882828941b4",
    measurementId: "G-81W2HMQ8WM"
  //  apiKey: "AIzaSyCGjzkpuYry95MTV5W62KheY5emz23VZPI",
  //   authDomain: "accountuit-dev.firebaseapp.com",
  //   databaseURL: "https://accountuit-dev.firebaseio.com",
  //   projectId: "accountuit-dev",
  //   storageBucket: "accountuit-dev.appspot.com",
  //   messagingSenderId: "701012384177",
  //   appId: "1:701012384177:web:11744d1486f5fc208c8590",
  //   measurementId: "G-6MGLT487HV",
  },
  production: {
    apiKey: "AIzaSyBXnaF-xAHUHCMDUKfcGy81Om4P7tzo6X4",
    authDomain: "accountuit-prod.firebaseapp.com",
    databaseURL: "https://accountuit-prod.firebaseio.com",
    projectId: "accountuit-prod",
    storageBucket: "accountuit-prod.appspot.com",
    messagingSenderId: "1021187644509",
    appId: "1:1021187644509:web:15cb626f65e882828941b4",
    measurementId: "G-81W2HMQ8WM"
    // apiKey: "AIzaSyCGjzkpuYry95MTV5W62KheY5emz23VZPI",
    // authDomain: "accountuit-dev.firebaseapp.com",
    // databaseURL: "https://accountuit-dev.firebaseio.com",
    // projectId: "accountuit-dev",
    // storageBucket: "accountuit-dev.appspot.com",
    // messagingSenderId: "701012384177",
    // appId: "1:701012384177:web:11744d1486f5fc208c8590",
    // measurementId: "G-6MGLT487HV",
  },
};

//alert(process.env.NODE_ENV);
firebase.initializeApp(config[process.env.NODE_ENV]);
firebase.firestore().enablePersistence({synchronizeTabs:true});

export const analytics = firebase.analytics();
export const auth = firebase.auth();
export const db = firebase.firestore();
export const storage = firebase.storage();
export const functions = firebase.functions();
