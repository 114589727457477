import React from "react";
import { useRecoilState } from "recoil";
import { claimsState } from "./state/claimsState";
import { Container } from "@mui/material";

const AdminRoute = ({Component}) => {
  const [claims] = useRecoilState(claimsState);

  const isAuthed = claims.isInRole("admin");

  return isAuthed ? (
    <Component />
  ) : (
    <Container>
      <h1>You don't have permission to view this!</h1>
    </Container>
  );
}

export default AdminRoute;
