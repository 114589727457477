import { Alert } from "@mui/material";
import React from "react";

function SuccessIndicator(props) {
  const { message } = props;

  if (!message) {
    return <div></div>;
  }
  return <Alert severity="success">{message}</Alert>;
}

export default SuccessIndicator;
