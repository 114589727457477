import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { makeStyles } from "tss-react/mui";
import {
  createPackage,
  deletePackages,
  getPackages,
  updatePackage,
} from "../../services/packagesService";
import { claimsState } from "../../state/claimsState";
import LoadingIndicator from "../LoadingIndicator";
import StandardTable from "../StandardTable";
import PackageEditor from "./PackageEditor";

const useStyles = makeStyles()((theme) => ({
  grid: {
    alignItems: "flex-start",
    justifyContent: "flex-end",
  },
  gridEditMode: {
    justifyContent: "center",
  },
  createButton: {
    margin: "5px",
  },
  paper: {},
}));

function PackagesView(props) {
  const [claims] = useRecoilState(claimsState);
  const [isLoading, setIsloading] = useState(false);
  const [packages, setPackages] = useState([]);
  const [editMode, setEditMode] = React.useState(false);
  const [editPackage, setEditPackage] = React.useState({});
  const [createMode, setCreateMode] = React.useState(false);
  const { classes } = useStyles();

  useEffect(() => {
    setIsloading(true);
    getPackages(claims.companyId).then((packages) => {
      setPackages(packages);
      setIsloading(false);
    });
  }, [claims.companyId, setPackages]);

  let onEdit = ($package) => {
    setEditMode(true);
    setEditPackage($package);
  };

  let onDelete = ($packages) => {
    if (window.confirm("Are you sure you want to delete these Packages?")) {
      setIsloading(true);
      deletePackages($packages).then(() => {
        getPackages(claims.companyId).then(($packages) => {
          setPackages($packages);
          setIsloading(false);
        });
      });
    }
  };

  let handleCreateClick = ($package) => {
    setIsloading(true);
    createPackage(claims.companyId, $package)
      .then((s) => {
        getPackages(claims.companyId).then(($packages) => {
          setPackages($packages);
          setIsloading(false);
          setCreateMode(false);
        });
      })
      .catch((e) => {
        alert(e);
        setIsloading(false);
        setCreateMode(false);
      });
  };

  let handleUpdateClick = ($package) => {
    setIsloading(true);
    updatePackage(claims.companyId, $package)
      .then((s) => {
        getPackages(claims.companyId).then(($packages) => {
          setPackages($packages);
          setIsloading(false);
          setEditMode(false);
          setEditPackage({});
        });
      })
      .catch((e) => {
        alert(e);
        setIsloading(false);
        setEditMode(false);
      });
  };

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (createMode) {
    return (
      <Grid container className={classes.gridEditMode}>
        <Grid item>
          <PackageEditor
            title="Create Package"
            onSave={handleCreateClick}
            onCancel={() => {
              setCreateMode(false);
            }}
          />
        </Grid>
      </Grid>
    );
  }

  if (editMode) {
    return (
      <Grid container className={classes.gridEditMode}>
        <Grid item>
          <PackageEditor
            title="Edit Package"
            $package={editPackage}
            onSave={handleUpdateClick}
            onCancel={() => {
              setEditMode(false);
              setEditPackage({});
            }}
          />
        </Grid>
      </Grid>
    );
  }

  let copyToClipboard = (value) => {
    const tempTextarea = document.createElement("textarea");
    tempTextarea.value = value;
    document.body.appendChild(tempTextarea);

    // Select the text
    tempTextarea.select();
    tempTextarea.setSelectionRange(0, 99999); // For mobile devices

    // Copy the text
    document.execCommand("copy");

    // Remove the temporary textarea
    document.body.removeChild(tempTextarea);
  };

  const columns = [
    {
      Header: "Name",
      accessor: "name", // accessor is the "key" in the data
      Cell: ({ cell }) => (
        <div onClick={() => copyToClipboard(cell.row.original.uid)}>
          {cell.row.values.name}
        </div>
      ),
    },
    {
      Header: "Price",
      accessor: "price",
      Cell: ({ cell }) => "R " + cell.row.values.price,
    },
    {
      Header: "No of Products",
      accessor: "products",
      Cell: ({ cell }) => cell.row.values.products.length,
    },
  ];

  return (
    <React.Fragment>
      <StandardTable
        canEdit={true}
        canDelete={true}
        canAdd={true}
        addUserHandler={() => {
          setCreateMode(true);
        }}
        onEdit={onEdit}
        deleteUserHandler={onDelete}
        title=""
        data={packages}
        columns={columns}
      />
    </React.Fragment>
  );
}

export default PackagesView;
