import { Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { makeStyles } from "tss-react/mui";
import { groupBy } from "../../helpers/helpers";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { getProfitibility } from "../../services/costingsService";
import { claimsState } from "../../state/claimsState";
import ClientsDropdown from "../ClientsDropdown";
import LoadingIndicator from "../LoadingIndicator";
import ProfitabilityFilter from "./ProfitabilityFilter";
import ProfitabilityTable from "./ProfitabilityTable";

const useStyles = makeStyles()(() => ({
  clientButton: {
    padding: 2,
    fontWeight: "bold",
  },
  clientDropdown: {
    width: "300px",
  },
}));

export default function ProfitabilityView() {
  const [filter, setFilter] = useLocalStorage("profitabilityFilter", []);
  const {classes} = useStyles();
  const location = useLocation();
  const navigate = useNavigate();

  let currrentDate = new Date();
  var firstDayOfMonth = new Date(
    currrentDate.getFullYear(),
    currrentDate.getMonth(),
    1
  );
  var lastDayOfMonth = new Date(
    currrentDate.getFullYear(),
    currrentDate.getMonth() + 1,
    0
  );

  const [fromDate, setFromDate] = useState(firstDayOfMonth);
  const [toDate, setToDate] = useState(lastDayOfMonth);
  const [client, setClient] = useState();

  const [isLoading, setIsloading] = useState(false);
  const [profitibility, setProfitibility] = useState([]);
  const [claims] = useRecoilState(claimsState);

  const [taskColumns, setTaskColumns] = useState([]);

  if (location.state && location.state.filter) {
    setFilter([location.state.filter]);
    location.state.filter = undefined;
  }

  useEffect(() => {
    setIsloading(true);
    getProfitibility(claims.companyId, fromDate, toDate, filter).then(
      (profitibility) => {
        let tempcolumns = [];

        profitibility.forEach((profitLine) => {
          profitLine[profitLine.taskName] = profitLine.totalCost;
          profitLine[profitLine.taskName + "_time"] = profitLine.totalTime;
          profitLine[profitLine.taskName + "_price"] = profitLine.price;
          profitLine[profitLine.taskName + "_diff"] =
            profitLine.price - profitLine.totalCost;
          tempcolumns.push(profitLine.taskName);
        });

        const grouped = groupBy(profitibility, (e) => {
          return e.recordedBy + e.clientName;
        });

        tempcolumns = [...new Set(tempcolumns)];

        const tempTotal = grouped.map((e) => {
          return {
            ...Object.assign({}, ...e.values),
            totalCost: sum(e.values, "totalCost"),
          };
        });

        let totalRow = Object.assign(
          {},
          ...tempcolumns.map((task) => {
            return { [task]: sum(tempTotal, task) };
          })
        );

        let budgetRow = Object.assign(
          {},
          ...tempcolumns.map((task) => {
            return { [task]: sum(tempTotal, task + "_price") };
          })
        );

        let diffRow = Object.assign(
          {},
          ...tempcolumns.map((task) => {
            return { [task]: sum(tempTotal, task + "_diff") };
          })
        );

        tempTotal.push({ recordedBy: "Total", ...totalRow });
        tempTotal.push({ recordedBy: "Budgeted", ...budgetRow });
        tempTotal.push({ recordedBy: "Difference", ...diffRow });

        setTaskColumns(tempcolumns);
        setProfitibility(tempTotal);
        setIsloading(false);
      }
    );
  }, [claims.companyId, fromDate, toDate, filter]);

  function ondateChange(date) {
    var firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    setFromDate(firstDayOfMonth);
    setToDate(lastDayOfMonth);
  }

  const sum = function (items, prop) {
    return items.reduce(function (a, b) {
      if (b[prop] !== undefined) return a + b[prop];
      else return a;
    }, 0);
  };

  function handleClientChange(value, name) {
    setClient(value);
    addToFilter("clientName", name, name);
  }

  function addToFilter(field, value, display) {
    let temp = filter.filter((item) => {
      return item.field !== field;
    });

    temp.push({
      field: field,
      value: value,
      display: display !== undefined ? display : value,
    });

    setFilter(temp);

    return temp;
  }

  function removeFromFilter(value) {
    let temp = filter.filter((item) => {
      return item.value !== value;
    });

    setFilter(temp);
  }

  let onRecordedByClick = (recordedBy) => {
    let temp = filter;

    temp.push({
      field: "recordedByName",
      value: recordedBy,
      display: recordedBy,
    });

    navigate("/timesheet", { state: { filter: temp } });
  };

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <React.Fragment>
      <Stack direction="row" sx={{ paddingLeft: 1 }}>
        <ClientsDropdown
          client={client}
          className={classes.clientDropdown}
          onChange={handleClientChange}
        />
        <ProfitabilityFilter
          filter={filter}
          onClick={removeFromFilter}
          fromDate={fromDate}
          toDate={toDate}
          dateChanged={ondateChange}
        ></ProfitabilityFilter>
      </Stack>

      <ProfitabilityTable
        items={profitibility}
        taskColumns={taskColumns}
        onClick={onRecordedByClick}
      ></ProfitabilityTable>
    </React.Fragment>
  );
}
