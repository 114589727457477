import { db, analytics } from "./firestore";
import firebase from 'firebase/compat/app';
import { getFromCache } from "../cache/cache";

export const getProducts = (companyId) => {
  const companyDocRef = firebase
    .firestore()
    .collection("companies")
    .doc(companyId);

  let query = db
    .collection("products")
    .where("company", "==", companyDocRef)
    .orderBy("name");

  let cacheKey = "products_" + companyId;

  return getFromCache(cacheKey, query, (snapshot) => {
    if (!snapshot.empty) {
      let snaps = snapshot.docs.map((doc) => {
        let p = doc.data();
        return { ...p, uid: doc.id };
      });

      return snaps;
    } else {
      return [];
    }
  });
};

export const createProduct = async (companyId, product) => {
  analytics.logEvent("product_create", { name: product.name });

  const companyDocRef = firebase
    .firestore()
    .collection("companies")
    .doc(companyId);

  return db.collection("products").add({
    name: product.name,
    price: parseInt(product.price),
    category: product.category,
    recurring: product.recurring,
    startDate: product.startDate,
    company: companyDocRef,
    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
  });
};

export const updateProduct = async (companyId, product) => {
  analytics.logEvent("product_update", { name: product.name });

  const companyDocRef = firebase
    .firestore()
    .collection("companies")
    .doc(companyId);

  return db
    .collection("products")
    .doc(product.uid)
    .set(
      {
        name: product.name,
        price: parseInt(product.price),
        category: product.category,
        recurring: product.recurring,
        startDate: product.startDate,
        company: companyDocRef,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      },
      { merge: true }
    );
};

export const deleteProducts = async (products) => {
  let deletePromises = [];

  products.forEach((product) => {
    analytics.logEvent("product_delete", { name: product.name });
    deletePromises.push(db.collection("products").doc(product.uid).delete());
  });

  return Promise.all(deletePromises);
};
