import { Chip, Container } from "@mui/material";
import React from "react";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  container: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: `100%`,
    "max-width": "100%",
    padding: "5px",
  },
  chip: {
    marginRight: "5px",
  },
}));

function ReportFilter(props) {
  const { filter, onClick } = props;
  const {classes} = useStyles();

  return (
    <Container className={classes.container}>
      {filter.map((item) => {
        return (
          <Chip
            className={classes.chip}
            key={item.display}
            label={item.display}
            size="small"
            color="primary"
            onDelete={() => onClick(item.value)}
          />
        );
      })}
    </Container>
  );
}

export default ReportFilter;
