import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { Container, IconButton, Typography } from "@mui/material";
import moment from "moment";
import React from "react";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  container: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: `100%`,
    "max-width": "100%",
    padding: "5px",
  },
  chip: {
    marginRight: "5px",
  },
}));

function ProfitabilityFilter(props) {
  const { fromDate, toDate, dateChanged } = props;
  const {classes} = useStyles();

  const title =
    moment(fromDate).format("D MMM") + " - " + moment(toDate).format("D MMM");

  let moveDateBack = () => {
    let fdate = moment(fromDate).subtract(1, "month").toDate();
    let tdate = moment(toDate).subtract(1, "month").toDate();
    dateChanged(fdate, tdate);
  };

  let moveDateForward = () => {
    let fdate = moment(fromDate).add(1, "month").toDate();
    let tdate = moment(toDate).add(1, "month").toDate();
    dateChanged(fdate, tdate);
  };

  return (
    <Container className={classes.container}>
      <Typography component="h1" variant="h6">
        <IconButton title="Previous Month" onClick={moveDateBack} size="large">
          <ArrowLeftIcon />
        </IconButton>
        {title}
        <IconButton title="Next Month" onClick={moveDateForward} size="large">
          <ArrowRightIcon />
        </IconButton>
      </Typography>
    </Container>
  );
}

export default ProfitabilityFilter;
