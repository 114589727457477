import React from 'react';
import logo from '../images/logo.png';
import logoMain from '../images/logo2.png';
import logoHero from '../images/uhlu-hero-001.png';

export const Logo = (props) => {
  // Import result is the URL of your image
  return <img src={logo} alt="Logo" className={props.className} />;
}

export const LogoMain = (props) => {
  // Import result is the URL of your image
  return <img src={logoMain} alt="Logo" className={props.className} />;
}

export const LogoHero = (props) => {
  // Import result is the URL of your image
  return <img src={logoHero} alt="Logo" className={props.className} />;
}


