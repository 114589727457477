import React from 'react'
import ContentHeader from "../../components/ContentHeader";
import UsersView from '../../components/Settings/Users/UsersView';

function Users() {


    return (
        <div>
            <ContentHeader title="Users" />
            <UsersView />
        </div>
    );
}

export default Users