import { db, auth } from "./firestore";
import firebase from 'firebase/compat/app';
import moment from "moment";

const chatLimit = 20;

export const addMessage = async (companyId, messageString) => {
  const companyDocRef = firebase
    .firestore()
    .collection("companies")
    .doc(companyId);

  const userDocRef = firebase
    .firestore()
    .collection("users")
    .doc(auth.currentUser.uid);

  const userData = await userDocRef.get();

  let chat = { content: messageString };

  chat.companyRef = companyDocRef;
  chat.userRef = userDocRef;
  chat.user = userData.data().displayName;
  chat.userImageUrl =
    userData.data().profileImageUrl !== undefined
      ? userData.data().profileImageUrl
      : "";
  chat.createdAt = firebase.firestore.FieldValue.serverTimestamp();

  return firebase.firestore().collection("chats").add(chat);
};

export const updateLastReadTimestamp = async (companyId) => {
  const companyDocRef = firebase
    .firestore()
    .collection("companies")
    .doc(companyId);

  return firebase
    .firestore()
    .collection("chats-metrics")
    .doc(auth.currentUser.uid)
    .set(
      {
        companyRef: companyDocRef,
        lastRead: firebase.firestore.FieldValue.serverTimestamp(),
      },
      { merge: true }
    );
};

export const getLastRead = async (companyId, callback) => {
   return firebase
    .firestore()
    .collection("chats-metrics")
    .doc(auth.currentUser.uid)
    .onSnapshot(function (querySnapshot) {
      if (!querySnapshot.metadata.hasPendingWrites) {
        let timestampData = querySnapshot.data();
        callback(timestampData !== undefined ? timestampData.lastRead : new Date());
      }
    });
};

export const getChatsChanged = (companyId, callback) => {
  const companyDocRef = firebase
    .firestore()
    .collection("companies")
    .doc(companyId);

  let chatsRef = db
    .collection("chats")
    .where("companyRef", "==", companyDocRef)
    .orderBy("createdAt")
    .limitToLast(chatLimit);

  return chatsRef.onSnapshot(function (querySnapshot) {
    callback();
  });
};

export const getMessageCountSince = async (companyId, lastRead) => {
  const companyDocRef = firebase
    .firestore()
    .collection("companies")
    .doc(companyId);

  return db
    .collection("chats")
    .where("companyRef", "==", companyDocRef)
    .where("createdAt", ">=", lastRead)
    .orderBy("createdAt")
    .limitToLast(chatLimit)
    .get()
    .then((snapshot) => {
      return snapshot.size;
    });
};

export const getChatsSnapshot = (companyId, callback) => {
  const companyDocRef = firebase
    .firestore()
    .collection("companies")
    .doc(companyId);

  let chatsRef = db
    .collection("chats")
    .where("companyRef", "==", companyDocRef)
    .orderBy("createdAt")
    .limitToLast(chatLimit);

  return chatsRef.onSnapshot(function (querySnapshot) {
    if (!querySnapshot.metadata.hasPendingWrites) {
      var chats = querySnapshot.docs.map((chatRef) => {
        console.log("CreatedAt: " + chatRef.data().createdAt);

        let createdAt = moment(chatRef.data().createdAt.toDate());

        let chat = chatRef.data();

        chat.position =
          chat.userRef.id === auth.currentUser.uid ? "right" : "left";
        chat.user =
          chat.userRef.id === auth.currentUser.uid ? "You" : chat.user;

        return {
          ...chat,
          uid: chatRef.id,
          createdAt: moment(createdAt).isSame(moment(), "day")
            ? createdAt.format("HH:mm")
            : createdAt.format("MMM Do"),
        };
      });

      callback(chats);
    }

    updateLastReadTimestamp(companyId);
  });
};
