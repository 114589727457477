import React from "react";
import SignIn from "./pages/SignIn/SignIn";
import SignUp from "./pages/SignUp/SignUp";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import { Route, Routes } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import Dashboard from "./Dashboard";

const OuterRouter = () => (
  <Routes>
    <Route path="/signin" exact element={<SignIn />} />
    <Route path="/signup" exact element={<SignUp />} />
    <Route path="/forgotpassword" exact element={<ForgotPassword />} />
    <Route element={<PrivateRoute/>}>
      <Route path="/" element={<Dashboard/>}/>
      <Route path="/:page/*" element={<Dashboard/>}/>
    </Route>
   
  </Routes>
);

export default OuterRouter;
