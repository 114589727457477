import React from "react";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";

function SimpleDialog(props) {
  const { onClose, open, title } = props;

  const handleClose = () => {
    if (onClose) onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open} keepMounted={false} aria-labelledby="simple-dialog-title">
     <DialogTitle id="simple-dialog-title">{title}</DialogTitle>
      {props.children}
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
};

export default SimpleDialog;
