import React from 'react'
import ContentHeader from "../../components/ContentHeader";
import ProfitabilityView from '../../components/Profitability/ProfitabilityView';

export default function Profitability() {


    return (
        <div>
            <ContentHeader title="Profitability" />
            <ProfitabilityView/>
        </div>
    );
}