import React from "react";
import Dropdown from "./Dropdown";
import moment from 'moment';

export default function DateDropdown(props) {
  const { value, label, className, onChange, disabled } = props;
  const months = Array.apply(0, Array(12)).map(function(_,i){return {value: i+1, display: moment().month(i).format('MMM')} });

  return (
    <Dropdown
      label={label}
      name="dates"
      disabled={disabled}
      className={className}
      value={value.toString()}
      items={months}
      onChange={onChange}
    />
  );
}
