import React from 'react'
import ContentHeader from "../../components/ContentHeader";
import TimesheetView from "../../components/Timesheet/TimesheetView";

export default function Timesheet() {


    return (
        <div>
            <ContentHeader title="Timesheet" />
            <TimesheetView />
        </div>
    );
}