import { Box, Button, Container } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  button: {
    padding: "0px",
    minWidth: "26px",
    marginLeft: "2px",
  },
  container: {
    padding: "0px",
  },
}));

export default function TasklistRecorder(props) {
  const {classes} = useStyles();
  const { task, setTime, readOnly } = props;
  const [seconds, setSeconds] = useState(0);
  const [editMode, setEditMode] = useState(false);
  const [editSeconds, setEditSeconds] = useState(0);

  useEffect(() => {
    //console.log("Updating Task Recorder: Name:" + task.name + " Client:" + task.client + " Duration:" + task.duration);
    setSeconds(task.duration);
  }, [task]);

  function handleOnChange(e) {
    var time = moment(e.target.value, "HH:mm:ss");

    let seconds = time.seconds() + time.minutes() * 60 + time.hours() * 60 * 60;
    setEditSeconds(seconds);
  }

  function handleOkClick() {
    if (editSeconds > 0) {
      setTime(task, editSeconds);
      setSeconds(seconds + editSeconds);
    }
    setEditMode(false);
    setEditSeconds(0);
  }

  if (editMode) {
    return (
      <Box component="span">
        <input
          onChange={handleOnChange}
          type="time"
          value={new Date(editSeconds * 1000).toISOString().substr(11, 8)}
        ></input>
        <Button
          className={classes.button}
          variant="outlined"
          size="small"
          color="primary"
          onClick={() => handleOkClick()}
        >
          Ok
        </Button>
      </Box>
    );
  } else {
    return (
      <Container className={classes.container}>
        <Box
          onClick={() => {
            if (!readOnly) setEditMode(!editMode);
          }}
          color="text.secondary"
          component="span"
        >
          {new Date(seconds * 1000).toISOString().substr(11, 8)}
        </Box>
      </Container>
    );
  }
}
