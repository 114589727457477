import { InputAdornment, ListSubheader, TextField } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import React, { useEffect } from "react";
import { useRecoilState } from "recoil";
import { makeStyles } from "tss-react/mui";
import { capitalizeFirstLetter, groupBy } from "../../helpers/helpers";
import { getPackageById } from "../../services/packagesService";
import { getProducts } from "../../services/productsService";
import { claimsState } from "../../state/claimsState";
import AssignedDropdown from "../AssignedDropdown";
import DateDropdown from "../DateDropdown";
import LoadingIndicator from "../LoadingIndicator";

const useStyles = makeStyles()((theme) => ({
  root: {
    margin: "auto",
  },
  paper: {
    height: 300,
    overflow: "auto",
  },
  monthDropdown: {
    width: "80px !important;",
    margin: "0px !important;",
  },
  recurringField: {
    width: "80px !important;",
    margin: "0px !important;",
    paddingLeft: "5px !important;",
  },
  priceField: {
    width: "100px !important;",
    margin: "2px !important;",
  },
}));

function contains(a, obj, func) {
  for (var i = 0; i < a.length; i++) {
    if (a[i].uid === obj.uid) {
      if (func !== undefined) func(a[i], obj);
      return true;
    }
  }
  return false;
}

export default function ProductsCheckList(props) {
  const [claims] = useRecoilState(claimsState);
  const { selectedProducts, onChange, showDueDate, packageId, showPrices } =
    props; //packageId is only used in the clientEdit
  const {classes} = useStyles();
  const [checked, setChecked] = React.useState([]);
  const [products, setProducts] = React.useState([]);
  const [isLoading, setIsloading] = React.useState(false);
  const [dirtyCount, setDirtyCount] = React.useState(0);

  useEffect(() => {
    console.log("In ProductsCheckList UseEffect");
    setIsloading(true);
    const newChecked = [];
    getProducts(claims.companyId).then((products) => {
      products.forEach((product) => {
        if (
          contains(selectedProducts, product, (x, y) => {
            y.startDate = x.startDate;
            y.price = x.price;
            y.assigned = x.assigned ? x.assigned.id : "";
          })
        ) {
          newChecked.push(product);
        }
      });

      //if packageId is specified then get package and marks all Products that's in this particular package.
      //This is used to calculate the cost of the package when additional Products is selected for this Client
      if (packageId !== undefined) {
        getPackageById(claims.companyId, packageId).then(($package) => {
          $package.products.forEach((product) => {
            if (contains(products, product, (x, y) => (x.isInPackage = true))) {
              //do nothing
            }
          });
          setIsloading(false);
          onChange(newChecked);
        });
      } else {
        setIsloading(false);
        onChange(newChecked);
      }
      setChecked(newChecked);
      setProducts(products);
    });
  }, [claims.companyId, packageId]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
    onChange(newChecked);
  };

  const handleDateChange = (product, value) => {
    product.startDate = value;
    setDirtyCount(dirtyCount + 1);
    onChange(checked);
  };

  const handleAssignedChange = (product, value) => {
    product.assigned = value;
    setDirtyCount(dirtyCount + 1);
    onChange(checked);
  };

  const handlePriceChange = (product, price) => {
    product.price = price;
    setDirtyCount(dirtyCount + 1);
    onChange(checked);
  };

  if (isLoading) {
    return <LoadingIndicator />;
  }

  const productList = (products) => (
    <Paper className={classes.paper} elevation={0}>
      <List dense component="div" role="list">
        {groupBy(products, "category").map((group) => {
          return (
            <div key={group.key}>
              <ListSubheader className={classes.listSubHeader}>
                {group.key}
                {" ("}
                {group.values.reduce(function (n, product) {
                  return n + (checked.indexOf(product) !== -1);
                }, 0)}
                {" selected)"}
              </ListSubheader>
              {group.values.map((product) => {
                const labelId = `transfer-list-item-${product.uid}-label`;
                return (
                  <ListItem key={product.uid} role="listitem" button>
                    <ListItemIcon onClick={handleToggle(product)}>
                      <Checkbox
                        checked={checked.indexOf(product) !== -1}
                        tabIndex={-1}
                        color="primary"
                        inputProps={{
                          "aria-labelledby": labelId,
                          "aria-label": "secondary checkbox",
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      id={labelId}
                      primary={
                        <div>
                          {product.name}
                          {product.isInPackage ? (
                            <div
                              style={{
                                color: "grey",
                                fontSize: "6",
                                fontWeight: 300,
                                display: "inline",
                              }}
                            >
                              {" "}
                              (in package)
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      }
                      onClick={handleToggle(product)}
                    />

                    {showPrices ? (
                      <React.Fragment>
                        <AssignedDropdown
                          assignedRef={product.assigned}
                          className={classes.dropDown}
                          onChange={(value) =>
                            handleAssignedChange(product, value)
                          }
                        />
                        <TextField
                          className={classes.priceField}
                          id="package-price"
                          type="number"
                          value={product.price}
                          InputProps={{
                            inputProps: { min: 0, step: 100 },
                            startAdornment: (
                              <InputAdornment position="start">
                                R
                              </InputAdornment>
                            ),
                          }}
                          size="small"
                          onChange={(e) =>
                            handlePriceChange(product, e.target.value)
                          }
                          label="Price"
                          variant="outlined"
                        />
                      </React.Fragment>
                    ) : (
                      <></>
                    )}

                    {showDueDate !== undefined &&
                    showDueDate &&
                    product.recurring !== "monthly" &&
                    product.recurring !== "weekly" ? (
                      <DateDropdown
                        label={capitalizeFirstLetter(product.recurring)}
                        className={classes.monthDropdown}
                        value={product.startDate}
                        onChange={(value) => handleDateChange(product, value)}
                      />
                    ) : (
                      <div className={classes.recurringField}>
                        {capitalizeFirstLetter(product.recurring)}
                      </div>
                    )}
                  </ListItem>
                );
              })}
            </div>
          );
        })}

        <ListItem />
      </List>
    </Paper>
  );

  return <Grid item>{productList(products)}</Grid>;
}
