import React, { useState, useEffect } from "react";
import { claimsState } from "../state/claimsState";
import { useRecoilState } from "recoil";
import Dropdown from "./Dropdown";
import { getClients } from "../services/clientsService";

function ClientsDropdown(props) {
  const { client, className, onChange, disabled } = props;
  const [users, setUsers] = useState([]);
  const [claims] = useRecoilState(claimsState);
  const [defaultValue, setDefaultValue] = useState("");

  function userComparer(a, b) {
    if (a.name > b.name) return -1;
    if (b.name > a.name) return 1;

    return 0;
  }

  useEffect(() => {
    getClients(claims.companyId).then((clients) => {
      let uu = clients.map((client) => {
        return { value: client.uid, display: client.name };
      });

      uu.sort(userComparer);

      setUsers(uu);

      if (client) {
        setDefaultValue(client);
      }
      else {
        setDefaultValue(uu[0].value);
        onChange(uu[0].value, uu[0].display);
      }
     
      
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client, claims.companyId]);

  function handleOnChange(value, displayName) {
    let name =
      displayName !== undefined
        ? displayName
        : users.find((c) => c.value === value).display;
    onChange(value, name);
    setDefaultValue(value);
  }

  return (
    <Dropdown
      name="clients"
      label="Clients"
      disabled={disabled}
      className={className}
      value={defaultValue}
      items={users}
      onChange={handleOnChange}
    />
  );
}

export default React.memo(ClientsDropdown) ;
