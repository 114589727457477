import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { auth } from "./services/firestore";

const PrivateRoute = () => {

  const isAuthed = auth.currentUser && auth.currentUser.emailVerified;

  if(!isAuthed)
  {
    return <Navigate to="/signin" replace/>
  }

  return <Outlet/>;
 
}

export default PrivateRoute;