import { Button, Container, Paper, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  container: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: `100%`,
    "max-width": "100%",
    padding: "5px",
  },
  paper: {
    width: "auto",
    // marginLeft: theme.spacing(3),
    //marginRight: theme.spacing(3),
    [theme.breakpoints.up(620 + theme.spacing(6))]: {
      width: 420,
      marginLeft: "auto",
      marginRight: "auto",
    },
    // marginTop: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing(2)} ${theme.spacing(3)} ${theme.spacing(3)}`,
  },
  textField: {
    width: "366px",
  },
}));

export default function TaskCommentFilter(props) {
  const { task, onClose } = props;
  const {classes} = useStyles();
  const [comments, setComments] = useState(task.comments);

  function handleOnClose() {
    onClose(task, comments);
  }

  return (
    <Container className={classes.container}>
      <Paper className={classes.paper} elevation={0}>
        <Typography component="h8" variant="h8">
          Task: {task.name}
        </Typography>
        <Typography component="h8" variant="h8">
          Client: {task.client}
        </Typography>
        <br />
        <TextField
          autoFocus
          value={comments}
          onChange={(e) => setComments(e.target.value)}
          className={classes.textField}
          multiline={true}
          variant="outlined"
          label="Comments"
          rows={8}
        />
        <br />
        <br />
        <Button
          onClick={handleOnClose}
          fullWidth
          variant="contained"
          color="primary"
        >
          Close
        </Button>
      </Paper>
    </Container>
  );
}
