import {
  Button,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { makeStyles } from "tss-react/mui";
import Dropdown from "../../Dropdown";
import ImageUpload from "../../ImageUpload";

const useStyles = makeStyles()((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: 200,
    },
    "& .MuiButton-root": {
      margin: theme.spacing(1),
      width: 200,
    },
  },
  typography: {
    margin: "5px",
  },
  paper: {
    padding: "5px",
  },
  formControl: {
    minWidth: 200,
    margin: theme.spacing(1),
  },
  logo: {
    margin: theme.spacing(1),
    width: 200,
  },
}));

function UserEdit(props) {
  const { onSave, onCancel, user } = props;
  const { classes } = useStyles();

  const [displayName, setDisplayName] = useState(user.displayName);
  const [rate, setRate] = useState(user.rate);
  const [status, setStatus] = useState(user.status);
  const [profileImageUrl, setProfileImageUrl] = useState(user.profileImageUrl);
  const [role, setRole] = useState(user.roles[0]);
  const [isSuperuser] = useState(user.roles.includes("superuser"));

  let handleOnSave = () => {
    user.displayName = displayName;
    user.rate = rate;
    user.status = status;
    user.roles = isSuperuser && role === "admin" ? [role, "superuser"] : [role];
    user.profileImageUrl = profileImageUrl;
    onSave(user);
  };

  const statuses = [
    { display: "Active", value: "active" },
    { display: "InActive", value: "inactive" },
  ];

  const roles = [
    { display: "Admin", value: "admin" },
    { display: "User", value: "user" },
  ];

  return (
    <Paper className={classes.paper} elevation={6}>
      <form className={classes.root} noValidate autoComplete="off">
        <Typography className={classes.typography} component="h1" variant="h6">
          Edit User
        </Typography>
        <div>
          <TextField
            id="outlined-basic"
            value={displayName}
            onChange={(e) => setDisplayName(e.target.value)}
            required
            size="small"
            label="Display Name"
            variant="outlined"
          />
          <TextField
            id="outlined-basic"
            value={rate}
            onChange={(e) => setRate(e.target.value)}
            InputProps={{
              inputProps: { min: 0, step: 50 },
              startAdornment: (
                <InputAdornment position="start">R</InputAdornment>
              ),
            }}
            required
            size="small"
            label="Rate (per 15min)"
            type="number"
            variant="outlined"
          />
        </div>
        <div>
          <Dropdown
            label="Status"
            className={classes.formControl}
            value={status}
            items={statuses}
            onChange={(status) => setStatus(status)}
          />
          <Dropdown
            label="Role"
            className={classes.formControl}
            value={role}
            items={roles}
            onChange={(role) => setRole(role)}
          />
        </div>
        <div>
          {profileImageUrl !== "" && profileImageUrl !== undefined ? (
            <img className={classes.logo} alt="Logo" src={profileImageUrl} />
          ) : (
            <></>
          )}
          <ImageUpload
            label="Upload Logo"
            name="logoPath"
            changeHandler={(url) => setProfileImageUrl(url)}
          />
        </div>
        <div>
          <Button fullWidth variant="contained" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleOnSave}
          >
            Save
          </Button>
        </div>
      </form>
    </Paper>
  );
}

export default UserEdit;
