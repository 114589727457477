import React from 'react'
import ContentHeader from "../../components/ContentHeader";
import PackagesView from '../../components/Packages/PackagesView';

export default function Packages() {


    return (
        <div>
            <ContentHeader title="Packages" />
            <PackagesView/>
        </div>
    );
}