import React from "react";
import { FormControl, Select, MenuItem, InputLabel } from "@mui/material";
import PropTypes from "prop-types";

export default function Dropdown(props) {
  const { name, value, items, label, className, onChange, disabled, required } = props;

  function handleOnChange(value) {
    onChange(value);
  }

  return (
    <FormControl className={className} size="small" variant="outlined">
      <InputLabel id="status-label">{label}</InputLabel>
      <Select
        disabled={disabled}
        value={value}
        name={name}
        required={required}
        onChange={(e) => {
          handleOnChange(e.target.value);
        }}
        label={label}
      >
        {items.map((item) => {
          return typeof item === "object" && item !== null ? (
            <MenuItem key={item.value} value={item.value}>
              {item.display}
            </MenuItem>
          ) : (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

Dropdown.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  items: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired
};
