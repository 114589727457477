import React, { useState, useEffect } from "react";
import { getPackages } from "../services/packagesService";
import Dropdown from "./Dropdown";
import { claimsState } from "../state/claimsState";
import { useRecoilState } from "recoil";

export default function PackagesDropdown(props) {
  const [claims] = useRecoilState(claimsState);
  const { $package, className, onChange, disabled, required } = props;
  const [packages, setPackages] = useState([]);
  const [defaultValue, setDefaultValue] = useState("");

  useEffect(() => {
    getPackages(claims.companyId).then((packages) => {
      setPackages(
        packages.map((p) => {
          return { value: p.uid, display: p.name, package: p };
        })
      );

      if ($package === undefined) {
        //onChange(packages[0]);
        //setDefaultValue(packages[0].uid);
      } else {
        let dValue = packages.find((p) => {
          return p.uid === $package.uid;
        });
        if (dValue !== undefined) {
          setDefaultValue(dValue.uid);
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleOnChange(value) {

    let item = packages.find((p) => {
      return p.value === value;
    });
    
    onChange(item.package);
    setDefaultValue(value);
  }

  return (
    <Dropdown
      name="packages"
      label="Packages"
      disabled={disabled}
      className={className}
      value={defaultValue}
      items={packages}
      required={required}
      onChange={handleOnChange}
    />
  );
}
