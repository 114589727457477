import React from "react";
import ContentHeader from "../components/ContentHeader";

export default function Notfound() {
  return (
    <div>
      <ContentHeader title="Page not found" />
    </div>
  );
}
