import CommentIcon from "@mui/icons-material/Comment";
import {
  Button,
  IconButton,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";
import { makeStyles } from "tss-react/mui";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { auth } from "../../services/firestore";
import { getCategories, getStatuses } from "../../services/lookupService";
import {
  assignTask,
  createTask,
  deleteTask,
  getTasksSnapshot,
  updateTask,
} from "../../services/tasksService";
import { getUserDocRef } from "../../services/userService";
import { claimsState } from "../../state/claimsState";
import AssignedDropdown from "../AssignedDropdown";
import Dropdown from "../Dropdown";
import FavouriteCheck from "../FavouriteCheck";
import LoadingIndicator from "../LoadingIndicator";
import SimpleDialog from "../SimpleDialog";
import StandardTable from "../StandardTable";
import TabPanel from "../TabPanel";
import CreateNewTask from "./CreateNewTask";
import TaskCommentEditor from "./TaskCommentEditor";
import TaskStatusChip from "./TaskStatusChip";
import TasklistFilter from "./TasklistFilter";
import TasklistRecorder from "./TasklistRecorder";
import TasklistTimeEntryManager from "./TasklistTimeEntryManager";

const useStyles = makeStyles()((theme) => ({
  table: {
    minWidth: 650,
    marginBottom: "20px",
    borderBottom: "2px lightgray solid",
  },
  typography: {
    paddingLeft: "10px",
    color: "gray",
  },
  lastTableCell: {
    paddingRight: "5px !important;",
  },
  paper: {
    width: 420,
    marginLeft: "auto",
    marginRight: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing(2)} ${theme.spacing(3)} ${theme.spacing(3)}`,
  },
  dropDown: {
    padding: "5px 5px 15px 5px",
    minWidth: "200px",
  },
  gridEditMode: {
    justifyContent: "center",
  },
  button: {
    fontWeight: "400",
  },
}));

function TasklistView() {
  const {classes} = useStyles();
  const [claims] = useRecoilState(claimsState);
  const [filter, setFilter] = useLocalStorage("tasklistFilter", []);
  const [createMode, setCreateMode] = React.useState(false);
  const [isLoading, setIsloading] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [commentsOpen, setCommentsOpen] = useState(false);
  const [task, setTask] = useState({ assignedRef: { id: 0 } });
  const [tasks, setTasks] = useState([]);
  const [favourites, setFavourites] = useState([]);
  const location = useLocation();
  const [categories] = useState(getCategories());
  const [tabValue, setTabValue] = useLocalStorage("tasklistSelectedTab", 0);

  if (location.state && location.state.filter) {
    setFilter([location.state.filter]);
    location.state.filter = undefined;
  }

  useEffect(() => {
    setIsloading(true);
    console.log("Subscribing to Tasks");
    let unSubscribe = getTasksSnapshot(
      claims.companyId,
      filter,
      (tasks) => {
        setTasks(tasks);

        let tempFav = tasks.filter((item) => item.isFavourite);
        tempFav.sort(compareTasks);

        setFavourites(tempFav);

        if (tempFav.length === 0 && tabValue === 4) {
          setTabValue(0);
        }

        setIsloading(false);
      },
      [claims.companyId, filter]
    );

    return unSubscribe;
  }, [claims.companyId, filter]);

  function addToFilter(field, value, display) {
    let temp = filter.filter((item) => {
      return item.field !== field;
    });

    temp.push({
      field: field,
      value: value,
      display: display !== undefined ? display : value,
    });
    setFilter(temp);
  }

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  function removeFromFilter(value) {
    let temp = filter.filter((item) => {
      return item.value !== value;
    });

    setFilter(temp);
  }

  function setOptionsOpen(task) {
    setTask(task);
    setEditOpen(true);
  }

  function showComments(task) {
    setTask(task);
    setCommentsOpen(true);
  }

  function commentEditorClosed(task, comments) {
    setCommentsOpen(false);

    if (comments !== undefined && comments !== task.comments) {
      task.comments = comments;
      updateTask(task, "comments_changed");
    }
  }

  function updateTaskStatus(task, status) {
    task.status = status;

    updateTask(task, "status_changed");
  }

  function updateTaskAssignee(task, value, name) {
    if (task.assignedRef.id !== value) {
      task.assigned = name;
      task.assignedRef = value;

      assignTask(task);
    }
  }

  function updateTaskIsFavourite(task, isFavourite) {
    task.isFavourite = isFavourite;

    updateTask(task, "isFavourite_changed");
  }

  function removeTimeEntry(task, entry) {
    task.timeEntries = task.timeEntries.filter((e) => {
      return e.id !== entry.id;
    });

    updateTask(task, "entry_removed");
  }

  function updateTimeEntry(task, entry) {
    updateTask(task, "entry_updated");
  }

  function deleteTasks(tasks) {
    tasks.forEach((task) => {
      deleteTask(task);
    });
  }

  function createNewTask(task) {
    setCreateMode(false);
    setIsloading(true);
    createTask(task)
      .then((t) => {
        setIsloading(false);
      })
      .catch((e) => {
        alert(e);
        setIsloading(false);
      });
  }

  function setTime(task, seconds) {
    let endTime = new Date();
    let startTime = new Date(endTime - seconds * 1000);

    let userDocRef = getUserDocRef(auth.currentUser.uid);

    if (task.timeEntries === undefined) task.timeEntries = [];

    if (task.status === "notstarted") task.status = "inprogress";

    task.timeEntries.push({
      startTime: startTime,
      endTime: endTime,
      recordedBy: userDocRef,
    });

    updateTask(task, "time_logged");
  }

  if (isLoading) {
    return <LoadingIndicator />;
  }

  let adHocData = tasks.filter(
    (item) => item.category === "Ad-hoc" && item.status !== "completed"
  );
  adHocData.sort(compareAddhocTasks);

  function compareAddhocTasks(a, b) {
    if (a.isFavourite === b.isFavourite) {
      return b.client > a.client ? -1 : 1;
    } else {
      return a.isFavourite && !b.isFavourite
        ? -1
        : !a.isFavourite && b.isFavourite
        ? 1
        : b.client > a.client
        ? -1
        : 1;
    }
  }

  function compareTasks(a, b) {
    if (a.isFavourite === b.isFavourite) {
      return b.dueDate === a.dueDate
        ? b.client > a.client
          ? -1
          : 1
        : b.dueDate > a.dueDate
        ? 1
        : -1;
    } else {
      return a.isFavourite && !b.isFavourite
        ? -1
        : !a.isFavourite && b.isFavourite
        ? 1
        : b.dueDate === a.dueDate
        ? b.client > a.client
          ? -1
          : 1
        : b.dueDate > a.dueDate
        ? 1
        : -1;
    }
  }

  let taskCreator = (
    <SimpleDialog
      title="Add Task"
      onClose={() => setCreateMode(false)}
      open={createMode}
    >
      <CreateNewTask
        createTask={createNewTask}
        cancelClick={() => setCreateMode(false)}
      />
    </SimpleDialog>
  );

  let taskEditor = (
    <SimpleDialog
      title="Edit Task"
      onClose={() => setEditOpen(false)}
      open={editOpen}
    >
      <Paper className={classes.paper} elevation={0}>
        <Typography component="h8" variant="h8">
          Task: {task.name}
        </Typography>
        <Typography component="h8" variant="h8">
          Client: {task.client}
        </Typography>
        <br />
        <Dropdown
          label="Status"
          className={classes.dropDown}
          items={getStatuses()}
          value={task.status}
          onChange={(value) => updateTaskStatus(task, value)}
        />
        <AssignedDropdown
          className={classes.dropDown}
          assignedRef={task.assignedRef.id}
          onChange={(value, name) => updateTaskAssignee(task, value, name)}
        />
        <TasklistTimeEntryManager
          timeEntries={task.timeEntries}
          onDelete={(entry) => removeTimeEntry(task, entry)}
          onUpdate={(entry) => updateTimeEntry(task, entry)}
        />
        <br />
        <br />
        <Button
          onClick={() => {
            setEditOpen(false);
          }}
          fullWidth
          variant="contained"
          color="primary"
        >
          Close
        </Button>
      </Paper>
    </SimpleDialog>
  );

  let columns = [
    {
      id: "name",
      Header: "Task Name",
      accessor: "name",
      Cell: ({ cell }) => (
        <React.Fragment>
          <FavouriteCheck
            checked={cell.row.original.isFavourite}
            onChange={(isFavourite) =>
              updateTaskIsFavourite(cell.row.original, isFavourite)
            }
          />
          <Button
            className={classes.button}
            onClick={() => addToFilter("name", cell.row.values.name)}
          >
            {cell.row.values.name}
          </Button>
        </React.Fragment>
      ),
    },
    {
      id: "client",
      Header: "Client",
      accessor: "client",
      Cell: ({ cell }) => (
        <Button
          className={classes.button}
          onClick={() => addToFilter("client", cell.row.values.client)}
        >
          {cell.row.values.client}
        </Button>
      ),
    },
    {
      id: "status",
      Header: "Status",
      accessor: "status",
      Cell: ({ cell }) => (
        <TaskStatusChip
          onClick={() => addToFilter("status", cell.row.values.status)}
          label={cell.row.values.status}
        ></TaskStatusChip>
      ),
    },
    {
      id: "dueDate",
      Header: "Due Date",
      accessor: "dueDate",
    },
    {
      id: "assigned",
      Header: "Assigned",
      accessor: "assigned",
      Cell: ({ cell }) => (
        <Button
          className={classes.button}
          onClick={() => addToFilter("assigned", cell.row.values.assigned)}
        >
          {cell.row.values.assigned}
        </Button>
      ),
    },
    {
      Header: "Time Record",
      accessor: "timeEntries",
      Cell: ({ cell }) => {
        return <TasklistRecorder setTime={setTime} task={cell.row.original} />;
      },
    },
    {
      Header: "Comments",
      accessor: "comments",
      Cell: ({ cell }) => {
        return (
          <IconButton
            title={cell.row.values.comments}
            aria-label="Comments"
            onClick={() => showComments(cell.row.original)}
            size="small"
          >
            {cell.row.original.comments !== undefined &&
            cell.row.original.comments !== "" ? (
              <CommentIcon color="primary" />
            ) : (
              <CommentIcon />
            )}
          </IconButton>
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <SimpleDialog
        title="Comments"
        onClose={() => setCommentsOpen(false)}
        open={commentsOpen}
      >
        <TaskCommentEditor task={task} onClose={commentEditorClosed} />
      </SimpleDialog>
      {taskEditor}
      {taskCreator}
      <TasklistFilter filter={filter} onClick={removeFromFilter} />
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab label="Ad-Hoc" />
        {categories.map((category) => {
          return <Tab label={category} />;
        })}
        {favourites.length > 0 ? <Tab label="Favourites" /> : <></>}
      </Tabs>
      <TabPanel value={tabValue} index={0} lazyload={true}>
        <StandardTable
          data={adHocData}
          columns={columns}
          canEdit={true}
          canAdd={true}
          canDelete={true}
          onEdit={setOptionsOpen}
          addUserHandler={() => setCreateMode(true)}
          deleteUserHandler={deleteTasks}
        />
      </TabPanel>
      {categories.map((category, index) => {
        let data = tasks.filter((item) => item.category === category);
        data.sort(compareTasks);
        return (
          <TabPanel value={tabValue} index={index + 1} lazyload={true}>
            <StandardTable
              data={data}
              columns={columns}
              canEdit={true}
              canAdd={false}
              canDelete={false}
              onEdit={setOptionsOpen}
              addUserHandler={() => setCreateMode(true)}
            />
          </TabPanel>
        );
      })}
      {favourites.length > 0 ? (
        <TabPanel
          value={tabValue}
          index={categories.length + 1}
          lazyload={true}
        >
          <StandardTable
            data={favourites}
            columns={columns}
            canEdit={true}
            canAdd={false}
            canDelete={false}
            onEdit={setOptionsOpen}
            addUserHandler={() => setCreateMode(true)}
          />
        </TabPanel>
      ) : (
        <></>
      )}
    </React.Fragment>
  );
}

export default TasklistView;
