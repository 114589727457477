import { Container, Typography } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";
import { makeStyles } from "tss-react/mui";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { getTimeEntries } from "../../services/timesheetService";
import { claimsState } from "../../state/claimsState";
import LoadingIndicator from "../LoadingIndicator";
import TimesheetFilter from "./TimesheetFilter";
import TimesheetTable from "./TimesheetTable";

const useStyles = makeStyles()((theme) => ({
  container: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: `100%`,
    "max-width": "100%",
    padding: "5px",
  },
}));

function TimesheetView() {
  const [claims] = useRecoilState(claimsState);
  const [filter, setFilter] = useLocalStorage("timesheetFilter", []);

  const {classes} = useStyles();
  const monday = moment().isoWeekday(1).startOf("isoWeek");
  const friday = moment().isoWeekday(5);

  const location = useLocation();

  if (location.state && location.state.filter) {
    setFilter(
      Array.isArray(location.state.filter)
        ? location.state.filter
        : [location.state.filter]
    );
    location.state.filter = undefined;
  }

  const [fromDate, setFromDate] = useState(monday.toDate());
  const [toDate, setToDate] = useState(friday.endOf("day").toDate());

  const [isLoading, setIsloading] = useState(false);

  const [timeEntries, setTimeEntries] = useState([]);

  useEffect(() => {
    setIsloading(true);
    console.log("Getting TimeEntries");
    getTimeEntries(claims.companyId, fromDate, toDate, filter)
      .then((timeEntries) => {
        setTimeEntries(timeEntries);
        setIsloading(false);
      })
      .catch((err) => {
        setIsloading(false);
      });
  }, [claims.companyId, fromDate, toDate, filter]);

  function ondateChange(fromDate, toDate) {
    setFromDate(fromDate);
    setToDate(toDate);
  }

  function addToFilter(field, value, display) {
    let temp = filter.filter((item) => {
      return item.field !== field;
    });

    temp.push({
      field: field,
      value: value,
      display: display !== undefined ? display : value,
    });

    setFilter(temp);
  }

  function removeFromFilter(value) {
    let temp = filter.filter((item) => {
      return item.value !== value;
    });

    setFilter(temp);
  }

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <React.Fragment>
      <TimesheetFilter
        filter={filter}
        onClick={removeFromFilter}
        fromDate={fromDate}
        toDate={toDate}
        dateChanged={ondateChange}
      ></TimesheetFilter>

      {timeEntries.length > 0 ? (
        <TimesheetTable
          data={timeEntries}
          fromDate={fromDate}
          addToFilter={addToFilter}
        />
      ) : (
        <Container className={classes.container}>
          <Typography component="h1" variant="h6">
            No entries could be found for selected dates
          </Typography>
        </Container>
      )}
    </React.Fragment>
  );
}

export default TimesheetView;
