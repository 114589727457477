import { Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { makeStyles } from "tss-react/mui";
import CompanySummary from "../../components/AdminReports/CompanySummary";
import ContentHeader from "../../components/ContentHeader";
import LoadingIndicator from "../../components/LoadingIndicator";
import { sum } from "../../helpers/helpers";
import { getAllCompanies } from "../../services/adminReportsService";

const useStyles = makeStyles()((theme) => ({
  root: {
    flexGrow: 1,
    padding: "0px 10px 0px 10px",
  },
  typography: {
    color: "grey",
  },
}));

export default function Companies() {
  const [companies, setCompanies] = useState();
  const [summary, setSummary] = useState();
  const [isLoading, setIsloading] = useState(false);
  const {classes} = useStyles();

  useEffect(() => {
    setIsloading(true);
    getAllCompanies()
      .then((companies) => {
        setCompanies(companies);

        let clientCount = sum(
          companies.map((company) => {
            return { length: company.clients.length };
          }),
          "length"
        );

        let userCount = sum(
          companies.map((company) => {
            return { userCount: company.userCount };
          }),
          "userCount"
        );

        let summary = `Currently there's a total of ${companies.length} companies, with a total of ${clientCount} clients and ${userCount} users.`;

        setSummary(summary);
        setIsloading(false);
      })
      .catch((e) => alert(e));
  }, []);

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <div>
      <ContentHeader title="Company Reports" />
      <Grid
        container
        justifyContent="center"
        spacing={2}
        className={classes.root}
      >
        <Grid item xs={7}>
          <Typography className={classes.typography} component="h4">
            {summary}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <CompanySummary companies={companies} />
        </Grid>
      </Grid>
    </div>
  );
}
