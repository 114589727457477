import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { Container, IconButton, Typography } from "@mui/material";
import moment from "moment";
import React from "react";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  container: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: `100%`,
    "max-width": "100%",
    padding: "5px",
  },
  chip: {
    marginRight: "5px",
  },
}));

export default function MonthSelector(props) {
  const { date, dateChanged } = props;
  const {classes} = useStyles();

  const title = moment(date).format("MMMM YYYY");

  let moveDateBack = () => {
    let newDate = moment(date).subtract(1, "months").toDate();
    dateChanged(newDate);
  };

  let moveDateForward = () => {
    let newDate = moment(date).add(1, "months").toDate();
    dateChanged(newDate);
  };

  return (
    <Container className={classes.container}>
      <Typography className={classes.typography} component="h1" variant="h6">
        <IconButton title="Previous Month" onClick={moveDateBack} size="large">
          <ArrowLeftIcon />
        </IconButton>
        {title}
        <IconButton title="Next Month" onClick={moveDateForward} size="large">
          <ArrowRightIcon />
        </IconButton>
      </Typography>
    </Container>
  );
}
