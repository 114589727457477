import { Button } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import moment from "moment";
import React from "react";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  table: {
    minWidth: 650,
    marginBottom: "20px",
    borderBottom: "2px lightgray solid",
  },
  tableContainer: {
    padding: "5px",
  },
  typography: {
    paddingLeft: "10px",
    color: "gray",
  },
  totalRow: {
    fontWeight: "700",
  },
}));

const sum = function (items, prop) {
  return items.reduce(function (a, b) {
    if (b[prop] !== undefined) return a + b[prop];
    else return a;
  }, 0);
};

export default function TimesheetTable(props) {
  const {classes} = useStyles();
  const { data, fromDate, addToFilter } = props;

  if (data === undefined || data.length === 0) {
    return <></>;
  }

  let dayHeadings = [];

  for (var i = 0; i < 5; i++) {
    let day = moment(fromDate).add(i, "days").format("ddd (Do)");
    dayHeadings.push(day);
  }

  let secondsToHms = (d) => {
    d = Number(d);
    var h = ("0" + Math.floor(d / 3600)).slice(-2);
    var m = ("0" + Math.floor((d % 3600) / 60)).slice(-2);
    var s = ("0" + Math.floor((d % 3600) % 60)).slice(-2);

    return h + ":" + m + ":" + s;
  };

  return (
    <TableContainer className={classes.tableContainer}>
      <Table className={classes.table} aria-label="task table">
        <TableHead>
          <TableRow>
            <TableCell>Client</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>RecordedBy</TableCell>
            {dayHeadings.map((day) => (
              <TableCell key={day} align="center">
                {day}
              </TableCell>
            ))}
            <TableCell align="center">Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((entry) => (
            <TableRow key={entry.uid + entry.recordedBy}>
              <TableCell>
                <Button
                  onClick={() =>
                    addToFilter("clientName", entry.client, entry.client)
                  }
                >
                  {entry.client}
                </Button>
              </TableCell>
              <TableCell>
                {" "}
                <Button
                  onClick={() => addToFilter("name", entry.name, entry.name)}
                >
                  {entry.name}
                </Button>
              </TableCell>
              <TableCell>
                <Button
                  onClick={() =>
                    addToFilter(
                      "recordedByName",
                      entry.recordedBy,
                      entry.recordedBy
                    )
                  }
                >
                  {entry.recordedBy}
                </Button>
              </TableCell>
              <TableCell align="right">
                {entry.Monday === undefined ? "" : secondsToHms(entry.Monday)}
              </TableCell>
              <TableCell align="right">
                {entry.Tuesday === undefined ? "" : secondsToHms(entry.Tuesday)}
              </TableCell>
              <TableCell align="right">
                {entry.Wednesday === undefined
                  ? ""
                  : secondsToHms(entry.Wednesday)}
              </TableCell>
              <TableCell align="right">
                {entry.Thursday === undefined
                  ? ""
                  : secondsToHms(entry.Thursday)}
              </TableCell>
              <TableCell align="right">
                {entry.Friday === undefined ? "" : secondsToHms(entry.Friday)}
              </TableCell>
              <TableCell align="right">
                {entry.total === undefined ? "" : secondsToHms(entry.total)}
              </TableCell>
            </TableRow>
          ))}
          <TableRow variant="footer" key="Total">
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell align="right" className={classes.totalRow}>
              Total
            </TableCell>
            <TableCell align="right" className={classes.totalRow}>
              {secondsToHms(sum(data, "Monday"))}
            </TableCell>
            <TableCell align="right" className={classes.totalRow}>
              {secondsToHms(sum(data, "Tuesday"))}
            </TableCell>
            <TableCell align="right" className={classes.totalRow}>
              {secondsToHms(sum(data, "Wednesday"))}
            </TableCell>
            <TableCell align="right" className={classes.totalRow}>
              {secondsToHms(sum(data, "Thursday"))}
            </TableCell>
            <TableCell align="right" className={classes.totalRow}>
              {secondsToHms(sum(data, "Friday"))}
            </TableCell>
            <TableCell align="right" className={classes.totalRow}>
              {secondsToHms(sum(data, "total"))}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
