import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Users from "./pages/Users/Users";
import Packages from "./pages/Packages/Packages";
import Products from "./pages/Products/Products";
import Timesheet from "./pages/Timesheet/Timesheet";
import Tasklist from "./pages/Tasklist/Tasklist";
import Dashboard from "./pages/Dashboard/Dashboard";
import Clients from "./pages/Clients/Clients";
import Costings from "./pages/Costings/Costings";
import Reports from "./pages/Reports/Reports";
import AnnualTaskStatusReport from "./components/Reports/AnnualTaskStatusReport";
import Notfound from "./pages/Notfound";
import { navigationState } from "./state/navigationState";
import { useRecoilState } from "recoil";
import AdminRoute from "./AdminRoute";
import SuperuserRoute from "./SuperuserRoute";
import IssueLogger from "./pages/Support/IssueLogger";
import AdminCompaniesReports from "./pages/AdminReports/Companies";
import Profitability from "./pages/Profitability/Profitability";

const InnerRouter = () => {
  const location = useLocation();
  const [selectedMenuItem, setSelectedMenuItem] =
    useRecoilState(navigationState);

  useEffect(() => {
    if (selectedMenuItem !== location.pathname.substr(1)) {
      setSelectedMenuItem(location.pathname.substr(1));
      document.title =
        "Uhlu - " + capitalizeFirstLetter(location.pathname.substr(1));
    }
  }, [location, selectedMenuItem, setSelectedMenuItem]);

  let capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  switch (location.pathname) {
    case "/":
    case "/home":
    case "/dashboard":
      return <Dashboard />;
    case "/tasklist":
      return <Tasklist />;
    case "/timesheet":
      return <Timesheet />;
    case "/clients":
      return <Clients />;
    case "/costings":
      return <AdminRoute Component={Costings} />;
    case "/profitability":
      return <AdminRoute Component={Profitability} />;
    case "/reports":
      return <AdminRoute Component={Reports} />;
    case "/issuelogger":
      return <AdminRoute Component={IssueLogger} />;
    case "/reports/annualtasksstatus":
      return <AdminRoute Component={AnnualTaskStatusReport} />;
    case "/packages":
      return <AdminRoute Component={Packages} />;
    case "/products":
      return <AdminRoute Component={Products} />;
    case "/users":
      return <AdminRoute Component={Users} />;
    case "/admin/companies":
      return <SuperuserRoute Component={AdminCompaniesReports} />;
    default:
      return <Notfound />;
  }
};

export default InnerRouter;
