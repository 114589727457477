import { auth, analytics } from "./firestore";


export const signIn = (credentials) => {
  return auth
    .signInWithEmailAndPassword(credentials.email, credentials.password)
    .then((result) => {
      analytics.logEvent("login");
      return result;
    })
    .catch((err) => {
      throw err;
    });
};

export const signUp = (credentials) => {
  return auth
    .createUserWithEmailAndPassword(credentials.email, credentials.password)
    .then(async (result) => {
      analytics.logEvent("sign_up");
      await result.user.sendEmailVerification();
      return result;
    })
    .catch((err) => {
      throw err;
    });


};

export const signOut = () => {
  return auth
    .signOut()
    .then((result) => {
      analytics.logEvent("logout");
      return result;
    })
    .catch((err) => {
      throw err;
    });
};

export const resetPassword = (email) => {
  return auth.sendPasswordResetEmail(email);
};


export const checkUserExists = (email) => {
    return auth.fetchSignInMethodsForEmail(email)        
    .then((result) => {
        return result.length > 0;
    }).catch((err) => {
        console.log("error", err);
        throw err;
    });
};