import React from "react";
import PropTypes from "prop-types";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import { IconButton } from "@mui/material";

export default function FavouriteCheck(props) {
  const { checked, onChange } = props;

  if (checked) {
    return (
      <IconButton
        size="small"
        aria-label="favourite"
        title="Remove Favourite"
        onClick={() => onChange(false)}
      >
        <StarIcon fontSize="small" color="primary"/>
      </IconButton>
    );
  } else {
    return (
      <IconButton
      size="small"
        aria-label="favourite"
        title="Add Favourite"
        onClick={() => onChange(true)}
      >
        <StarBorderIcon fontSize="small"/>
      </IconButton>
    );
  }
}

FavouriteCheck.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};
