import { Button } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React from "react";
import { makeStyles } from "tss-react/mui";
import { toCurrency } from "../../helpers/helpers";

const useStyles = makeStyles()((theme) => ({
  table: {
    minWidth: 650,
    marginBottom: "20px",
  },
  tableContainer: {
    padding: "5px",
  },
  typography: {
    paddingLeft: "10px",
    color: "gray",
  },
  totalRow: {
    fontWeight: "700",
    paddingRight: "5px",
    border: "1px solid lightgray",
  },
  normalRow: {
    paddingRight: "5px",
    border: "1px solid lightgray",
  },
  negative: {
    color: "red",
  },
}));

export default function ProfitabilityTable(props) {
  const {classes} = useStyles();
  const { items, taskColumns, onClick } = props;

  let secondsToHms = (d) => {

    if(d)
    {
      d = Number(d);
      var h = ("0" + Math.floor(d / 3600)).slice(-2);
      var m = ("0" + Math.floor((d % 3600) / 60)).slice(-2);
      var s = ("0" + Math.floor((d % 3600) % 60)).slice(-2);
  
      return "(" + h + ":" + m + ":" + s + ")";
    }
    else{
      return "";
    }
   
  };

  return (
    <TableContainer className={classes.tableContainer}>
      <Table className={classes.table} aria-label="task table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.normalRow}>RecordedBy</TableCell>
            {taskColumns.map((task) => (
              <TableCell
                className={classes.normalRow}
                key={task}
                align="center"
              >
                {task}
              </TableCell>
            ))}
            <TableCell className={classes.normalRow} align="center">
              Total
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.slice(0, items.length - 3).map((entry, index) => {
            return (
              <TableRow key={index}>
                <TableCell className={classes.normalRow}>
                  <Button
                    className={classes.clientButton}
                    onClick={() => {
                      onClick(entry.recordedBy);
                    }}
                  >
                    {entry.recordedBy}
                  </Button>
                </TableCell>
                {taskColumns.map((task) => (
                  <TableCell
                    className={classes.normalRow}
                    key={task}
                    align="right"
                  >
                    {toCurrency(entry[task])} {secondsToHms(entry[task+ "_time"])}
                  </TableCell>
                ))}
                <TableCell className={classes.totalRow} align="right">
                  {toCurrency(entry.totalCost)} 
                </TableCell>
              </TableRow>
            );
          })}
          <br />
          {items.slice(items.length - 3).map((entry, index) => {
            return (
              <TableRow variant="footer" key={index}>
                <TableCell className={classes.totalRow}>
                  {entry.recordedBy}
                </TableCell>
                {taskColumns.map((task) => (
                  <TableCell
                    key={task}
                    className={`${classes.totalRow} ${
                      entry[task] < 0 ? classes.negative : ""
                    }`}
                    align="right"
                  >
                    {toCurrency(entry[task])}
                  </TableCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
