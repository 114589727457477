import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import moment from "moment";
import React from "react";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  table: {
    minWidth: 650,
    marginBottom: "20px",
    borderBottom: "2px lightgray solid",
  },
  tableContainer: {
    padding: "5px",
  },
  typography: {
    paddingLeft: "10px",
    color: "gray",
  },
  totalRow: {
    fontWeight: "700",
  },
}));

export default function CompanySummary(props) {
  const { companies } = props;
  const {classes} = useStyles();

  if (!companies) {
    return <></>;
  }

  return (
    <TableContainer className={classes.tableContainer}>
      <Table className={classes.table} aria-label="task table">
        <TableHead>
          <TableRow>
            <TableCell>Company Name</TableCell>
            <TableCell>Number of users</TableCell>
            <TableCell>Number of clients</TableCell>
            <TableCell>Joined</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {companies.map((entry) => (
            <TableRow key={entry.uid}>
              <TableCell>{entry.name}</TableCell>
              <TableCell>{entry.userCount}</TableCell>
              <TableCell>{entry.clients.length}</TableCell>
              <TableCell>
                {moment(entry.createdAt).format("MMMM yyyy")}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
