import React from 'react'
import ContentHeader from "../../components/ContentHeader";
import TasklistView from '../../components/Tasklist/TasklistView'
export default function Tasklist() {


    return (
        <div>
            <ContentHeader title="Tasklist" />
            <TasklistView/>
        </div>
    );
}