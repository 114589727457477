import { db, auth, analytics } from "./firestore";
import firebase from "firebase/compat/app";
import { getUsers } from "./userService";
import { getFromCache } from "../cache/cache";
import { toHashMap } from "../helpers/helpers";

export const getClients = async (companyId) => {
  const companyDocRef = firebase
    .firestore()
    .collection("companies")
    .doc(companyId);

  const users = await getUsers(companyId);

  if (users === undefined) return [];

  let usersMap = toHashMap(users, "uid");

  let query = db
    .collection("clients")
    .where("company", "==", companyDocRef)
    .orderBy("name");

  let cacheKey = "client_" + companyId;

  return getFromCache(cacheKey, query, (snapshot) => {
    if (!snapshot.empty) {
      let clients = snapshot.docs.map((doc) => {
        let client = doc.data();
        let user = usersMap[client.assigned.id];

        return {
          ...client,
          uid: doc.id,
          assignedName: user ? user.displayName : client.assigned.id,
        };
      });

      //USED to Export Seed data
      // clients.forEach((client) => {
      //     console.log( JSON.stringify({name: client.name, package: client.package.name, company: client.company.id, assigned: client.assigned.id}));
      // })

      return clients;
    }
    return [];
  });
};

export const createClient = async (companyId, client) => {
  analytics.logEvent("client_create", { name: client.name });

  const companyDocRef = firebase
    .firestore()
    .collection("companies")
    .doc(companyId);

  const userDocRef = firebase
    .firestore()
    .collection("users")
    .doc(client.assigned);

  let capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  client.package = {
    uid: client.package.uid,
    name: client.package.name,
    price: parseInt(client.packagePrice),
  };

  client.products = client.products.map((product) => {
    return {
      uid: product.uid,
      name: product.name,
      category: product.category,
      recurring: product.recurring,
      isInPackage:
        product.isInPackage !== undefined ? product.isInPackage : false,
      startDate:
        product.recurring !== "monthly" && product.recurring !== "weekly"
          ? product.startDate
          : null,
      price: parseInt(product.price),
      assigned: product.assigned
        ? firebase.firestore().collection("users").doc(product.assigned)
        : userDocRef,
    };
  });

  client.name = capitalizeFirstLetter(client.name);
  client.company = companyDocRef;
  client.assigned = userDocRef;
  client.lastTaskChecked = firebase.firestore.FieldValue.serverTimestamp();
  client.createdAt = firebase.firestore.FieldValue.serverTimestamp();
  client.createdBy = firebase
    .firestore()
    .collection("users")
    .doc(auth.currentUser.uid);

  delete client.packagePrice;

  return db.collection("clients").add(client);
};

export const updateClient = async (companyId, client) => {

  analytics.logEvent("client_update", { name: client.name });

  const clientDocRef = firebase
    .firestore()
    .collection("clients")
    .doc(client.uid);

  const existingClient = await clientDocRef.get().then((doc) => {
    let client = doc.data();

    return { ...client, uid: doc.id };
  });

  const userDocRef = firebase
    .firestore()
    .collection("users")
    .doc(client.assigned);

  client.products = client.products.map((product) => {
    return {
      uid: product.uid,
      name: product.name,
      category: product.category,
      recurring: product.recurring,
      isInPackage:
        product.isInPackage !== undefined ? product.isInPackage : false,
      startDate:
        product.recurring !== "monthly" && product.recurring !== "weekly"
          ? product.startDate
          : null,
      price: parseInt(product.price),
      assigned: product.assigned
        ? firebase.firestore().collection("users").doc(product.assigned)
        : userDocRef,
    };
  });

  client.package = {
    uid: client.package.uid,
    name: client.package.name,
    price: parseInt(client.packagePrice),
  };

  await firebase
    .firestore()
    .collection("clients")
    .doc(client.uid)
    .set(
      {
        name: client.name,
        assigned: userDocRef,
        registrationNumber: client.registrationNumber,
        incomeTaxNumber: client.incomeTaxNumber,
        type: client.type,
        contactPerson: client.contactPerson,
        email: client.email,
        cellNr: client.cellNr,
        package: client.package,
        products: client.products,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        updatedBy: firebase
          .firestore()
          .collection("users")
          .doc(auth.currentUser.uid),
      },
      { merge: true }
    )
    .then((snapshot) => {
      //We need Tasks for the Products
      //Check which Products was Added/Deleted/Updated and act accordingly
      const existingProducts = existingClient.products;
      const newProducts = client.products;

      let removedProducts = existingProducts.filter(
        (p) =>
          newProducts.find((p2) => {
            return p.uid === p2.uid;
          }) === undefined
      );

      let noChangeProducts = newProducts.filter(
        (p) =>
          existingProducts.find((p2) => {
            return p.uid === p2.uid;
          }) !== undefined
      );

      if (removedProducts.length !== 0) {
        console.log("Removed number: " + removedProducts.length);

        removedProducts.forEach((prod) => {
          const productDocRef = firebase
            .firestore()
            .collection("products")
            .doc(prod.uid);

          firebase
            .firestore()
            .collection("tasks")
            .where("productRef", "==", productDocRef)
            .where("clientRef", "==", clientDocRef)
            .get()
            .then((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                doc.ref.delete();
              });
            });
        });
      }

      if (noChangeProducts.length !== 0) {
        console.log("No Change: " + noChangeProducts.length);

        noChangeProducts.forEach(async (prod) => {
          if (prod.recurring !== "monthly" && prod.recurring !== "weekly") {
            try {
              let existingProd = existingProducts.find((p) => {
                return p.uid === prod.uid;
              });
              let startDateChanged = existingProd.startDate !== prod.startDate;

              if (startDateChanged) {
                console.log("Start date changed: " + prod.name);

                const productDocRef = firebase
                  .firestore()
                  .collection("products")
                  .doc(existingProd.uid);

                let deletePromises = firebase
                  .firestore()
                  .collection("tasks")
                  .where("productRef", "==", productDocRef)
                  .where("clientRef", "==", clientDocRef)
                  .get()
                  .then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                      doc.ref.delete();
                    });
                  });

                await deletePromises;
              }
            } catch (error) {
              alert(error);
            }
          }
        });
      }
    });
};

export const deleteClients = async (clients) => {
  let deletePromises = [];

  clients.forEach((client) => {
    analytics.logEvent("client_delete", { name: client.name });

    const clientDocRef = firebase
      .firestore()
      .collection("clients")
      .doc(client.uid);

    deletePromises.push(
      clientDocRef.delete().then(() => {
        //Delete all associated TASK too
        firebase
          .firestore()
          .collection("tasks")
          .where("clientRef", "==", clientDocRef)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              doc.ref.delete();
            });
          });
      })
    );
  });

  return Promise.all(deletePromises);
};
