import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { Button, Grid, Paper, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { makeStyles } from "tss-react/mui";
import LoadingIndicator from "../../components/LoadingIndicator";
import { auth } from "../../services/firestore";
import { addIssue } from "../../services/issueService";
import { claimsState } from "../../state/claimsState";

const useStyles = makeStyles()((theme) => ({
  root: {
    "& .MuiButton-root": {
      margin: theme.spacing(1),
      width: 150,
    },
    "& .MuiFormControl-root": {
      margin: theme.spacing(1),
      //width: 400,
    },
    padding: 5,
    justifyContent: "center",
  },
  paper: {
    padding: "5px",
  },
  header: {
    padding: "5px",
  },
  icon: {
    paddingTop: 0,
  },
  left: {
    paddingRight: 20,
  },
}));

export default function IssueLogger() {
  const history = useNavigate();
  const [claims] = useRecoilState(claimsState);
  const [isLoading, setIsloading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const {classes} = useStyles();
  const [subject, setSubject] = React.useState("");
  const [description, setDescription] = React.useState("");

  const canSubmit = () => {
    return subject.length > 0 && description.length > 0;
  };

  const handleSubmit = () => {
    setIsloading(true);
    addIssue(
      { subject, description },
      claims.companyName,
      auth.currentUser.email
    )
      .then(() => {
        setIsloading(false);
        setIsSubmitted(true);
      })
      .catch((e) => {
        alert(e);
        setIsloading(false);
      });
  };

  if (isSubmitted) {
    return (
      <Grid container className={classes.root}>
        <Grid item>
          <Paper className={classes.paper} elevation={6}>
            <Grid container>
              <Grid item xs={12}>
                <Typography component="h1" variant="h6">
                  Thank you!
                </Typography>
                <Typography variant="subtitle1">
                  Your query was submitted successfully.
                </Typography>
                <Typography variant="subtitle1">
                  One of our consultants will contact you as soon as possible.
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => history.goBack()}
                >
                  Continue
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    );
  }

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <Grid container className={classes.root}>
      <Grid item xs={6}>
        <Paper className={classes.paper} elevation={6}>
          <Grid container item xs={12}>
            <Grid container item xs={6} className={classes.left}>
              <Typography
                className={classes.header}
                component="h1"
                variant="h6"
              >
                Submit a query
              </Typography>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  value={subject}
                  error={subject.length < 3}
                  required
                  size="small"
                  onChange={(e) => setSubject(e.target.value)}
                  label="Subject"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  value={description}
                  error={description.length === 0}
                  required
                  size="small"
                  onChange={(e) => setDescription(e.target.value)}
                  label="Description"
                  variant="outlined"
                  multiline={true}
                  rows={8}
                />
              </Grid>
              <Grid item xs={12}>
                <Button variant="contained" onClick={() => history.goBack()}>
                  Cancel
                </Button>
                <Button
                  disabled={!canSubmit()}
                  variant="contained"
                  color="primary"
                  onClick={() => handleSubmit()}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
            <Grid container item xs={6}>
              <Grid item xs={12}>
                <Typography
                  className={classes.header}
                  component="h1"
                  variant="h6"
                >
                  Contact us
                </Typography>
              </Grid>

              <Grid item container>
                <Grid item xs={2}>
                  <MailOutlineIcon className={classes.icon} />
                </Grid>
                <Grid item xs={10}>
                  <Typography variant="subtitle1">
                    General queries:
                    <a href={"mailto:info@uhlu.co.za"}>info@uhlu.co.za</a>
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <ContactPhoneIcon className={classes.icon} />
                </Grid>
                <Grid item xs={10}>
                  <Typography variant="subtitle1">
                    Phone: 078 746 1903
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}
