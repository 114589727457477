import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { Chip, Container, IconButton, Typography } from "@mui/material";
import moment from "moment";
import React from "react";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  container: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: `100%`,
    "max-width": "100%",
    padding: "5px",
  },
  chip: {
    marginRight: "5px",
  },
}));

function TimesheetFilter(props) {
  const { filter, fromDate, toDate, onClick, dateChanged } = props;
  const {classes} = useStyles();

  const title =
    moment(fromDate).format("D MMM") + " - " + moment(toDate).format("D MMM");

  let moveDateBack = () => {
    let fdate = moment(fromDate).subtract(7, "day").toDate();
    let tdate = moment(toDate).subtract(7, "day").toDate();
    dateChanged(fdate, tdate);
  };

  let moveDateForward = () => {
    let fdate = moment(fromDate).add(7, "day").toDate();
    let tdate = moment(toDate).add(7, "day").toDate();
    dateChanged(fdate, tdate);
  };

  return (
    <Container className={classes.container}>
      <Typography component="h1" variant="h6">
        <IconButton title="Previous Week" onClick={moveDateBack} size="large">
          <ArrowLeftIcon />
        </IconButton>
        {title}
        <IconButton title="Next Week" onClick={moveDateForward} size="large">
          <ArrowRightIcon />
        </IconButton>
      </Typography>
      {filter.map((item) => {
        return (
          <Chip
            className={classes.chip}
            key={item.display}
            label={item.display}
            size="small"
            color="primary"
            onDelete={() => onClick(item.value)}
          />
        );
      })}
    </Container>
  );
}

export default TimesheetFilter;
