import { Grid } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { makeStyles } from "tss-react/mui";
import {
  createProduct,
  deleteProducts,
  getProducts,
  updateProduct,
} from "../../services/productsService";
import { claimsState } from "../../state/claimsState";
import LoadingIndicator from "../LoadingIndicator";
import StandardTable from "../StandardTable";
import ProductEditor from "./ProductEditor";

const useStyles = makeStyles()((theme) => ({
  grid: {
    alignItems: "flex-start",
    justifyContent: "flex-end",
  },
  gridEditMode: {
    justifyContent: "center",
  },
  createButton: {
    margin: "5px",
  },
  paper: {},
}));

function ProductsView(props) {
  const [claims] = useRecoilState(claimsState);
  const [isLoading, setIsloading] = useState(false);
  const [products, setProducts] = useState([]);
  const [editMode, setEditMode] = React.useState(false);
  const [editProduct, setEditProduct] = React.useState({});
  const [createMode, setCreateMode] = React.useState(false);
  const {classes} = useStyles();

  useEffect(() => {
    setIsloading(true);
    getProducts(claims.companyId).then((products) => {
      setProducts(products);
      setIsloading(false);
    });
  }, [claims.companyId, setProducts]);

  let onEdit = (product) => {
    setEditMode(true);
    setEditProduct(product);
  };

  let onDelete = (products) => {
    if (window.confirm("Are you sure you want to delete these Products?")) {
      setIsloading(true);
      deleteProducts(products).then(() => {
        getProducts(claims.companyId).then((products) => {
          setProducts(products);
          setIsloading(false);
        });
      });
    }
  };

  let handleCreateClick = (product) => {
    setIsloading(true);
    createProduct(claims.companyId, product)
      .then((s) => {
        getProducts(claims.companyId).then((products) => {
          setProducts(products);
          setIsloading(false);
          setCreateMode(false);
        });
      })
      .catch((e) => {
        alert(e);
        setIsloading(false);
        setCreateMode(false);
      });
  };

  let handleUpdateClick = (product) => {
    setIsloading(true);
    updateProduct(claims.companyId, product)
      .then((s) => {
        getProducts(claims.companyId).then((products) => {
          setProducts(products);
          setIsloading(false);
          setEditMode(false);
          setEditProduct({});
        });
      })
      .catch((e) => {
        alert(e);
        setIsloading(false);
        setEditMode(false);
      });
  };

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (createMode) {
    return (
      <Grid container className={classes.gridEditMode}>
        <Grid item>
          <ProductEditor
            title="Create Product"
            onSave={handleCreateClick}
            onCancel={() => {
              setCreateMode(false);
            }}
          />
        </Grid>
      </Grid>
    );
  }

  if (editMode) {
    return (
      <Grid container className={classes.gridEditMode}>
        <Grid item>
          <ProductEditor
            title="Edit Product"
            product={editProduct}
            onSave={handleUpdateClick}
            onCancel={() => {
              setEditMode(false);
              setEditProduct({});
            }}
          />
        </Grid>
      </Grid>
    );
  }

  const columns = [
    {
      Header: "Name",
      accessor: "name", // accessor is the "key" in the data
    },
    {
      Header: "Category",
      accessor: "category",
    },
    {
      Header: "Price",
      accessor: "price",
      Cell: ({ cell }) => "R " + cell.row.values.price,
    },
    {
      Header: "Recurring",
      accessor: "recurring",
    },
    {
      Header: "Due Month",
      accessor: "startDate", //
      Cell: ({ cell }) =>
        cell.row.values.startDate !== undefined
          ? moment()
              .month(cell.row.values.startDate - 1)
              .format("MMM")
          : "",
    },
  ];

  return (
    <React.Fragment>
      <StandardTable
        canEdit={true}
        canDelete={true}
        canAdd={true}
        addUserHandler={() => {
          setCreateMode(true);
        }}
        onEdit={onEdit}
        deleteUserHandler={onDelete}
        title=""
        data={products}
        columns={columns}
      />
    </React.Fragment>
  );
}

export default ProductsView;
