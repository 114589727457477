import { getClients } from "./clientsService";
import { db } from "./firestore";

export const getAllCompanies = async () => {
  return await db
    .collection("companies")
    .get()
    .then((comps) => {
      return Promise.all(
        comps.docs.map(async (doc) => {
          let company = { uid: doc.id, ...doc.data() };

          let clients = await getClients(company.uid);

          return {
            name: company.name,
            createdAt: company.createdAt ? company.createdAt.toDate() : '',
            userCount: company.users.length,
            clients: clients,
          };
        })
      );
    });
};
