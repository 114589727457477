import firebase from "firebase/compat/app";

export const sendInviteEmail = (to, token) => {
  var templateBody = getTemplateBody();
  //1. {{domain}} the domain of the Dr. Bill site
  //2. {{invitelink}} the actual link that includes the invitetoken
  const domain = window.location.host;
  const invitelink = "signup?invitetoken=" + token;

  templateBody = templateBody.replace(/{{domain}}/gi, domain);
  templateBody = templateBody.replace(/{{invitelink}}/gi, invitelink);

  const data = {
    to: to,
    subject: "You've been invited by Uhlu",
    text: templateBody,
    html: templateBody,
  };

  var functions = firebase.app().functions("europe-west2");
  var genericEmail = functions.httpsCallable("genericEmail");

  return genericEmail(data);
};

const getTemplateBody = () => {
  return `<table role="presentation" border="0" cellpadding="0" cellspacing="0">
    <tr>
      <td>
        <p>Hi there,</p>
        <p>You have been invited to join a practise on Uhlu</p>
        <p>Please visit the following link to register and join now:</p>
        <p>
          <a href="http://app.uhlu.co.za/{{invitelink}}">
            Register Now.
          </a>
        </p>
        <p>The Uhlu Team</p>
        <p>
          <a href="http://www.uhlu.co.za">www.uhlu.co.za</a>
        </p>
        <p>
          Having trouble logging in? Please{" "}
          <a href="mailto:info@uhlu.co.za">contact</a> our support team
        </p>
      </td>
    </tr>
  </table>`;
};
