import { db } from "./firestore";
import firebase from 'firebase/compat/app';
import moment from "moment";
import { getCostings } from "./costingsService";
import { getFromCache } from "../cache/cache";

export const getTopClientsForMonth = (companyId, fromDate, toDate) => {
  function SortByTotalCost(a, b) {
    if (a.totalCost > b.totalCost) return -1;
    if (b.totalCost > a.totalCost) return 1;

    return 0;
  }

  function SortByClient(a, b) {
    if (a.client > b.client) return 1;
    if (b.client > a.client) return -1;

    return 0;
  }

  let checkForOrange = (cost, price) => {
    let perc = 100 - (cost / price) * 100;

    return perc <= 10;
  };

  return getCostings(companyId, fromDate, toDate).then((costings) => {
    costings.sort(SortByTotalCost);

    let values = costings.slice(0, 10);
    values.sort(SortByClient);

    let labels = values.map((costing) => {
      return costing.client;
    });
    let actualCost = values.map((costing) => {
      return costing.totalCost;
    });
    let packageCost = values.map((costing) => {
      return costing.packagePrice;
    });
    let colours = values.map((costing) => {
      return costing.totalCost > costing.packagePrice
        ? "#f44336"
        : checkForOrange(costing.totalCost, costing.packagePrice)
        ? "#ff9800"
        : "#4caf50";
    });

    return {
      labels: labels,
      actualCost: actualCost,
      packageCost: packageCost,
      colours: colours,
    };
  });
};

export const getTaskStatusesForMonth = (companyId, date) => {
  const companyDocRef = firebase
    .firestore()
    .collection("companies")
    .doc(companyId);

  var firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
  var lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 2, 0);

  let query = db
    .collection("tasks")
    .where("companyRef", "==", companyDocRef)
    .where("due", ">=", firstDayOfMonth)
    .where("due", "<", lastDayOfMonth)
    .orderBy("due");

  let cacheKey =
    "taskStatusChart_" + companyId + firstDayOfMonth + lastDayOfMonth;

  return getFromCache(cacheKey, query, (snapshot) => {
    let tasks = snapshot.docs.map((taskRef) => {
      let dueDate = moment(taskRef.data().due.toDate());

      let task = taskRef.data();

      return { ...task, uid: taskRef.id, dueDate: dueDate.format("MMM Do") };
    });

    let notStartedCount = tasks.filter((task) => {
      return task.status === "notstarted";
    }).length;
    let inprogressCount = tasks.filter((task) => {
      return task.status === "inprogress";
    }).length;
    let completedCount = tasks.filter((task) => {
      return task.status === "completed";
    }).length;

    return [notStartedCount, inprogressCount, completedCount];
  }).catch((err) => {
    console.error("Error: " + err);
    throw err;
  });
};

export const getTaskStatusesPerEmployee = (companyId, date) => {
  const companyDocRef = firebase
    .firestore()
    .collection("companies")
    .doc(companyId);

  var firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
  var lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 2, 0);

  let query = db
    .collection("tasks")
    .where("companyRef", "==", companyDocRef)
    .where("due", ">=", firstDayOfMonth)
    .where("due", "<", lastDayOfMonth)
    .orderBy("due");

  let cacheKey =
    "taskStatusPerEmployeeChart_" + companyId + firstDayOfMonth + lastDayOfMonth;

  return getFromCache(cacheKey, query, (snapshot) => {
    let tasks = snapshot.docs.map((taskRef) => {
      let dueDate = moment(taskRef.data().due.toDate());

      let task = taskRef.data();

      return { ...task, uid: taskRef.id, dueDate: dueDate.format("MMM Do") };
    });

    //We want to see Tasks per Employee, so Group By Assigned
    let groupedByAssigned = groupBy(tasks, (entry) => {
      return entry.assigned;
    });

    let labels = groupedByAssigned.map((group) => {
      return group.key;
    });

    let notStarted = groupedByAssigned.map((group) => {
      return sum(group.values, (task) => {
        return task.status === "notstarted" ? 1 : 0;
      });
    });
    let inProgress = groupedByAssigned.map((group) => {
      return sum(group.values, (task) => {
        return task.status === "inprogress" ? 1 : 0;
      });
    });
    let completed = groupedByAssigned.map((group) => {
      return sum(group.values, (task) => {
        return task.status === "completed" ? 1 : 0;
      });
    });

    return {
      labels: labels,
      notStarted: notStarted,
      inProgress: inProgress,
      completed: completed,
    };
  }).catch((err) => {
    console.error("Error: " + err);
    throw err;
  });
};

const sum = function (items, prop) {
  return items.reduce(function (a, b) {
    if (prop instanceof Function) {
      let val = prop(b);
      return a + val;
    } else if (b[prop] !== undefined) {
      return a + b[prop];
    } else return a;
  }, 0);
};

var groupBy = function (xs, key) {
  return xs.reduce(function (rv, x) {
    let v = key instanceof Function ? key(x) : x[key];
    let el = rv.find((r) => r && r.key === v);
    if (el) {
      el.values.push(x);
    } else {
      rv.push({ key: v, values: [x] });
    }

    return rv;
  }, []);
};
