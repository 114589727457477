import { db } from "./firestore";
import firebase from "firebase/compat/app";
import { getFromCache } from "../cache/cache";
import { getClients } from "./clientsService";
import { toHashMap } from "../helpers/helpers";

export const getCostings = async (companyId, fromDate, toDate) => {
  const companyDocRef = firebase
    .firestore()
    .collection("companies")
    .doc(companyId);

  let query = db
    .collection("timeEntries")
    .where("companyRef", "==", companyDocRef)
    .where("endTime", ">=", fromDate)
    .where("endTime", "<=", toDate)
    .orderBy("endTime");

  let cacheKey = "costings_" + companyId + fromDate + toDate;

  let clients = await getClients(companyId);
  let clientHash = toHashMap(clients, "uid");

  return getFromCache(cacheKey, query, (snapshot) => {
    let tasks = snapshot.docs
      .map((taskRef) => {
        return taskRef.data();
      })
      .sort(compareTimeEntries);

    let groupedByClient = groupBy(tasks, (entry) => {
      return entry.clientRef.id;
    });

    return groupedByClient.map((group) => {
      let task = group.values[0];
      let totalTime = 0;
      let totalCost = 0;
      group.values.forEach(function (entry) {
        let elapsedTime = entry.endTime - entry.startTime;

        //Note: Charging Clients per 15min
        let minutes = Math.floor(elapsedTime / 60);
        let sections = Math.floor(minutes / 15);

        totalCost = totalCost + sections * entry.recordedByRate;
        totalTime = totalTime + elapsedTime;
      });

      let client = clientHash[group.key];

      return {
        uid: task.clientRef.id,
        client: client !== undefined ? client.name : task.clientName,
        packageName:
          client !== undefined ? client.package.name : task.packageName,
        packagePrice:
          client !== undefined ? client.package.price : task.packagePrice,
        totalTime: totalTime,
        totalCost: totalCost,
      };
    });
  }).catch((err) => {
    console.error("Error: " + err);
    throw err;
  });
};

export const getProfitibility = async (companyId, fromDate, toDate, filter) => {
  const companyDocRef = firebase
    .firestore()
    .collection("companies")
    .doc(companyId);

  let query = db
    .collection("timeEntries")
    .where("companyRef", "==", companyDocRef)
    .where("endTime", ">=", fromDate)
    .where("endTime", "<=", toDate)
    .orderBy("endTime");

  if (filter) {
    filter.forEach((element) => {
      query = query.where(element.field, "==", element.value);
    });
  }

  let cacheKey = "profitibility_" + companyId + fromDate + toDate + JSON.stringify(filter);

  return getFromCache(cacheKey, query, (snapshot) => {
    let tasks = snapshot.docs
      .map((taskRef) => {
        return taskRef.data();
      })
      .sort(compareTimeEntries);

    let groupedByClient = groupBy(tasks, (entry) => {
      return entry.clientRef.id;
    });

    return groupedByClient
      .map((group) => {
        let groupedByTaskName = groupBy(group.values, (entry) => {
          return entry.name;
        });

        let client = groupedByTaskName[0].values[0];

        return groupedByTaskName
          .map((taskGroup) => {
            let groupedByUser = groupBy(taskGroup.values, (entry) => {
              return entry.recordedByName;
            });

            return groupedByUser
              .map((userGroup) => {
                let totalTime = 0;
                let totalCost = 0;
                userGroup.values.forEach(function (entry) {
                  let elapsedTime = entry.endTime - entry.startTime;

                  //Note: Charging Clients per 15min
                  let minutes = Math.floor(elapsedTime / 60);
                  let sections = Math.floor(minutes / 15);

                  totalCost = totalCost + sections * entry.recordedByRate;
                  totalTime = totalTime + elapsedTime;
                });

                return {
                  clientName: client.clientName,
                  taskName: taskGroup.values[0].name,
                  recordedBy: userGroup.key,
                  recordedByRate: userGroup.values[0].recordedByRate,
                  price: taskGroup.values[0].price,
                  totalTime: totalTime,
                  totalCost: totalCost,
                };
              })
              .flat();
          })
          .flat();
      }).flat();



      
  }).catch((err) => {
    console.error("Error: " + err);
    throw err;
  });
};

function compareTimeEntries(a, b) {
  if (a.clientName > b.clientName) return 1;
  if (b.clientName > a.clientName) return -1;

  if (a.category > b.category) return 1;
  if (b.category > a.category) return -1;

  return 0;
}

var groupBy = function (xs, key) {
  return xs.reduce(function (rv, x) {
    let v = key instanceof Function ? key(x) : x[key];
    let el = rv.find((r) => r && r.key === v);
    if (el) {
      el.values.push(x);
    } else {
      rv.push({ key: v, values: [x] });
    }

    return rv;
  }, []);
};
