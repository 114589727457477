import React from 'react';
import { CircularProgress, Grid } from '@mui/material';

export default function LoadingIndicator() {
    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
          
        >
            <CircularProgress />
        </Grid>
    )
}
