import EditIcon from "@mui/icons-material/Edit";
import { Checkbox, IconButton } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Typography from "@mui/material/Typography";
import React from "react";
import {
  useGlobalFilter,
  useGroupBy,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import { makeStyles } from "tss-react/mui";
import TableToolbar from "./TableToolbar";

const useStyles = makeStyles()((theme) => ({
  table: {
    minWidth: 650,
    marginBottom: "20px",
    borderBottom: "2px lightgray solid",
  },
  typography: {
    paddingLeft: "10px",
    color: "gray",
  },
}));

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <Checkbox color="primary" ref={resolvedRef} {...rest} />
      </>
    );
  }
);

export default function StandardTable(props) {
  const {classes} = useStyles();
  const {
    data,
    title,
    columns,
    addUserHandler,
    deleteUserHandler,
    onEdit,
    canEdit,
    canSearch = true,
    canAdd,
    canDelete,
    sortBy,
    hiddenColumns = [],
  } = props;

  const tableData = React.useMemo(() => data, [data]);
  const tableColumns = React.useMemo(() => columns, [columns]);

  if (!canDelete) {
    hiddenColumns.push("selection");
  }
  if (!canEdit) {
    hiddenColumns.push("edit");
  }

  const {
    getTableProps,
    headerGroups,
    rows,
    prepareRow,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { selectedRowIds, globalFilter },
  } = useTable(
    {
      columns: tableColumns,
      data: tableData,
      initialState: {
        hiddenColumns,
        sortBy: sortBy || [],
      },
    },
    useGlobalFilter,
    useGroupBy,
    useSortBy,
    useRowSelect,
    (hooks) => {
      hooks.allColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: "selection",
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox.  Pagination is a problem since this will select all
          // rows even though not all rows are on the current page.  The solution should
          // be server side pagination.  For one, the clients should not download all
          // rows in most cases.  The client should only download data for the current page.
          // In that case, getToggleAllRowsSelectedProps works fine.
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
        {
          id: "edit",
          Cell: ({ row }) => (
            <div>
              <IconButton
                title="Edit"
                aria-label="edit"
                onClick={() => onEdit(row.original)}
                disabled={!canEdit}
                size="small"
              >
                <EditIcon />
              </IconButton>
            </div>
          ),
        },
      ]);
    }
  );

  const getByIndexs = (array, indexs) =>
    array.filter((_, i) => indexs.includes(i));

  const deleteItems = (event) => {
    const removedItems = getByIndexs(
      data,
      Object.keys(selectedRowIds).map((x) => parseInt(x, 10))
    );
    deleteUserHandler(removedItems);
  };

  var searchBar = canSearch ? (
    <TableToolbar
      numSelected={Object.keys(selectedRowIds).length}
      deleteUserHandler={deleteItems}
      addUserHandler={addUserHandler}
      preGlobalFilteredRows={preGlobalFilteredRows}
      setGlobalFilter={setGlobalFilter}
      globalFilter={globalFilter}
      title={title}
      canDelete={canDelete}
      canAdd={canAdd}
    />
  ) : (
    <></>
  );

  return (
    <TableContainer>
      <Typography className={classes.typography} component="h1" variant="h6" />
      {searchBar}
      <Table {...getTableProps()}>
        <TableHead>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <TableCell
                  {...(column.id === "selection"
                    ? column.getHeaderProps()
                    : column.getHeaderProps(column.getSortByToggleProps()))}
                >
                  {column.render("Header")}
                  {column.id !== "selection" ? (
                    <TableSortLabel
                      active={column.isSorted}
                      // react-table has a unsorted state which is not treated here
                      direction={column.isSortedDesc ? "desc" : "asc"}
                    />
                  ) : null}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <TableRow {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <TableCell {...cell.getCellProps()}>
                      {cell.render("Cell")}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      {rows.length === 0 ? (
        <div className={classes.typography}>No items found</div>
      ) : (
        <></>
      )}
    </TableContainer>
  );
}
