import { Button, Grid, Paper, Typography } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "tss-react/mui";
import ContentHeader from "../../components/ContentHeader";
import Dropdown from "../../components/Dropdown";

const useStyles = makeStyles()((theme) => ({
  root: {
    padding: "5px",
  },
  typography: {
    margin: "5px",
  },
  paper: {
    padding: "15px",
  },
  button: {
    marginLeft: "5px",
    marginTop: "2px",
  },
}));

export default function Reports() {
  const navigate = useNavigate();
  const {classes} = useStyles();
  let years = [new Date().getFullYear(), new Date().getFullYear() - 1];
  const [year, setYear] = useState(years[0]);

  return (
    <div className={classes.root}>
      <ContentHeader title="Reports" />
      <Grid container>
        <Grid item>
          <Paper className={classes.paper} elevation={3}>
            <Typography className={classes.typography}>
              Annual Task Report
            </Typography>{" "}
            <Dropdown
              items={years}
              value={year}
              onChange={(value) => setYear(value)}
            />
            <Button
              className={classes.button}
              variant="contained"
              onClick={() =>
                navigate("/reports/annualtasksstatus", {
                  state: { year: year },
                })
              }
            >
              View
            </Button>
          </Paper>
        </Grid>
      </Grid>
      {/* <Button
        variant="contained"
        color="default"
        onClick={() => history.push("/reports/annualtasksstatus")}
      >
        Annual Tasks Statuses
      </Button> */}
    </div>
  );
}
