import { db, analytics } from "./firestore";
import firebase from 'firebase/compat/app';
import { getFromCache } from "../cache/cache";
import { getProducts } from "./productsService";

export const getPackageById = async (companyId, packageId) => {
  return db
    .collection("packages")
    .doc(packageId)
    .get()
    .then(async ($package) => {
      let allProducts = await getProducts(companyId);

      let p = $package.data();

      p.products = p.products
        .map((d) => {
          return allProducts.find((p) => p.uid === d.id);
        })
        .filter((p) => p !== undefined);

      return { ...p, uid: $package.id };
    })
    .catch((err) => {
      console.error("Error: " + err);
    });
};

export const getPackages = async (companyId) => {
  const companyDocRef = firebase
    .firestore()
    .collection("companies")
    .doc(companyId);

  let query = db
    .collection("packages")
    .where("company", "==", companyDocRef)
    .orderBy("name");

  let cacheKey = "packages_" + companyId;
  let allProducts = await getProducts(companyId);

  return getFromCache(cacheKey, query, (snapshot) => {
    if (!snapshot.empty) {
      let packages = snapshot.docs.map((doc) => {
        let p = doc.data();

        p.products = p.products
          .map((d) => {
            return allProducts.find((p) => p.uid === d.id);
          })
          .filter((p) => p !== undefined);

        return { ...p, uid: doc.id };
      });

      return packages;
    } else {
      return [];
    }
  }).catch((err) => {
    console.error("Error: " + err);
  });
};

export const createPackage = async (companyId, $package) => {
  analytics.logEvent("package_create", { name: $package.name });

  const companyDocRef = firebase
    .firestore()
    .collection("companies")
    .doc(companyId);

  $package.products = $package.products.map((product) => {
    return db.doc("products/" + product.uid);
  });

  return db.collection("packages").add({
    name: $package.name,
    price: parseInt($package.price),
    products: $package.products,
    company: companyDocRef,
    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
  });
};

export const updatePackage = async (companyId, $package) => {
  analytics.logEvent("package_update", { name: $package.name });

  $package.products = $package.products.map((product) => {
    return db.doc("products/" + product.uid);
  });

  const companyDocRef = firebase
    .firestore()
    .collection("companies")
    .doc(companyId);

  return db
    .collection("packages")
    .doc($package.uid)
    .set(
      {
        name: $package.name,
        price: parseInt($package.price),
        products: $package.products,
        company: companyDocRef,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      },
      { merge: true }
    );
};

export const deletePackages = async ($packages) => {
  let deletePromises = [];

  $packages.forEach(($package) => {
    analytics.logEvent("package_delete", { name: $package.name });
    deletePromises.push(db.collection("packages").doc($package.uid).delete());
  });

  return Promise.all(deletePromises);
};
