import {
  Autocomplete,
  Button,
  Grid,
  Tab,
  Tabs,
  TextField,
  createFilterOptions,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import { makeStyles } from "tss-react/mui";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import {
  createClient,
  deleteClients,
  getClients,
  updateClient,
} from "../../services/clientsService";
import { getCompanyTypes } from "../../services/lookupService";
import { claimsState } from "../../state/claimsState";
import LoadingIndicator from "../LoadingIndicator";
import StandardTable from "../StandardTable";
import TabPanel from "../TabPanel";
import ClientCreate from "./ClientCreate";
import ClientEdit from "./ClientEdit";
import ClientTasks from "./ClientTasks";

const useStyles = makeStyles()((theme) => ({
  grid: {
    alignItems: "flex-start",
    justifyContent: "flex-end",
  },
  gridEditMode: {
    justifyContent: "center",
  },
  createButton: {
    margin: "5px",
  },
  paper: {},
  tabs: {
    borderRight: `1px solid grey`,
  },
  searchContainer: {
    paddingLeft: 15,
    paddingRight: 15,
  },
  link: {
    color: theme.palette.text.primary,
  },
}));

function ClientsView(props) {
  const [claims] = useRecoilState(claimsState);
  const [isLoading, setIsloading] = useState(false);
  const [editMode, setEditMode] = React.useState(false);
  const [editClient, setEditClient] = React.useState({});
  const [createMode, setCreateMode] = React.useState(false);
  const [createModeDefaultType, setCreateModeDefaultType] = React.useState("");
  const [taskMode, setTaskMode] = React.useState(false);
  const [tabValue, setTabValue] = useLocalStorage("clientsSelectedTab", 0);
  const [clients, setClients] = useState([]);
  const {classes} = useStyles();

  useEffect(() => {
    setIsloading(true);
    console.log("Getting Clients");
    getClients(claims.companyId).then((clients) => {
      setClients(clients);
      setIsloading(false);
    });
  }, [claims.companyId, setClients]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  let handleCreateClick = (client) => {
    setIsloading(true);
    createClient(claims.companyId, client)
      .then((s) => {
        getClients(claims.companyId).then((clients) => {
          setClients(clients);
          setIsloading(false);
          setCreateMode(false);
        });
      })
      .catch((e) => {
        alert(e);
        setIsloading(false);
        setCreateMode(false);
      });
  };

  let handleUpdateClick = (client) => {
    setIsloading(true);
    updateClient(claims.companyId, client)
      .then((s) => {
        getClients(claims.companyId).then((clients) => {
          setClients(clients);
          setIsloading(false);
          setEditMode(false);
          setEditClient({});
        });
      })
      .catch((e) => {
        alert(e);
        setIsloading(false);
        setEditMode(false);
      });
  };

  let onDelete = (clients) => {
    if (
      window.confirm(
        "Are you sure you want to delete this Client? It will delete all associated Tasks too and can not be undone."
      )
    ) {
      setIsloading(true);
      deleteClients(clients).then(() => {
        getClients(claims.companyId).then((clients) => {
          setClients(clients);
          setIsloading(false);
        });
      });
    }
  };

  let onEdit = (client) => {
    setEditMode(true);
    setEditClient(client);
  };

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (createMode) {
    return (
      <Grid container className={classes.gridEditMode}>
        <Grid item>
          <ClientCreate
            defaultType={createModeDefaultType}
            onSave={handleCreateClick}
            onCancel={() => {
              setCreateMode(false);
            }}
          />
        </Grid>
      </Grid>
    );
  }

  if (editMode) {
    return (
      <Grid container className={classes.gridEditMode}>
        <Grid item>
          <ClientEdit
            client={editClient}
            onSave={handleUpdateClick}
            onCancel={() => {
              setEditMode(false);
              setEditClient({});
            }}
          />
        </Grid>
      </Grid>
    );
  }

  if (taskMode) {
    return (
      <Grid container className={classes.gridEditMode}>
        <Grid item>
          <ClientTasks
            client={editClient}
            onCancel={() => {
              setTaskMode(false);
              setEditClient({});
            }}
          />
        </Grid>
      </Grid>
    );
  }

  const columns = [
    {
      Header: "Name",
      accessor: "name",
      Cell: ({ cell }) => (
        <Link
          className={classes.link}
          variant="contained"
          color="default"
          onClick={() => {
            setEditClient(cell.row.original);
            setEditMode(true);
          }}
        >
          {cell.row.values.name}
        </Link>
      ),
    },

    {
      Header: "Registration Number",
      accessor: "registrationNumber",
    },
    {
      Header: "Income Tax Number",
      accessor: "incomeTaxNumber",
    },
    {
      Header: "Contact Person",
      accessor: "contactPerson",
    },
    {
      Header: "Cell Nr.",
      accessor: "cellNr",
    },
    {
      Header: "Email",
      accessor: "email",
      Cell: ({ cell }) =>
        cell.row.values.email !== undefined ? (
          <a href={"mailto:" + cell.row.values.email}>
            {cell.row.values.email}
          </a>
        ) : (
          <></>
        ),
    },
    {
      Header: "Package",
      accessor: "package.name",
    },
    {
      Header: "Assigned",
      accessor: "assignedName",
    },
    {
      Header: "Tasks",
      accessor: "uid",
      Cell: ({ cell }) => (
        <Button
          variant="contained"
          onClick={() => {
            setEditClient(cell.row.values);
            setTaskMode(true);
          }}
        >
          Tasks
        </Button>
      ),
    },
  ];

  const companyTypes = getCompanyTypes();

  if (clients.length === 0) {
    return (
      <Button variant="contained" onClick={() => setCreateMode(true)}>
        Click to add first Client
      </Button>
    );
  }

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    stringify: (option) =>
      option.name +
      option.incomeTaxNumber +
      option.registrationNumber +
      option.contactPerson,
  });

  const globalSearch = (
    <Autocomplete
      filterOptions={filterOptions}
      id="globalClientSearch"
      size="small"
      onChange={(event, client) => {
        if (client !== null) onEdit(client);
      }}
      getOptionLabel={(option) => option.name + " (" + option.type + ")"}
      options={clients}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Client Search"
          margin="normal"
          variant="outlined"
          placeholder="Search on Name, Registration Number, Income Tax Number or Contact Person"
          autoFocus
          size="small"
        />
      )}
    />
  );

  return (
    <div>
      <div className={classes.searchContainer}>{globalSearch}</div>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
      >
        {companyTypes.map((type) => {
          return <Tab key={type} label={type} />;
        })}
      </Tabs>
      {companyTypes.map((type, index) => {
        let typeData = clients.filter((client) => {
          return client.type === type;
        });
        return (
          <TabPanel key={index} value={tabValue} index={index} lazyload={true}>
            <StandardTable
              title={""}
              key={type}
              data={typeData}
              columns={columns}
              onEdit={onEdit}
              addUserHandler={() => {
                setCreateMode(true);
                setCreateModeDefaultType(type);
              }}
              deleteUserHandler={onDelete}
              //title={type}
              canEdit={true}
              canDelete={true}
              canAdd={true}
            />
          </TabPanel>
        );
      })}
    </div>
  );
}

export default ClientsView;
