export const getCompanyTypes = () => {
  return [
    "Close Corporation",
    "Trust",
    "Company",
    "Individual",
    "Body Corporate",
    "Incorporated",
  ];
};

export const getStatuses = () => {
  return [
    { display: "Not Started", value: "notstarted" },
    { display: "In Progress", value: "inprogress" },
    { display: "Completed", value: "completed" },
  ];
};

export const getCategories = () => {
  return ["Accounting", "Management & Advisory", "Taxation & Statutory"];
};
