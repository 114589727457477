import { Alert } from '@mui/material';
import React from 'react';

function ErrorIndicator(props) {
    const { message } = props;

    if(!message)
    {
        return (<></>)
    }
    return (
        <Alert severity="error">
        {message}
    </Alert>
    )
}

export default ErrorIndicator;
