import { Avatar, Button, Grid, Typography } from "@mui/material";
import moment from "moment";
import React, { useEffect } from "react";
import { useRecoilState } from "recoil";
import { makeStyles } from "tss-react/mui";
import { removeUserFromCompanyById } from "../../../services/companyService";
import {
  deleteInvite,
  getAllInvites,
  resendInviteEmail,
} from "../../../services/invitesService";
import { getUsers, updateUser } from "../../../services/userService";
import { claimsState } from "../../../state/claimsState";
import LoadingIndicator from "../../LoadingIndicator";
import StandardTable from "../../StandardTable";
import UsersEdit from "./UserEdit";
import UsersInvite from "./UsersInvite";

const useStyles = makeStyles()((theme) => ({
  grid: {
    alignItems: "flex-start",
    justifyContent: "flex-end",
  },
  gridEditMode: {
    justifyContent: "center",
  },
  inviteButton: {
    margin: "5px",
  },
  deleteButton: {
    marginLeft: "15px",
  },
  title: {
    paddingLeft: "10px",
  },
}));

function UsersView(props) {
  const {classes} = useStyles();
  const [editMode, setEditMode] = React.useState(false);
  const [inviteMode, setInviteMode] = React.useState(false);
  const [claims] = useRecoilState(claimsState);
  const [isLoading, setIsloading] = React.useState(false);
  const [users, setUsers] = React.useState([]);
  const [user, setUser] = React.useState(null);
  const [invites, setInvites] = React.useState([]);

  useEffect(() => {
    setIsloading(true);
    getUsers(claims.companyId)
      .then((users) => {
        setUsers(users);
        setIsloading(false);
      })
      .catch((err) => {
        setIsloading(false);
        console.error("Error Users:" + err);
      });

    getAllInvites(claims.companyId)
      .then((invites) => {
        setInvites(invites);
      })
      .catch((err) => {
        console.error("Error Getting Invites:" + err);
        //enqueueSnackbar("Could not retrieve invites", { variant: "error" });
      });
  }, [claims.companyId]);

  const handleEditClick = (user) => {
    setUser(user);
    setEditMode(true);
  };

  const handleSaveClick = (user) => {
    setEditMode(false);
    setIsloading(true);
    updateUser(user).then(() => {
      getUsers(claims.companyId)
        .then((users) => {
          setUsers(users);
          setIsloading(false);
        })
        .catch((err) => {
          setIsloading(false);
          console.error("Error Users:" + err);
        });
    });
  };

  const handleUserDelete = (user) => {
    setIsloading(true);
    removeUserFromCompanyById(claims.companyId, user[0].uid)
      .then(() => {
        getUsers(claims.companyId)
          .then((users) => {
            setUsers(users);
            setIsloading(false);
          })
          .catch((err) => {
            setIsloading(false);
            console.error("Error Users:" + err);
          });
      })
      .catch((err) => {
        setIsloading(false);
        console.error("Error deleting User:" + err);
      });
  };

  const handleInviteDelete = (inviteUid) => {
    setIsloading(true);

    deleteInvite(inviteUid)
      .then(() => {
        //enqueueSnackbar("Invite deleted", { variant: "success" });

        getAllInvites(claims.companyId)
          .then((invites) => {
            setInvites(invites);
            setIsloading(false);
          })
          .catch((err) => {
            setIsloading(false);
            console.error("Error Getting Invites:" + err);
            //enqueueSnackbar("Could not retrieve invites", { variant: "error" });
          });
      })
      .catch((err) => {
        setIsloading(false);
        console.error("Error deleting Invite:" + err);
        //enqueueSnackbar("Could not delete invite", { variant: "error" });
      });
  };

  const handleInviteResend = (inviteUid) => {
    setIsloading(true);
    resendInviteEmail(inviteUid)
      .then(() => {
        //enqueueSnackbar("Invite sent", { variant: "success" });
        getAllInvites(claims.companyId)
          .then((invites) => {
            setInvites(invites);
            setIsloading(false);
          })
          .catch((err) => {
            setIsloading(false);
            console.error("Error Getting Invites:" + err);
            //enqueueSnackbar("Could not retrieve invites", { variant: "error" });
          });
      })
      .catch((err) => {
        setIsloading(false);
        console.error("Error resending Invite:" + err);
        //enqueueSnackbar("Could not resend invite", { variant: "error" });
      });
  };

  const columns = [
    {
      Header: "",
      accessor: "profileImageUrl",
      Cell: ({ cell }) => (
        <Avatar
          className={classes.avatar}
          src={cell.row.values.profileImageUrl}
          alt="My Avatar"
        />
      ),
    },
    {
      Header: "Name",
      accessor: "displayName", // accessor is the "key" in the data
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Roles",
      accessor: "roles",
    },
    {
      Header: "Status",
      accessor: "status",
    },
    {
      Header: "Rate (per 15min)",
      accessor: "rate",
      Cell: ({ cell }) => "R " + cell.row.values.rate,
    },
  ];

  const inviteColumns = [
    {
      Header: "Email",
      accessor: "email", // accessor is the "key" in the data
    },
    {
      Header: "Created",
      accessor: "createdAt",
      Cell: ({ cell }) =>
        moment(cell.row.values.createdAt).format("yyyy-MM-DD HH:mm:ss"),
    },
    {
      Header: "Last Sent",
      accessor: "sent",
      Cell: ({ cell }) =>
        moment(cell.row.values.sent).format("yyyy-MM-DD HH:mm:ss"),
    },
    {
      Header: "",
      accessor: "uid",
      Cell: ({ cell }) => (
        <React.Fragment>
          <Button
            size="small"
            color="primary"
            variant="contained"
            title="Resend this Invite email again"
            onClick={() => handleInviteResend(cell.row.values.uid)}
            disabled={isLoading}
          >
            Resend
          </Button>
          <Button
            className={classes.deleteButton}
            size="small"
            variant="contained"
            title="Delete this Invite"
            onClick={() => handleInviteDelete(cell.row.values.uid)}
            disabled={isLoading}
          >
            Delete
          </Button>
        </React.Fragment>
      ),
    },
  ];

  const invitesList =
    invites.length > 0 ? (
      <React.Fragment>
        <Typography variant="h6" component="h6" className={classes.title}>
          Existing Invites
        </Typography>
        <StandardTable
          data={invites}
          columns={inviteColumns}
          canEdit={false}
          canDelete={false}
          canAdd={false}
          canSearch={false}
        />
      </React.Fragment>
    ) : (
      <></>
    );

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (editMode) {
    return (
      <Grid container className={classes.gridEditMode}>
        <Grid item>
          <UsersEdit
            user={user}
            onSave={handleSaveClick}
            onCancel={() => setEditMode(false)}
          />
        </Grid>
      </Grid>
    );
  }

  if (inviteMode) {
    return (
      <UsersInvite
        onClose={() => {
          setInviteMode(false);

          getAllInvites(claims.companyId)
            .then((invites) => {
              setInvites(invites);
            })
            .catch((err) => {
              console.error("Error Getting Invites:" + err);
            });
        }}
      />
    );
  }

  return (
    <React.Fragment>
      <StandardTable
        data={users}
        columns={columns}
        onEdit={handleEditClick}
        addUserHandler={() => {
          setInviteMode(true);
        }}
        deleteUserHandler={handleUserDelete}
        title="Invite"
        canEdit={true}
        canDelete={true}
        canAdd={true}
        sortBy={[
          {
            id: "displayName",
            desc: false,
          },
        ]}
      />
      <br />
      {invitesList}
    </React.Fragment>
  );
}

export default UsersView;
