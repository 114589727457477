import { db, analytics } from "./firestore";
import firebase from 'firebase/compat/app';
import { getFromCache } from "../cache/cache";

export const getUserDocRef = (uid) => {
  return firebase.firestore().collection("users").doc(uid);
};

export const getUsers = async (companyId) => {
  const users = await getFromCache("users", db.collection("users"), (sna) => {
    return sna;
  });

  return db
    .collection("companies")
    .doc(companyId)
    .get()
    .then((doc) => {
      let company = doc.data();
      return company.users.map((userRef) => {
        let user = users.docs.find((user) => user.id === userRef.id);

        return { ...user.data(), uid: user.id };
      });
    })
    .catch((err) => {
      console.error("Error: " + err);
    });
};

export const getUserByEmail = (email) => {
  return db
    .collection("users")
    .where("email", "==", email)
    .get()
    .then((doc) => {
      if (!doc.empty) {
        const user = doc.docs[0];
        return { ...user.data(), uid: user.id };
      }
    })
    .catch((err) => {
      console.error("Error: " + err);
    });
};

export const updateUser = (user) => {
  analytics.logEvent("user_update", { name: user.email });

  return db
    .collection("users")
    .doc(user.uid)
    .set(
      {
        displayName: user.displayName,
        rate: parseInt(user.rate),
        status: user.status,
        roles: user.roles,
        profileImageUrl: user.profileImageUrl || '',
        company: {id: user.company.id, name: user.company.name},
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      },
      { merge: true }
    );
};
