import AssessmentIcon from "@mui/icons-material/Assessment";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import CollectionsIcon from "@mui/icons-material/Collections";
import EventNoteIcon from "@mui/icons-material/EventNote";
import FaceIcon from "@mui/icons-material/Face";
import GroupIcon from "@mui/icons-material/Group";
import HomeIcon from "@mui/icons-material/Home";
import ListIcon from "@mui/icons-material/List";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import PhotoSizeSelectActualIcon from "@mui/icons-material/PhotoSizeSelectActual";
import { Container } from "@mui/material";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import { withStyles } from "tss-react/mui";
import { LogoMain } from "../images/Images";
import { auth } from "../services/firestore";
import { claimsState } from "../state/claimsState";
import { navigationState } from "../state/navigationState";

const categories = [
  {
    id: "App",
    children: [
      { id: "Dashboard", icon: <HomeIcon />, url: "/dashboard", admin: false },
      { id: "Task List", icon: <ListIcon />, url: "/tasklist", admin: false },
      {
        id: "Timesheet",
        icon: <EventNoteIcon />,
        url: "/timesheet",
        admin: false,
      },
      { id: "Clients", icon: <FaceIcon />, url: "/clients", admin: false },
      {
        id: "Costings",
        icon: <AttachMoneyIcon />,
        url: "/costings",
        admin: true,
      },
      {
        id: "Profitability",
        icon: <MonetizationOnIcon />,
        url: "/profitability",
        admin: true,
      },
      { id: "Reports", icon: <AssessmentIcon />, url: "/reports", admin: true },
    ],
  },
  {
    id: "Settings",
    admin: true,
    children: [
      { id: "Users", icon: <GroupIcon />, url: "/users", admin: true },
      {
        id: "Packages",
        icon: <CollectionsIcon />,
        url: "/packages",
        admin: true,
      },
      {
        id: "Products",
        icon: <PhotoSizeSelectActualIcon />,
        url: "/products",
        admin: true,
      },
    ],
  },
  {
    id: "Admin",
    superuser: true,
    children: [
      {
        id: "Reports",
        icon: <AssessmentIcon />,
        url: "/admin/companies",
        superuser: true,
      },
    ],
  },
];

const styles = (theme) => ({
  categoryHeader: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  categoryHeaderPrimary: {
    color: theme.palette.common.white,
  },
  item: {
    paddingTop: 1,
    paddingBottom: 1,
    color: "rgba(255, 255, 255, 0.7)",
    "&:hover,&:focus": {
      backgroundColor: "rgba(255, 255, 255, 0.08)",
    },
  },
  itemCategory: {
    //backgroundColor: '#232f3e',
    boxShadow: "0 -1px 0 #404854 inset",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  firebase: {
    fontSize: 24,
    color: theme.palette.common.white,
  },
  itemActiveItem: {
    color: "#4fc3f7",
  },
  itemPrimary: {
    fontSize: "inherit",
  },
  itemIcon: {
    minWidth: "auto",
    marginRight: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(2),
  },
  logo: {
    width: 120,
    filter: "brightness(0) invert(1)",
  },
  logoContainer: {
    paddingLeft: 8,
  },
});

function Navigator(props) {
  const { classes, ...other } = props;
  const [selectedMenuItem, setSelectedMenuItem] =
    useRecoilState(navigationState);

  const [claims] = useRecoilState(claimsState);

  if (!auth.currentUser) {
    return <></>;
  }

  return (
    <Drawer variant="permanent" {...other}>
      <List disablePadding>
        <ListItem
          className={clsx(classes.firebase, classes.item, classes.itemCategory)}
        >
          <Container className={classes.logoContainer} maxWidth="sm">
            <LogoMain className={classes.logo} />
          </Container>
        </ListItem>
        <ListItem
          className={clsx(classes.item, classes.itemCategory)}
          button
          component={Link}
          onClick={() => setSelectedMenuItem("dashboard")}
          to="/dashboard"
        >
          <ListItemIcon className={classes.itemIcon}>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText
            classes={{
              primary: classes.itemPrimary,
            }}
          >
            Home
          </ListItemText>
        </ListItem>
        {categories.map(
          ({ id, children, admin, superuser }) =>
            ((claims.isInRole("superuser") && superuser) || !superuser) &&
            ((claims.isInRole("admin") && admin) || !admin) && (
              <React.Fragment key={id}>
                <ListItem className={classes.categoryHeader}>
                  <ListItemText
                    classes={{
                      primary: classes.categoryHeaderPrimary,
                    }}
                  >
                    {id}
                  </ListItemText>
                </ListItem>
                {children.map(
                  ({
                    id: childId,
                    icon,
                    url,
                    admin: childAdmin,
                    superuser: childSuperuser,
                  }) =>
                    ((claims.isInRole("superuser") && childSuperuser) ||
                      !childSuperuser) &&
                    ((claims.isInRole("admin") && childAdmin) ||
                      !childAdmin) && (
                      <ListItem
                        key={childId}
                        button
                        className={clsx(
                          classes.item,
                          selectedMenuItem === url && classes.itemActiveItem
                        )}
                        onClick={() => setSelectedMenuItem(url)}
                        component={Link}
                        to={url}
                      >
                        <ListItemIcon className={classes.itemIcon}>
                          {icon}
                        </ListItemIcon>
                        <ListItemText
                          classes={{
                            primary: classes.itemPrimary,
                          }}
                        >
                          {childId}
                        </ListItemText>
                      </ListItem>
                    )
                )}

                <Divider className={classes.divider} />
              </React.Fragment>
            )
        )}
      </List>
    </Drawer>
  );
}

Navigator.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(Navigator, styles);
