import { db } from "./firestore";
import firebase from 'firebase/compat/app';
import { getUserByEmail } from "./userService";

export const addCompany = (company, uid) => {
  var newOrg = {
    name: company.name,
    users: [db.doc("users/" + uid)],
    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
  };

  return db.collection("companies").add(newOrg);
};

export const addUserToCompanyByEmail = (companyId, email) => {
  return getUserByEmail(email).then((user) => {
    var orgRef = db.collection("companies").doc(companyId);
    return orgRef.update({
      users: firebase.firestore.FieldValue.arrayUnion(
        db.doc("users/" + user.uid)
      ),
    });
  });
};

export const addUserToCompanyById = (companyId, uid) => {
  var orgRef = db.collection("companies").doc(companyId);
  return orgRef.update({
    users: firebase.firestore.FieldValue.arrayUnion(db.doc("users/" + uid)),
  });
};

export const removeUserFromCompanyById = (companyId, uid) => {
  var orgRef = db.collection("companies").doc(companyId);
  return orgRef.update({
    users: firebase.firestore.FieldValue.arrayRemove(db.doc("users/" + uid)),
  });
};

export const getCompanies = async (userId) => {
  let userRef = db.collection("users").doc(userId);
  return await db
    .collection("companies")
    .where("users", "array-contains", userRef)
    .get()
    .then((comps) => {
      return comps.docs.map((doc) => {
        return { id: doc.id, ...doc.data() };
      });
    });
};
