import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";
import { makeStyles } from "tss-react/mui";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { getAnnualTaskStatus } from "../../services/reportsService";
import { claimsState } from "../../state/claimsState";
import ContentHeader from "../ContentHeader";
import LoadingIndicator from "../LoadingIndicator";
import StandardTable from "../StandardTable";
import TaskStatusChip from "../Tasklist/TaskStatusChip";
import ReportFilter from "./ReportFilter";

const useStyles = makeStyles()((theme) => ({
  rowPadding: {
    padding: "5px",
  },
  button: {
    fontWeight: "400",
    padding: "0px",
  },
}));

export default function AnnualTaskStatusReport() {
  const location = useLocation();
  const [claims] = useRecoilState(claimsState);
  const [filter, setFilter] = useLocalStorage("annualTask_reportFilter", []);
  const [isLoading, setIsloading] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [filteredTasks, setFilteredTasks] = useState([]);
  const {classes} = useStyles();

  const year = location.state.year;

  useEffect(() => {
    setIsloading(true);
    getAnnualTaskStatus(claims.companyId, year).then((tasks) => {
      setIsloading(false);
      setTasks(tasks);
      setFilteredTasks(tasks);
    });
  }, [claims.companyId, year]);

  function addToFilter(field, value, display) {
    let temp = filter.filter((item) => {
      return item.field !== field;
    });

    temp.push({
      field: field,
      value: value,
      display: display !== undefined ? display : value,
    });

    setFilter(temp);

    let fTasks = tasks;

    temp.forEach((filter) => {
      fTasks = fTasks.filter((task) => {
        return task[filter.field] === filter.value;
      });
    });

    setFilteredTasks(fTasks);
  }

  function removeFromFilter(value) {
    let temp = filter.filter((item) => {
      return item.value !== value;
    });

    setFilter(temp);

    let fTasks = tasks;

    temp.forEach((filter) => {
      fTasks = fTasks.filter((task) => {
        return task[filter.field] === filter.value;
      });
    });

    setFilteredTasks(fTasks);
  }

  if (isLoading) {
    return <LoadingIndicator />;
  }

  const columns = [
    {
      Header: "Name",
      accessor: "name",
      Cell: ({ cell }) => (
        <Button
          className={classes.button}
          onClick={() => addToFilter("name", cell.row.values.name)}
        >
          {cell.row.values.name}
        </Button>
      ),
    },
    {
      Header: "Client",
      accessor: "client",
      Cell: ({ cell }) => (
        <Button
          className={classes.button}
          onClick={() => addToFilter("client", cell.row.values.client)}
        >
          {cell.row.values.client}
        </Button>
      ),
    },
    {
      Header: "Category",
      accessor: "category",
    },

    {
      Header: "Status",
      accessor: "status",
      Cell: ({ cell }) => (
        <div className={classes.rowPadding}>
          <TaskStatusChip
            onClick={() => addToFilter("status", cell.row.values.status)}
            label={cell.row.values.status}
          ></TaskStatusChip>
        </div>
      ),
    },
    {
      Header: "Due Date",
      accessor: "dueDate",
    },
    {
      Header: "Assigned",
      accessor: "assigned",
    },
  ];

  return (
    <div>
      <ContentHeader title={"Annual Task Status Report for " + year} />
      <ReportFilter filter={filter} onClick={removeFromFilter} />
      <StandardTable
        data={filteredTasks}
        columns={columns}
        addUserHandler={() => {}}
        title=""
      />
    </div>
  );
}
