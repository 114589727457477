import { Button, Grid, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { makeStyles } from "tss-react/mui";
import { getAllTasksForClient } from "../../services/tasksService";
import LoadingIndicator from "../LoadingIndicator";
import StandardTable from "../StandardTable";
import TaskStatusChip from "../Tasklist/TaskStatusChip";
import TasklistRecorder from "../Tasklist/TasklistRecorder";

const useStyles = makeStyles()((theme) => ({
  paper: {
    width: "auto",
    [theme.breakpoints.up(620 + theme.spacing(6))]: {
      marginLeft: "auto",
      marginRight: "auto",
      width: "12in",
    },
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing(2)} ${theme.spacing(3)} ${theme.spacing(3)}`,
  },

  button: {
    fontWeight: "400",
  },
}));

function ClientTasks(props) {
  const { client, onCancel } = props;
  const {classes} = useStyles();
  const [isLoading, setIsloading] = useState(false);
  const [tasks, setTasks] = useState([]);

  useEffect(() => {
    setIsloading(true);

    getAllTasksForClient(client.uid, new Date().getFullYear()).then((tasks) => {
      setTasks(tasks);
      setIsloading(false);
    });
  }, [client.uid]);

  if (isLoading) {
    return <LoadingIndicator />;
  }

  let columns = [
    {
      id: "name",
      Header: "Task Name",
      accessor: "name",
    },
    {
      id: "category",
      Header: "Category",
      accessor: "category",
    },
    {
      id: "status",
      Header: "Status",
      accessor: "status",
      Cell: ({ cell }) => (
        <TaskStatusChip label={cell.row.values.status}></TaskStatusChip>
      ),
    },
    {
      id: "dueDate",
      Header: "Due Date",
      accessor: "dueDate",
    },
    {
      id: "recurring",
      Header: "Recurring",
      accessor: "recurring",
    },
    {
      id: "assigned",
      Header: "Assigned",
      accessor: "assigned",
      Cell: ({ cell }) => (
        <Button className={classes.button}>{cell.row.values.assigned}</Button>
      ),
    },
    {
      Header: "Time Record",
      accessor: "timeEntries",
      Cell: ({ cell }) => {
        return <TasklistRecorder task={cell.row.original} readOnly="true" />;
      },
    },
  ];

  return (
    <Paper className={classes.paper} elevation={6}>
      <StandardTable
        data={tasks}
        columns={columns}
        title={"All tasks for - " + client.name}
        canEdit={false}
        canAdd={false}
        canDelete={false}
      />
      <Grid container>
        <Button fullWidth variant="contained" onClick={onCancel}>
          Close
        </Button>
      </Grid>
    </Paper>
  );
}

export default ClientTasks;
