import { getUsers } from "./userService";
import { createTask } from "./tasksService";

//PLEASE READ:
//A support Ticket is in reality just a Task that's added to the 'Uhlu Admin' company.
//The UID for this company is 'BikDggtRL48tL4WSgW5Q'

export const addIssue = async (issue, companyName, userEmail) => {
  
  //Get all Users for Uhlu so we can assign to someone
  let users = await getUsers("BikDggtRL48tL4WSgW5Q");

  let task = {
    company: "Uhlu Admin",
    companyRef: "BikDggtRL48tL4WSgW5Q",
    due: new Date(),
    name: issue.subject,
    comments: issue.description,
    status: "notstarted",
    category: "Ad-hoc",
    //clientRef: client,
    client: companyName + " (" + userEmail + ")",
    assignedRef: users[0].uid,
    assigned: users[0].displayName,
  };
    
  return createTask(task);
};
