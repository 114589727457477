import { IconButton } from "@mui/material";
import PropTypes from "prop-types";
import React, { useState } from "react";

import styled from "@emotion/styled";
import md5 from "md5";
import { storage } from "../services/firestore";
import LoadingIndicator from "./LoadingIndicator";

export default function ImageUpload(props) {
  const { changeHandler, label, disabled } = props;
  const [isLoading, setIsloading] = useState(false);

  let handleOnSave = (files) => {
    let image = files[0];

    setIsloading(true);
    storage
      .ref(`/profilePictures/${md5(image.name)}`)
      .put(image)
      .then((snapshot) => {
        if (snapshot.state === "success") {
          snapshot.ref.getDownloadURL().then((url) => {
            setIsloading(false);
            changeHandler(url);
          });
        }
      })
      .catch((e) => alert(e));
  };

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <div>
      <IconButton
        style={{ cursor: "pointer" }}
        disabled={disabled}
        variant="contained"
        component="label"
        size="large"
      >
        {label}
        <VisuallyHiddenInput
          type="file"
          onChange={(e) => handleOnSave(e.target.files)}
        />
      </IconButton>
    </div>
  );
}

ImageUpload.propTypes = {
  label: PropTypes.string.isRequired,
};

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
