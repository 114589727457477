import React from 'react'
import ContentHeader from "../../components/ContentHeader";
import ProductsView from '../../components/Products/ProductsView';

export default function Packages() {


    return (
        <div>
            <ContentHeader title="Products" />
            <ProductsView/>
        </div>
    );
}