import { db } from "./firestore";
import firebase from 'firebase/compat/app';
import moment from "moment";
import { getClients } from "./clientsService";
import { toHashMap } from "../helpers/helpers";
import { getFromCache } from "../cache/cache";
import {getFinancialYear} from "../helpers/helpers"

export const getAnnualTaskStatus = async (companyId, year) => {

  let financialYear = getFinancialYear(year);

  const companyDocRef = firebase
    .firestore()
    .collection("companies")
    .doc(companyId);

  let clients = await getClients(companyId);
  let clientHash = toHashMap(clients, "uid");

  let cacheKey = "annualTaskStatusReport_" + companyId + "_" + year;

  const query = db
    .collection("tasks")
    .where("companyRef", "==", companyDocRef)
    .where("due", ">=", financialYear.from)
    .where("due", "<=", financialYear.to)
    .where("recurring", "==", "yearly")
    .orderBy("due");

  return getFromCache(cacheKey, query, (querySnapshot) => {
    return querySnapshot.docs.map((taskRef) => {
      let dueDate = moment(taskRef.data().due.toDate());
      let task = taskRef.data();

      let client = clientHash[task.clientRef.id];

      return {
        ...task,
        uid: taskRef.id,
        client: client.name,
        dueDate: dueDate.format("MMM Do, YYYY"),
      };
    });
  });
};
