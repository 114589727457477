import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ChatIcon from "@mui/icons-material/Chat";
import MenuIcon from "@mui/icons-material/Menu";
import { Badge, Button, Menu, MenuItem } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import Hidden from "@mui/material/Hidden";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Popper from "@mui/material/Popper";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { Routes, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { withStyles } from "tss-react/mui";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { signOut } from "../services/authService";
import {
  getChatsChanged,
  getLastRead,
  getMessageCountSince,
} from "../services/chatService";
import { getCompanies } from "../services/companyService";
import { auth } from "../services/firestore";
import { getUserByEmail, updateUser } from "../services/userService";
import { claimsState } from "../state/claimsState";
import ChatBox from "./ChatBox";

const styles = (theme) => ({
  menuButton: {
    marginLeft: -theme.spacing(1),
  },
  iconButtonAvatar: {
    padding: 4,
  },
  avatar: {
    width: "40px",
    height: "40px",
  },
});

function Header(props) {
  const { classes, onDrawerToggle } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [claims, setClaims] = useRecoilState(claimsState);
  const [companyMenuOpen, setCompanyMenuOpen] = React.useState(false);
  const [profileMenuOpen, setProfileMenuOpen] = React.useState(false);
  const [supportMenuOpen, setSupportMenuOpen] = React.useState(false);
  const [unreadMessageCount, setUnreadMessageCount] = React.useState(0);
  const [companies, setCompanies] = React.useState([]);
  const uid = auth.currentUser.uid;
  const [lastReadDate, setLastReadDate] = React.useState(null);
  const [mode, setMode] = useLocalStorage("mode", "light");
  const history = useNavigate();

  useEffect(() => {
    if (auth.currentUser) {
      getCompanies(uid).then((companies) => {
        setCompanies(companies);
      });

      getLastRead(claims.companyId, (lastReadDate) => {
        setLastReadDate(lastReadDate);
        // getMessageCountSince(claims.companyId, lastReadDate)
        // .then((messageCount) => {
        //   debugger;
        //   setUnreadMessageCount(messageCount);
        // })
      });

      setClaims({
        ...claims,
        mode: mode,
      });
    }
  }, [uid]);

  useEffect(() => {
    if (lastReadDate != null) {
      let unSubscribe = getChatsChanged(claims.companyId, () => {
        getMessageCountSince(claims.companyId, lastReadDate).then(
          (messageCount) => {
            setUnreadMessageCount(messageCount);
          }
        );
      });

      return unSubscribe;
    }
  }, [lastReadDate]);

  if (!auth.currentUser) {
    return <></>;
  }

  function logOut() {
    signOut();
  }

  const handleProfileMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
    setProfileMenuOpen(true);
  };

  const handleSupportMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
    setSupportMenuOpen(true);
  };

  const handleCompanyMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
    setCompanyMenuOpen(true);
  };

  const handleModeChange = (e) => {
    setClaims({
      ...claims,
      mode: e.target.checked ? "dark" : "light",
    });

    setMode(e.target.checked ? "dark" : "light");
  };

  const handleClose = (company) => {
    if (company.id) {
      getUserByEmail(auth.currentUser.email).then((user) => {
        let updatedUser = { ...user, company: company };
        updateUser(updatedUser);

        setClaims({
          ...claims,
          companyId: company.id,
          companyName: company.name,
        });
      });
    }
    setAnchorEl(null);
    setProfileMenuOpen(false);
    setCompanyMenuOpen(false);
  };

  const handleChatClick = (event) => {
    setAnchorEl2(anchorEl2 ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl2);
  const id = open ? "simple-popper" : undefined;

  return (
    <React.Fragment>
      <AppBar color="secondary" position="sticky" elevation={0}>
        <Toolbar>
          <Grid container spacing={1} alignItems="center">
            <Hidden smUp>
              <Grid item>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={onDrawerToggle}
                  className={classes.menuButton}
                  size="large"
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Hidden>
            <Grid item xs>
              <Button
                onClick={handleCompanyMenuClick}
                aria-controls="company-menu"
                aria-haspopup="true"
                color="inherit"
                className={classes.menuButton}
                endIcon={<ArrowDropDownIcon />}
              >
                {claims.companyName}
              </Button>
              <Menu
                id="company-menu"
                anchorEl={anchorEl}
                keepMounted
                open={companyMenuOpen}
                onClose={handleClose}
              >
                {companies.map((c) => {
                  return (
                    <MenuItem key={c.id} onClick={() => handleClose(c)}>
                      {c.name}
                    </MenuItem>
                  );
                })}
              </Menu>
            </Grid>

            <Grid item>
              <Link
                onClick={handleSupportMenuClick}
                color="inherit"
                className={classes.link}
                href="#"
                variant="body2"
              >
                Support
              </Link>
              <Menu
                id="profile-menu"
                anchorEl={anchorEl}
                keepMounted
                open={supportMenuOpen}
                onClose={() => setSupportMenuOpen(false)}
              >
                {/* <MenuItem variant="outlined" onClick={() => {history.push('/issuelogger'); setSupportMenuOpen(false)}}>
                  View docs
                </MenuItem> */}
                <MenuItem
                  variant="outlined"
                  onClick={() => {
                    history.push("/issuelogger");
                    setSupportMenuOpen(false);
                  }}
                >
                  Contact us
                </MenuItem>
              </Menu>
            </Grid>
            <Grid item>
              <Tooltip title="Chat">
                <IconButton
                  color="inherit"
                  onClick={handleChatClick}
                  size="large"
                >
                  <Badge color="error" badgeContent={unreadMessageCount}>
                    <ChatIcon />
                  </Badge>
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item>
              <IconButton
                color="inherit"
                onClick={handleProfileMenuClick}
                aria-controls="profile-menu"
                aria-haspopup="true"
                className={classes.iconButtonAvatar}
                size="large"
              >
                <Avatar
                  className={classes.avatar}
                  src={claims.user.profileImageUrl}
                  alt="My Avatar"
                  title={auth.currentUser.email}
                />
              </IconButton>
              <Menu
                id="profile-menu"
                anchorEl={anchorEl}
                keepMounted
                open={profileMenuOpen}
                onClose={handleClose}
              >
                <MenuItem onClick={handleClose}>Profile</MenuItem>
                {/* <MenuItem>
                  Mode
                  <Grid item>
                    <Routes
                      checked={claims.mode === "dark"}
                      onChange={handleModeChange}
                      name="checkedB"
                      color="primary"
                    />
                  </Grid>
                </MenuItem> */}
                <MenuItem variant="outlined" onClick={logOut}>
                  Logout
                </MenuItem>
              </Menu>
            </Grid>
          </Grid>
          <Popper id={id} open={open} anchorEl={anchorEl2} placement="bottom">
            <ChatBox onClose={handleChatClick} />
          </Popper>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  onDrawerToggle: PropTypes.func.isRequired,
};

export default withStyles(Header, styles);
