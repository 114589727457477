import CloseIcon from "@mui/icons-material/Close";
import {
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  TextField,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import { makeStyles } from "tss-react/mui";
import { addMessage, getChatsSnapshot } from "../services/chatService";
import { claimsState } from "../state/claimsState";
import LoadingIndicator from "./LoadingIndicator";

const useStyles = makeStyles()((theme) => ({
  root: {
    "& .MuiButton-root": {
      margin: theme.spacing(1),
      height: 27,
      marginLeft: 15,
    },
    "& .MuiInputBase-root": {
      margin: theme.spacing(1),
      height: 27,
    },
    "& .MuiBox-root": {
      padding: "0px",
    },
  },
  typography: {
    margin: "5px",
  },
  paper: {
    padding: "5px",
    marginTop: "20px",
  },
  bubbleContainer: {
    width: "100%",
    display: "flex",
  },
  bubble: {
    borderRadius: "20px",
    margin: "5px",
    padding: "5px 10px",
  },
  chats: {
    height: 300,
    width: 500,
    overflow: "auto",
    //marginTop: '-35px'
  },
  loader: {
    height: 300,
    width: 500,
  },
  right: {
    justifyContent: "flex-end",
  },
  rightBubble: {
    backgroundColor: "lightblue",
    border: "0.5px solid lightblue",
  },
  leftBubble: {
    border: "0.5px solid lightgrey",
    backgroundColor: "lightgrey",
  },
  subText: {
    fontSize: "x-small",
    fontWeight: 100,
    color: "grey",
  },
  avatar: {
    margin: "5px",
    width: "40px",
    height: "40px",
  },
  closeButton: {
    marginTop: "-12px",
    marginRight: "-12px",
  },
}));

export default function ChatBox(props) {
  const { onClose } = props;
  const [claims] = useRecoilState(claimsState);
  const {classes} = useStyles();
  const [isLoading, setIsloading] = useState(false);
  const [busy, setBusy] = useState(false);
  const [chats, setChats] = useState([]);
  const [message, setMessage] = useState("");

  const AlwaysScrollToBottom = () => {
    const elementRef = useRef();
    useEffect(() => {
      elementRef.current.parentNode.scrollTop = elementRef.current.offsetTop;
    });
    return <div ref={elementRef} />;
  };

  useEffect(() => {
    setIsloading(true);

    console.log("Subscribing to Chats");
    let unSubscribe = getChatsSnapshot(
      claims.companyId,
      (chats) => {
        setChats(chats);
        setIsloading(false);
      },
      [claims.companyId]
    );

    return unSubscribe;
  }, [claims.companyId]);

  function handleAddMessage() {
    setBusy(true);
    addMessage(claims.companyId, message).then(() => {
      setMessage("");
      setBusy(false);
    });
  }

  function canSendMessage() {
    return !busy && message.length > 0;
  }

  function getUserInitials(user) {
    if (user === null || user === undefined) return "";

    var res = user.split(" ");

    let result = "";

    res.forEach((word) => {
      result = result + word[0];
    });

    return result;
  }

  if (isLoading) {
    return (
      <Paper className={`${classes.paper} ${classes.loader}`} elevation={6}>
        <LoadingIndicator />
      </Paper>
    );
  }

  return (
    <Paper className={`${classes.paper} ${classes.root}`} elevation={6}>
      <Grid container justifyContent="flex-end">
        <Grid item>
          <IconButton
            className={classes.closeButton}
            color="primary"
            onClick={onClose}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
      <div id="chatsContainer" className={classes.chats}>
        {chats.map((chat, i = 0) => {
          return (
            <div
              className={`${classes.bubbleContainer} ${
                chat.position === "left" ? classes.left : classes.right
              }`}
              key={i}
            >
              {chat.userImageUrl !== undefined && chat.userImageUrl !== "" ? (
                <Avatar
                  className={classes.avatar}
                  src={chat.userImageUrl}
                  alt="User Avatar"
                  title={chat.user}
                />
              ) : (
                <Avatar
                  className={classes.avatar}
                  alt="User Avatar"
                  title={chat.user}
                >
                  {getUserInitials(chat.user)}
                </Avatar>
              )}

              <Paper
                elevation={3}
                key={i++}
                className={`${classes.bubble} ${
                  chat.position === "left"
                    ? classes.leftBubble
                    : classes.rightBubble
                }`}
              >
                <Box display="flex" justifyContent="flex-start">
                  <Box p={1}>{chat.content}</Box>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Box className={classes.subText} p={1}>
                    {chat.createdAt}
                  </Box>
                </Box>
              </Paper>
            </div>
          );
        })}
        <AlwaysScrollToBottom />
      </div>
      <br />
      <hr />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          handleAddMessage();
        }}
        noValidate
        className={classes.root}
        autoComplete="off"
      >
        <Box display="flex">
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            onChange={(e) => setMessage(e.target.value)}
            value={message}
          ></TextField>
          <Button
            disabled={!canSendMessage()}
            onClick={() => handleAddMessage()}
            variant="contained"
            color="primary"
            size="small"
          >
            Send
          </Button>
        </Box>
      </form>
    </Paper>
  );
}
