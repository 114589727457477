import {
  Autocomplete,
  Button,
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Select,
  TextField,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { makeStyles } from "tss-react/mui";
import { getClients } from "../../services/clientsService";
import { auth } from "../../services/firestore";
import { getProducts } from "../../services/productsService";
import { claimsState } from "../../state/claimsState";
import AssignedDropdown from "../AssignedDropdown";

const useStyles = makeStyles()((theme) => ({
  root: {
    "& .MuiButton-root": {
      margin: theme.spacing(1),
      width: 200,
    },
    "& .MuiFormControl-root": {
      margin: theme.spacing(1),
      width: 200,
    },
    flexGrow: 1,
  },
  formControl: {
    minWidth: 120,
  },
  paper: {
    padding: "5px",
  },
  rightDiv: {
    alignItems: "flex-start",
    justifyContent: "flex-end",
  },
}));

function CreateNewTask(props) {
  const { createTask, cancelClick } = props;
  const {classes} = useStyles();
  const [name, setName] = useState("");
  const [client, setClient] = useState("");
  const [dueDate, setDueDate] = useState(moment().format("yyyy-MM-DD"));
  const [assignedName, setAssignedName] = useState("");
  const [assignedRef, setAssignedRef] = useState(auth.currentUser.uid || "");

  const [clients, setClients] = useState([]);
  const [products, setProducts] = useState([]);
  const [claims] = useRecoilState(claimsState);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getClients(claims.companyId).then((clients) => {
      setClients(clients);
    });

    getProducts(claims.companyId).then((products) => {
      setProducts(products);
    });
  }, [claims.companyId, setClients]);

  function handleClick() {
    let task = {
      company: claims.companyName,
      companyRef: claims.companyId,
      due: new Date(dueDate),
      name: name,
      status: "notstarted",
      category: "Ad-hoc",
      clientRef: client,
      client: clients.find((c) => c.uid === client).name,
      assignedRef: assignedRef,
      assigned: assignedName,
    };
    setLoading(true);
    createTask(task);
  }

  function handleAssignedChange(value, name) {
    setAssignedName(name);
    setAssignedRef(value);
  }

  function canCreateTask() {
    return (
      name !== "" &&
      name !== null &&
      client !== "" &&
      assignedRef !== "" &&
      !loading
    );
  }

  return (
    <Paper className={classes.paper} elevation={0}>
      <Grid container className={classes.root}>
        <Grid item xs>
          <Autocomplete
            id="taskTitle"
            freeSolo
            size="small"
            onChange={(event, newValue) => {
              setName(newValue);
            }}
            //value={name}
            options={products.map((product) => product.name)}
            renderInput={(params) => (
              <TextField
                {...params}
                onChange={(e) => setName(e.target.value)}
                label="Task Name"
                margin="normal"
                variant="outlined"
                required
                autoFocus
                size="small"
              />
            )}
          />
        </Grid>
        <Grid item xs>
          <FormControl
            size="small"
            variant="outlined"
            className={classes.formControl}
          >
            <InputLabel id="demo-simple-select-outlined-label">
              Client
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={client}
              onChange={(e) => {
                setClient(e.target.value);
              }}
              label="Client"
            >
              {clients.map((client) => {
                return (
                  <MenuItem key={client.uid} value={client.uid}>
                    {client.name} ({client.type})
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs>
          <TextField
            id="date"
            size="small"
            variant="outlined"
            label="Due Date"
            type="date"
            value={dueDate}
            onChange={(e) => {
              setDueDate(e.target.value);
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs>
          <AssignedDropdown
            className={classes.formControl}
            assignedRef={assignedRef}
            onChange={handleAssignedChange}
          />
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        className={classes.root}
      >
        <Grid item justifyContent="flex-end" xs>
          <Button onClick={cancelClick} fullWidth variant="contained">
            Cancel
          </Button>
        </Grid>
        <Grid item xs>
          <Button
            onClick={() => handleClick()}
            color="primary"
            fullWidth
            disabled={!canCreateTask()}
            variant="contained"
          >
            Create
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default CreateNewTask;
