import React from "react";
import { useRecoilState } from "recoil";
import { claimsState } from "./state/claimsState";
import { Box } from "@mui/material";

const SuperuserRoute = ({Component}) => {
  const [claims] = useRecoilState(claimsState);

  const isAuthed = claims.isInRole("superuser");

  return isAuthed ? (
    <Component />
  ) : (
    <Box>
      <h1>You don't have permission to view this!</h1>
    </Box>
  );
}

export default SuperuserRoute;

