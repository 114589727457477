import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";

export default function TabPanel(props) {
  const { children, value, index, lazyload, ...other } = props;

  if (lazyload) {
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`settings-tabpanel-${index}`}
        aria-labelledby={`settings-tab-${index}`}
        {...other}
      >
        {value === index && (
        <Box p={3}>{children}</Box>
        )}
      </div>
    );
  } else {
    return (
      <div
        style={{ display: value === index ? "block" : "none" }}
        role="tabpanel"
        hidden={value !== index}
        id={`settings-tabpanel-${index}`}
        aria-labelledby={`settings-tab-${index}`}
        {...other}
      >
        <Box p={3}>{children}</Box>
      </div>
    );
  }
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
  lazyload: PropTypes.bool,
};

export function a11yProps(index) {
  return {
    id: `settings-tab-${index}`,
    "aria-controls": `settings-tabpanel-${index}`,
  };
}
