import { CssBaseline } from "@mui/material";
import {
  StyledEngineProvider,
  ThemeProvider,
  createTheme,
} from "@mui/material/styles";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { useRecoilState } from "recoil";
import OuterRouter from "./OuterRouter";
import LoadingIndicator from "./components/LoadingIndicator";
import { auth, db } from "./services/firestore";
import { getUserByEmail } from "./services/userService";
import { claimsState } from "./state/claimsState";

let themeDark = createTheme({
  palette: {
    primary: {
      light: "#63ccff",
      main: "#f2920a", //"#009be5",
      dark: "#f28d00",
      contrastText: "#F6F7F9",
    },
    secondary: {
      main: "#003756",
      contrastText: "#F6F7F9",
    },

    text: {
      primary: "#F6F7F9",
      secondary: "#F6F7F9",
    },
  },
  typography: {
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
    h4: {
      fontWeight: 500,
    },
  },
  shape: {
    borderRadius: 4,
  },
  props: {
    MuiTab: {
      disableRipple: true,
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});

themeDark = {
  ...themeDark,
  overrides: {
    MuiDrawer: {
      paper: {
        backgroundImage: "linear-gradient(180deg,#000000  10%,#003756 80%)",
      },
    },
    MuiButton: {
      color: "#F6F7F9",
      label: {
        textTransform: "none",
      },
      contained: {
        boxShadow: "none",
        "&:active": {
          boxShadow: "none",
        },
      },
    },
    MuiAppBar: {
      colorSecondary: {
        backgroundColor: "#000000",
      },
    },
    MuiTableCell: {
      root: {
        padding: "0px 0px 0px 5px",
      },
      head: {
        "font-weight": "bold",
      },
    },
    MUIDataTable: {
      responsiveScrollMaxHeight: {
        maxHeight: "none !important",
      },
    },
    MuiTabs: {
      root: {
        marginLeft: themeDark.spacing(1),
      },
      indicator: {
        height: 3,
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
      },
    },
    MuiCssBaseline: {
      "@global": {
        body: {
          backgroundImage: "linear-gradient(180deg,#000000 10%,#003756 80%)",
        },
      },
    },
    MuiTab: {
      root: {
        textTransform: "none",
        margin: "0 16px",
        minWidth: 0,
        padding: 0,
        [themeDark.breakpoints.up("md")]: {
          padding: 0,
          minWidth: 0,
        },
      },
    },
    MuiPaper: {
      root: {
        backgroundColor: "#003756",
      },
    },
    MuiIconButton: {
      root: {
        padding: themeDark.spacing(1),
        color: "#F6F7F9",
      },
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: 4,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: "#404854",
      },
    },
    MuiListSubheader: {
      sticky: {
        backgroundColor: "#000000",
      },
    },
    MuiListItemText: {
      primary: {
        fontWeight: themeDark.typography.fontWeightMedium,
      },
    },
    MuiListItemIcon: {
      root: {
        color: "inherit",
        marginRight: 0,
        "& svg": {
          fontSize: 20,
        },
      },
    },
    MuiAvatar: {
      root: {
        width: 32,
        height: 32,
      },
    },
  },
};

let theme = createTheme({
  palette: {
    primary: {
      light: "#63ccff",
      main: "#009be5",
      dark: "#006db3",
    },
    secondary: {
      main: "#F6F7F9",
      contrastText: "#496483",
    },
    background: {
      default: "#F6F7F9",
    },
  },

  typography: {
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
    h4: {
      fontWeight: 500,
    },
  },
  shape: {
    borderRadius: 4,
  },
  props: {
    MuiTab: {
      disableRipple: true,
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});

theme = {
  ...theme,
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundImage: "linear-gradient(180deg,#000000  10%,#003756 80%)",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          //color: "#F6F7F9",
          label: {
            textTransform: "none",
          },
          contained: {
            boxShadow: "none",
            "&:active": {
              boxShadow: "none",
            },
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: "0px 0px 0px 5px",
        },
        head: {
          "font-weight": "bold",
        },
      },
    },
    MUIDataTable: {
      styleOverrides: {
        root: {
          responsiveScrollMaxHeight: {
            maxHeight: "none !important",
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          marginLeft: theme.spacing(1),
        },
        indicator: {
          height: 3,
          borderTopLeftRadius: 3,
          borderTopRightRadius: 3,
        },
      },
    },

    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
          margin: "0 16px",
          minWidth: 0,
          padding: 0,
          [theme.breakpoints.up("md")]: {
            padding: 0,
            minWidth: 0,
          },
        },
      },
    },

    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: theme.spacing(1),
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        root: {
          tooltip: {
            borderRadius: 4,
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: "#404854",
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontWeight: theme.typography.fontWeightMedium,
          fontSize: "14px",
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: "inherit",
          marginRight: 0,
          "& svg": {
            fontSize: 20,
          },
        },
      },
    },
    MuiListSubheader: {
      styleOverrides: {
        root: {
          sticky: {
            backgroundColor: "#eeeeee",
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: 32,
          height: 32,
        },
      },
    },
  },
};

function App(props) {
  const [claims, setClaims] = useRecoilState(claimsState);
  const [loading, setLoading] = useState(true);

  const finalTheme = React.useMemo(() => {
    return claims && claims.mode === "dark" ? theme : theme;
  }, [claims]);

  moment.updateLocale("en", {
    relativeTime: {
      future: "in %s",
      past: "%s ago",
      s: "seconds",
      ss: "%d seconds",
      m: "a minute",
      mm: "%d mins",
      h: "an hour",
      hh: "%d hrs",
      d: "a day",
      dd: "%d days",
      w: "a week",
      ww: "%d weeks",
      M: "a month",
      MM: "%d months",
      y: "a year",
      yy: "%d years",
    },
  });

  useEffect(() => {
    auth.onAuthStateChanged(function (authUser) {
      if (authUser) {
        GetClaims(authUser);
      } else {
        setClaims(null);
        setLoading(false);
      }
    });

    function GetClaims(authUser) {
      db.collection("user-claims")
        .doc(authUser.uid)
        .onSnapshot((snapshot) => {
          const data = snapshot.data();
          if (data) {
            data.isInRole = function (roleName) {
              return this.roles.length > 0 && this.roles.includes(roleName);
            };

            getUserByEmail(authUser.email).then((user) => {
              // alert(user)
              data.user = {
                displayName: user.displayName,
                profileImageUrl: user.profileImageUrl,
              };
              setClaims(data);
              authUser.getIdToken(true);
              setLoading(false);
            });
          }
        });
    }
  }, [setClaims, setLoading]);

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={finalTheme}>
        <CssBaseline />
        <BrowserRouter>
          <OuterRouter />
        </BrowserRouter>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
