import {
  Button,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { makeStyles } from "tss-react/mui";
import { valueOrDefault } from "../../helpers/helpers";
import { getCompanyTypes } from "../../services/lookupService";
import AssignedDropdown from "../AssignedDropdown";
import Dropdown from "../Dropdown";
import ProductsCheckList from "../Packages/ProductsCheckList";
import PackagesDropdown from "../PackagesDropdown";

const useStyles = makeStyles()((theme) => ({
  root: {
    "& .MuiButton-root": {
      margin: theme.spacing(1),
      width: 200,
    },
    "& .MuiFormControl-root": {
      margin: theme.spacing(1),
      width: 200,
    },
  },
  typography: {
    margin: "5px",
  },
  paper: {
    padding: "5px",
  },
  monthDropdown: {
    width: 80,
    margin: theme.spacing(1),
  },
  dropDown: {
    width: 200,
    margin: theme.spacing(1),
  },
}));

function ClientCreate(props) {
  const { onSave, onCancel, defaultType } = props;
  const {classes} = useStyles();

  const [name, setName] = React.useState("");
  const [registrationNumber, setRegistrationNumber] = React.useState("");
  const [incomeTaxNumber, setIncomeTaxNumber] = React.useState("");
  const [type, setType] = React.useState(defaultType || "Company");
  const [contactPerson, setContactPerson] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [cellNr, setCellNr] = React.useState("");

  const [assigned, setAssigned] = React.useState(undefined);
  const [$package, setPackage] = React.useState(undefined);
  const [products, setProducts] = React.useState([]);
  const [packagePrice, setPackagePrice] = React.useState(0);
  const [recommendedPrice, setRecommendedPrice] =
    React.useState(" (calculating...)");

  const companyTypes = getCompanyTypes();

  function handleClick() {
    onSave({
      name: valueOrDefault(name, ""),
      assigned: assigned,
      registrationNumber: valueOrDefault(registrationNumber, ""),
      incomeTaxNumber: valueOrDefault(incomeTaxNumber, ""),
      type: valueOrDefault(type, ""),
      contactPerson: valueOrDefault(contactPerson, ""),
      email: valueOrDefault(email, ""),
      cellNr: valueOrDefault(cellNr, ""),
      package: $package,
      products: products,
      packagePrice: packagePrice,
    });
  }

  let canCreateClient = () => {
    return name.length >= 3 && $package !== undefined && assigned !== undefined;
  };

  let showProducts = () => {
    return $package !== undefined;
  };

  let handleProductUpdate = (products) => {
    if ($package !== undefined) {
      setRecommendedPrice(
        $package.price +
          products.reduce((sum, b) => {
            return b.isInPackage ? sum : sum + b.price;
          }, 0)
      );
    }
    setProducts(products);
  };

  let handlePackageChange = ($package) => {
    setPackage($package);
    setProducts($package.products);
    setPackagePrice($package.price);
    setRecommendedPrice($package.price);
  };

  let productsList = showProducts() ? (
    <div>
      <Typography variant="h6">Products</Typography>
      <ProductsCheckList
        packageId={$package !== undefined ? $package.uid : undefined}
        selectedProducts={products}
        onChange={handleProductUpdate}
        showDueDate={true}
        showPrices={true}
      />
    </div>
  ) : (
    <></>
  );

  return (
    <Paper className={classes.paper} elevation={6}>
      <form className={classes.root} noValidate autoComplete="off">
        <Typography className={classes.typography} component="h1" variant="h6">
          Create Client
        </Typography>
        <div>
          <TextField
            id="outlined-basic"
            error={name.length < 3}
            required
            size="small"
            onChange={(e) => setName(e.target.value)}
            label="Name"
            variant="outlined"
            focused
          />
          <TextField
            id="outlined-basic"
            value={registrationNumber}
            size="small"
            onChange={(e) => setRegistrationNumber(e.target.value)}
            label="Registration Number"
            variant="outlined"
          />
          <TextField
            id="outlined-basic"
            value={incomeTaxNumber}
            size="small"
            onChange={(e) => setIncomeTaxNumber(e.target.value)}
            label="Income Tax Number"
            variant="outlined"
          />
        </div>
        <div>
          <TextField
            id="outlined-basic"
            value={contactPerson}
            size="small"
            onChange={(e) => setContactPerson(e.target.value)}
            label="Contact Person"
            variant="outlined"
          />
          <TextField
            id="outlined-basic"
            value={cellNr}
            size="small"
            onChange={(e) => setCellNr(e.target.value)}
            label="Cell nr"
            variant="outlined"
          />
          <TextField
            id="outlined-basic"
            value={email}
            type="email"
            size="small"
            onChange={(e) => setEmail(e.target.value)}
            label="Email"
            variant="outlined"
            autoComplete="email"
          />
        </div>
        <div>
          <PackagesDropdown
            required
            className={classes.dropDown}
            onChange={(value) => {
              handlePackageChange(value);
            }}
          />
          <AssignedDropdown
            className={classes.dropDown}
            onChange={(value) => setAssigned(value)}
          />
          <Dropdown
            className={classes.dropDown}
            label="Company Type"
            name="Type"
            value={type}
            items={companyTypes}
            onChange={(value) => setType(value)}
          />
        </div>
        <div>
          <TextField
            id="outlined-basic"
            value={packagePrice}
            type="number"
            InputProps={{
              inputProps: { min: 0, step: 100 },
              startAdornment: (
                <InputAdornment position="start">R</InputAdornment>
              ),
            }}
            size="small"
            onChange={(e) => setPackagePrice(e.target.value)}
            label="Package Price"
            variant="outlined"
          />
          {showProducts() ? (
            <Typography
              style={{
                color: "grey",
                fontSize: "6",
                fontWeight: 300,
                display: "inline-grid",
                paddingTop: "15px",
              }}
            >
              Recommended Price: R{recommendedPrice}
            </Typography>
          ) : (
            <></>
          )}
        </div>
        {productsList}
        <div>
          <Button fullWidth variant="contained" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            fullWidth
            disabled={!canCreateClient()}
            variant="contained"
            color="primary"
            onClick={() => handleClick()}
          >
            Save
          </Button>
        </div>
      </form>
    </Paper>
  );
}

export default ClientCreate;
