import {
  Button,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { makeStyles } from "tss-react/mui";
import ProductsCheckList from "./ProductsCheckList";

const useStyles = makeStyles()((theme) => ({
  root: {
    "& .MuiButton-root": {
      margin: theme.spacing(1),
      width: 200,
    },
    "& .MuiFormControl-root": {
      margin: theme.spacing(1),
      width: 200,
    },
  },
  typography: {
    margin: "5px",
  },
  paper: {
    padding: "5px",
  },
}));

export default function PackageEditor(props) {
  const { onSave, onCancel, title, $package } = props;
  const {classes} = useStyles();

  const [name, setName] = React.useState(
    $package !== undefined ? $package.name : ""
  );
  const [price, setPrice] = React.useState(
    $package !== undefined ? $package.price : 0
  );
  const [products, setProducts] = React.useState(
    $package !== undefined ? $package.products : []
  );

  function handleClick() {
    onSave({
      uid: $package !== undefined ? $package.uid : undefined,
      name: name,
      price: price,
      products: products,
    });
  }

  let canCreatePackage = () => {
    return name.length >= 3 && price >= 0;
  };

  let handleProductsChanged = (products) => {
    setProducts(products);
  };

  return (
    <Paper className={classes.paper} elevation={6}>
      <form className={classes.root} noValidate autoComplete="off">
        <Typography className={classes.typography} component="h1" variant="h6">
          {title}
        </Typography>
        <div>
          <TextField
            id="package-name"
            error={name.length < 3}
            value={name}
            required
            size="small"
            onChange={(e) => setName(e.target.value)}
            label="Name"
            variant="outlined"
            focused
          />
          <TextField
            id="package-price"
            type="number"
            value={price}
            InputProps={{
              inputProps: { min: 0, step: 100 },
              startAdornment: (
                <InputAdornment position="start">R</InputAdornment>
              ),
            }}
            required
            size="small"
            onChange={(e) => setPrice(e.target.value)}
            label="Price"
            variant="outlined"
          />
        </div>
        <div>
          <Typography
            className={classes.typography}
            component="h3"
            variant="h6"
          >
            Products
          </Typography>
          <ProductsCheckList
            selectedProducts={products}
            onChange={handleProductsChanged}
          />
        </div>
        <div>
          <Button fullWidth variant="contained" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            fullWidth
            disabled={!canCreatePackage()}
            variant="contained"
            color="primary"
            onClick={() => handleClick()}
          >
            Save
          </Button>
        </div>
      </form>
    </Paper>
  );
}
