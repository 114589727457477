import { db } from "./firestore";
import firebase from 'firebase/compat/app';
import moment from "moment";
import { getFromCache } from "../cache/cache";
import {getClients} from './clientsService';
import { toHashMap } from "../helpers/helpers";

export const getTimeEntries = async (companyId, fromDate, toDate, filter) => {
  const companyDocRef = firebase
    .firestore()
    .collection("companies")
    .doc(companyId);

  let query = db
    .collection("timeEntries")
    .where("companyRef", "==", companyDocRef)
    .where("endTime", ">=", fromDate)
    .where("endTime", "<=", toDate)
    .orderBy("endTime");

  filter.forEach((element) => {
    query = query.where(element.field, "==", element.value);
  });

  let cacheKey =
    "timeEntries_" + companyId + fromDate + toDate + JSON.stringify(filter);

  let clients = await getClients(companyId);
  let clientHash = toHashMap(clients, 'uid');

  return getFromCache(cacheKey, query, (snapshot) => {
    let entries = snapshot.docs
      .map((taskRef) => {
        return taskRef.data();
      })
      .sort(compareTimeEntries);

    let groupedByTaskId = groupBy(entries, (entry) => {
      return entry.taskId;
    });

    return groupedByTaskId
      .map((taskGroup) => {
        let groupedByRecorded = groupBy(taskGroup.values, (entry) => {
          return entry.recordedBy.id;
        });

        return groupedByRecorded.map((recordedByGroup) => {
          let task = recordedByGroup.values[0];
          let days = {};
          let total = 0;

          recordedByGroup.values.forEach(function (item) {
            let elapsedTime = item.endTime - item.startTime;

            let dayOfWeek = moment(item.endTime.toDate()).format("dddd");

            if (days[dayOfWeek] === undefined) {
              days[dayOfWeek] = elapsedTime;
            } else {
              days[dayOfWeek] = days[dayOfWeek] + elapsedTime;
            }

            total = total + elapsedTime;
          });

          if (total === 0) {
            return undefined;
          }

          let client = clientHash[task.clientRef.id];

          return {
            uid: task.taskId,
            client: client !== undefined ? client.name : task.clientName,
            clientRef: task.clientRef,
            name: task.name,
            category: task.category,
            recordedByUid: task.recordedBy,
            recordedBy: task.recordedByName,
            total: total,
            ...days,
          };
        });
      })
      .flat()
      .filter((item) => item !== undefined);
  }).catch((err) => {
    console.error("Error: " + err);
    throw err;
  });
};

function compareTimeEntries(a, b) {
  if (a.clientName > b.clientName) return 1;
  if (b.clientName > a.clientName) return -1;

  if (a.category > b.category) return 1;
  if (b.category > a.category) return -1;

  return 0;
}

var groupBy = function (xs, key) {
  return xs.reduce(function (rv, x) {
    let v = key instanceof Function ? key(x) : x[key];
    let el = rv.find((r) => r && r.key === v);
    if (el) {
      el.values.push(x);
    } else {
      rv.push({ key: v, values: [x] });
    }

    return rv;
  }, []);
};
