import { Alert, Button, Paper } from "@mui/material";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "tss-react/mui";
import { Logo } from "../../images/Images";
import { resetPassword } from "../../services/authService";

const useStyles = makeStyles()((theme) => ({
  paper: {
    width: 400,
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing(2)} ${theme.spacing(3)} ${theme.spacing(3)}`,
  },
  avatar: {
    margin: theme.spacing(1),
    width: 192,
    height: 192,
    color: theme.palette.secondary.main,
  },
  form: {
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: `100%`,
  },
  grid: {
    fontSize: "10px",
  },
  gridItem: {
    textAlign: "right",
  },
  logo: {
    width: "250px",
    paddingBottom: "0px",
  },
  background: {
    position: "fixed",
    width: "100%",
    height: "100%",
    top: 0,
    backgroundImage: "linear-gradient(45deg,#003756 30%,#000000 80%)",
    zIndex: -200,
  },
}));

const ForgotPassword = () => {
  const {classes} = useStyles();
  const history = useNavigate();
  const [username, setUsername] = useState("");
  const [emailSent, setEmailSent] = useState(false);

  //const referer = props.location.state.referer || '/';

  function handleSubmit(event) {
    event.preventDefault();

    if (emailSent) {
      history.push("signin");
    } else {
      resetPassword(username);
      setEmailSent(true);
    }
  }

  return (
    <Paper className={classes.paper} elevation={6}>
      <div className={classes.background}></div>
      <Logo className={classes.logo} />
      <div className={classes.container}>
        <Typography component="h1" variant="h5">
          Forgot your Password?
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit} noValidate>
          {!emailSent ? (
            <React.Fragment>
              <TextField
                value={username}
                onInput={(e) => setUsername(e.target.value)}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="username"
                label="Username"
                name="username"
                autoComplete="username"
                autoFocus
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Reset
              </Button>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Alert severity="success">
                Check your email for a reset link
              </Alert>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Continue
              </Button>
            </React.Fragment>
          )}
        </form>
      </div>
    </Paper>
  );
};

export default ForgotPassword;
