import { Hidden, Typography } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import React from "react";
import { useRecoilState } from "recoil";
import { withStyles } from "tss-react/mui";
import Header from "././components/Header";
import Navigator from "././components/Navigator";
import InnerRouter from "./InnerRouter";
import LoadingIndicator from "./components/LoadingIndicator";
import { claimsState } from "./state/claimsState";

const drawerWidth = 170;

const theme = createTheme();

const styles = {
  root: {
    display: "flex",
    minHeight: "100vh",
    width: "100%",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  app: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  main: {
    flex: 1,
  },
  footer: {
    padding: theme.spacing(2),
  },
};

const Dashboard = (props) => {
  const { classes } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [claims] = useRecoilState(claimsState);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  if (!claims || !claims.companyId) {
    return <LoadingIndicator />;
  }

  function Copyright() {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        {"Copyright © Uhlu "}
        {new Date().getFullYear()}
      </Typography>
    );
  }

  function Version() {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        {"Version - " + process.env.REACT_APP_VERSION}
      </Typography>
    );
  }

  return (
    <div className={classes.root}>
      <nav className={classes.drawer}>
        <Hidden smUp implementation="js">
          <Navigator
            PaperProps={{ style: { width: drawerWidth } }}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
          />
        </Hidden>
        <Hidden smDown implementation="css">
          <Navigator PaperProps={{ style: { width: drawerWidth } }} />
        </Hidden>
      </nav>
      <div className={classes.app}>
        <Header onDrawerToggle={handleDrawerToggle} />
        <main className={classes.main}>
          <InnerRouter />
        </main>
        <footer className={classes.footer}>
          <Copyright /> <Version />
        </footer>
      </div>
    </div>
  );
};

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(Dashboard, styles);
