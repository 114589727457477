import { Chip } from "@mui/material";
import React from "react";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme, props) => ({
  chip: {
    backgroundColor:
      props.label === "notstarted"
        ? "#f44336"
        : props.label === "inprogress"
        ? "#ff9800"
        : "#4caf50",
    color: "white",
    borderRadius: "5px",
  },
  compact: {
    fontSize: "12px",
    margin: "2px 0px 2px 0px",
  },
}));

function TaskStatusChip(props) {
  const { label, onClick, compact } = props;
  const { classes } = useStyles(props);

  function transformLabel(label) {
    switch (label) {
      case "notstarted":
        return "Not Started";
      case "inprogress":
        return "In Progress";
      case "completed":
        return "Completed";
      default:
        return label;
    }
  }
  const compactClass = compact === true ? classes.compact : "";
  const ff = classes.chip + " " + compactClass;
  return (
    <Chip
      className={ff}
      compact={compact}
      label={transformLabel(label)}
      title={transformLabel(label)}
      size="small"
      onClick={onClick}
    />
  );
}

export default TaskStatusChip;
