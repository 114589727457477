import { Grid } from "@mui/material";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { makeStyles } from "tss-react/mui";
import ErrorIndicator from "../../components/ErrorIndicator";
import LoadingIndicator from "../../components/LoadingIndicator";
import SuccessIndicator from "../../components/SuccessIndicator";
import { Logo } from "../../images/Images";
import { signUp } from "../../services/authService";
import {
  addCompany,
  addUserToCompanyById,
} from "../../services/companyService";
import { auth } from "../../services/firestore";
import { deleteInvite, getInvite } from "../../services/invitesService";

const useStyles = makeStyles()((theme) => ({
  paper: {
    width: 400,
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing(2)} ${theme.spacing(3)} ${theme.spacing(3)}`,
  },
  avatar: {
    margin: theme.spacing(1),
    width: 192,
    height: 192,
    color: theme.palette.secondary.main,
  },
  form: {
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0),
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: `100%`,
  },
  grid: {
    fontSize: "10px",
  },
  logo: {
    width: "250px",
    paddingTop: 40,
    paddingBottom: "20px",
  },
  background: {
    position: "fixed",
    width: "100%",
    height: "100%",
    top: 0,
    backgroundImage: "linear-gradient(45deg,#003756 30%,#000000 80%)",
    zIndex: -200,
  },
}));

const SignUp = () => {
  const history = useNavigate();
  const {classes} = useStyles();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [company, setCompany] = useState("");

  const [invite, setInvite] = useState(null);
  const [isInvite, setIsInvite] = useState(false);

  const [emailSent, setEmailSent] = useState(false);

  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);

  let location = useLocation();

  useEffect(() => {
    let values = queryString.parse(location.search);

    if (auth.currentUser !== undefined && values.invitetoken !== undefined) {
      setLoading(true);
      getInvite(values.invitetoken).then((invite) => {
        if (invite !== undefined) {
          setCompany(invite.company.name);
          setUsername(invite.email);
          setInvite(invite);
          setIsInvite(true);
        }
        setLoading(false);
      });
    }
  }, [setLoading, setCompany, setSuccess, setIsInvite, location.search]);

  const clearState = () => {
    setError("");
    setSuccess("");
  };

  function handleSubmit(event) {
    setLoading(true);
    clearState();
    event.preventDefault();

    if (!emailSent) {
      if (isInvite) {
        //Org already exist, user needs to Join existing Org
        if (invite.email !== username) {
          setError({ message: "Not allowed to this Company" });
          setLoading(false);
          return;
        }
      }
      handleSignUp();
    } else {
      history.push("/");
    }
  }

  function handleSignUp() {
    signUp({ email: username, password: password })
      .then((creds) => {
        if (isInvite) {
          addUserToCompanyById(invite.company.uid, auth.currentUser.uid)
            .then((result) => {
              deleteInvite(invite.uid).then(() => {
                if (creds.user.emailVerified) {
                  return <Navigate to="/" />;
                } else {
                  setSuccess("Check your email to verify your account");
                  setEmailSent(true);
                  setLoading(false);
                }
              });
            })
            .catch((err) => {
              console.log(err);
              setError(err);
              setLoading(false);
            });
        } else {
          addCompany({ name: company }, auth.currentUser.uid)
            .then((org) => {
              if (creds.user.emailVerified) {
                return <Navigate to="/" />;
              } else {
                setSuccess("Check your email to verify your account");
                setEmailSent(true);
                setLoading(false);
              }
            })
            .catch((err) => {
              setLoading(false);
              setError(err);
              console.log("Failed to create new Org: " + err);
            });
        }
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
  }

  function canSignUp() {
    return (
      username !== "" && password !== "" && company !== "" && company.length > 3
    );
  }

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <Paper className={classes.paper} elevation={6}>
      <div className={classes.background}></div>
      <Logo className={classes.logo} />
      <div className={classes.container}>
        <Typography style={{ color: "#003756" }} component="h1" variant="h5">
          Sign Up
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit} noValidate>
          <ErrorIndicator message={error.message} />
          {emailSent ? (
            <SuccessIndicator message={success} />
          ) : (
            <>
              <TextField
                value={company}
                disabled={isInvite}
                onInput={(e) => setCompany(e.target.value)}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="company"
                label="Company Name"
                name="company"
                autoComplete="company"
              />
              <TextField
                value={username}
                disabled={isInvite}
                onInput={(e) => setUsername(e.target.value)}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="username"
                label="E-mail"
                name="username"
                autoComplete="email"
                autoFocus
              />
              <TextField
                value={password}
                onInput={(e) => setPassword(e.target.value)}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              <Button
                disabled={!canSignUp()}
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                {emailSent ? "Continue" : "Sign Up"}
              </Button>
            </>
          )}

          <Grid className={classes.grid} container>
            <Grid item xs={12}>
              Already have an account? <Link to="/signin">Sign in</Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Paper>
  );
};

export default SignUp;
