import { db, analytics } from "./firestore";
import firebase from 'firebase/compat/app';
import { sendInviteEmail } from "./emailService";

export const inviteUser = (companyId, email) => {
  var invitation = {
    email: email,
    company: db.doc("companies/" + companyId),
    sent: firebase.firestore.FieldValue.serverTimestamp(),
    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
  };

  analytics.logEvent("invite_user", { email: email });

  return db
    .collection("user-invites")
    .add(invitation)
    .then((invite) => {
      sendInviteEmail(email, invite.id);
    });
};

export const resendInviteEmail = async (inviteUid) => {
  var invitation = await getInvite(inviteUid);

  const companyDocRef = firebase
    .firestore()
    .collection("companies")
    .doc(invitation.company.uid);

  invitation.sent = firebase.firestore.FieldValue.serverTimestamp();
  invitation.company = companyDocRef;
  analytics.logEvent("reinvite_user", { email: invitation.email });

  return db
    .collection("user-invites")
    .doc(inviteUid)
    .set(invitation, { merge: true })
    .then((invite) => {
      sendInviteEmail(invitation.email, inviteUid);
    });
};

export const getInvite = (inviteUid) => {
  return db
    .collection("user-invites")
    .doc(inviteUid)
    .get()
    .then((invite) => {
      var data = invite.data();

      if (data === undefined) return;
      return data.company.get().then((org) => {
        return {
          uid: invite.id,
          email: data.email,
          company: { ...org.data(), uid: org.id },
          createdAt: data.createdAt.toDate(),
          sent: data.sent !== undefined ? data.sent.toDate() : "",
        };
      });
    });
};

export const deleteInvite = (inviteUid) => {
  return db.collection("user-invites").doc(inviteUid).delete();
};

export const checkInviteExist = (companyId, email) => {
  const companyDocRef = firebase
    .firestore()
    .collection("companies")
    .doc(companyId);

  return db
    .collection("user-invites")
    .where("company", "==", companyDocRef)
    .where("email", "==", email)
    .get()
    .then((invites) => {
      return invites.docs.length > 0;
    });
};

export const getAllInvites = (companyId) => {
  const companyDocRef = firebase
    .firestore()
    .collection("companies")
    .doc(companyId);

  return db
    .collection("user-invites")
    .where("company", "==", companyDocRef)
    .get()
    .then((snapshot) => {
      return snapshot.docs.map((invite) => {
        var data = invite.data();
        return {
          uid: invite.id,
          email: data.email,
          createdAt: data.createdAt.toDate(),
          sent: data.sent !== undefined ? data.sent.toDate() : "",
        };
      });
    });
};
