import React, { useEffect, useState } from "react";

import { Grid, Paper, Typography } from "@mui/material";
import moment from "moment";
import { useRecoilState } from "recoil";
import { makeStyles } from "tss-react/mui";
import { getTaskStatusesForMonth } from "../../services/chartsService";
import { claimsState } from "../../state/claimsState";
import MonthSelector from "../MonthSelector";
import ClientTaskChart from "./ClientCostChart";
import LastUpdatedTasks from "./LastUpdatedTasks";
import TaskStatusChart from "./TaskStatusChart";
import TasksPerAssignedChart from "./TasksPerAssignedChart";

const useStyles = makeStyles()((theme) => ({
  root: {
    flexGrow: 1,
    padding: "0px 10px 0px 10px",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  typography: {
    color: "grey",
  },
}));

export default function DashboardView() {
  const {classes} = useStyles();
  const [currentDate, setCurrentDate] = useState(new Date());
  const [claims] = useRecoilState(claimsState);
  const [summary, setSummary] = useState("");

  function ondateChange(date) {
    setCurrentDate(date);
  }

  useEffect(() => {
    var todaysdate = moment(currentDate);
    if (todaysdate.format("MMMM") === moment().format("MMMM")) {
      getTaskStatusesForMonth(claims.companyId, currentDate).then((data) => {
        let summary =
          "{perc}% of Tasks have been completed for the month of {month} with {days} days remaining until month-end.";

        let perc = (data[2] / (data[0] + data[1] + data[2])) * 100;

        var endOfMonth = moment(
          new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)
        );

        var daysRemaining = endOfMonth.diff(todaysdate, "days");

        summary = summary.replace("{perc}", perc.toFixed(0));
        summary = summary.replace("{month}", todaysdate.format("MMMM"));
        summary = summary.replace("{days}", daysRemaining);
        setSummary(summary);
      });
    }
  }, [claims.companyId, currentDate]);

  return (
    <div>
      <MonthSelector date={currentDate} dateChanged={ondateChange} />

      <Grid
        container
        justifyContent="center"
        spacing={2}
        className={classes.root}
      >
        <Grid item xs={7}>
          <Typography className={classes.typography} component="h4">
            {summary}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Paper s elevation={3} className={classes.paper}>
            <TaskStatusChart date={currentDate} />
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper elevation={3} className={classes.paper}>
            <ClientTaskChart date={currentDate} />
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper elevation={3} className={classes.paper}>
            <TasksPerAssignedChart date={currentDate} />
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper elevation={3} className={classes.paper}>
            <LastUpdatedTasks />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
