import React, { useEffect, useState, useRef } from "react";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement } from "chart.js";
import LoadingIndicator from "../LoadingIndicator";
import { Typography } from "@mui/material";
import moment from "moment";
import { claimsState } from "../../state/claimsState";
import { useRecoilState } from "recoil";
import { getTaskStatusesPerEmployee } from "../../services/chartsService";
import { useNavigate } from "react-router-dom";

export default function TasksPerAssignedChart(props) {
  const { date } = props;
  const monthName = moment(date).format("MMMM");
  const [claims] = useRecoilState(claimsState);
  const [isLoading, setIsloading] = useState(false);
  const history = useNavigate();

  const [chartLabels, setChartLabels] = useState([]);
  const [notStarted, setNotStarted] = useState([]);
  const [inProgress, setInProgress] = useState([]);
  const [completed, setCompleted] = useState([]);

  const mountedRef = useRef(false);

  useEffect(() => {
    setIsloading(true);
    mountedRef.current = true;
    var firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    getTaskStatusesPerEmployee(
      claims.companyId,
      firstDayOfMonth,
      lastDayOfMonth
    ).then((data) => {
      if (!mountedRef.current) return null;
      setIsloading(false);
      setNotStarted(data.notStarted);
      setInProgress(data.inProgress);
      setCompleted(data.completed);
      setChartLabels(data.labels);
    });
    return () => {
      mountedRef.current = false;
    };
  }, [claims.companyId, date]);

  let onClientClick = (elems) => {
    let client = data.labels[elems[0]._index];

    let filter = {
      filter: { field: "assigned", value: client, display: client },
    };

    history.push("/tasklist", filter);
  };

  var data = {
    labels: chartLabels,
    datasets: [
      {
        label: "Not Started",
        data: notStarted,
        backgroundColor: "#f44336",
      },
      {
        label: "In Progress",
        data: inProgress,
        backgroundColor: "#ff9800",
      },
      {
        label: "Completed",
        data: completed,
        backgroundColor: "#4caf50",
      },
    ],
  };

  var options = {
    responsive: true,
   //maintainAspectRatio: true,
    legend: {
      display: false,
    },
    scales: {
      // yAxes: [
      //   {
      //     ticks: {
      //       beginAtZero: true,
      //     },
      //   },
      // ],
    },
  };

  if (isLoading) {
    return <LoadingIndicator />;
  }

  ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement);

  return (
    <div>
      <Typography variant="h6">
        Tasks Status per Assigned For {monthName}
      </Typography>
      <Bar data={data} options={options} onElementsClick={onClientClick} />
    </div>
  );
}
