import {
  Button,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { makeStyles } from "tss-react/mui";
import { getCategories } from "../../services/lookupService";
import DateDropdown from "../DateDropdown";
import Dropdown from "../Dropdown";

const useStyles = makeStyles()((theme) => ({
  root: {
    "& .MuiButton-root": {
      margin: theme.spacing(1),
      width: 200,
    },
    "& .MuiFormControl-root": {
      margin: theme.spacing(1),
      width: 200,
    },
  },
  typography: {
    margin: "5px",
  },
  paper: {
    padding: "5px",
  },
  productName: {
    width: "415px !important;",
  },
}));

export default function ProductEditor(props) {
  const { onSave, onCancel, title, product } = props;
  const {classes} = useStyles();
  const [categories] = useState(getCategories());

  const [name, setName] = useState(product !== undefined ? product.name : "");
  const [category, setCategory] = useState(
    product !== undefined ? product.category : "Accounting"
  );
  const [price, setPrice] = useState(product !== undefined ? product.price : 0);
  const [recurring, setRecurring] = useState(
    product !== undefined ? product.recurring : "yearly"
  );
  const [startDate, setStartDate] = useState(
    product !== undefined ? product.startDate : 1
  );

  const recurringOptions = [
    "weekly",
    "monthly",
    "yearly",
    "bi-monthly",
    "bi-yearly",
    "quarterly",
  ];

  function handleClick() {
    onSave({
      uid: product !== undefined ? product.uid : undefined,
      name: name,
      price: price,
      category: category,
      recurring: recurring,
      startDate: startDate,
    });
  }

  let canCreateProduct = () => {
    return name.length >= 3 && price >= 0;
  };

  return (
    <Paper className={classes.paper} elevation={6}>
      <form className={classes.root} noValidate autoComplete="off">
        <Typography className={classes.typography} component="h1" variant="h6">
          {title}
        </Typography>
        <div>
          <TextField
            className={classes.productName}
            id="package-name"
            error={name.length < 3}
            value={name}
            required
            size="small"
            onChange={(e) => setName(e.target.value)}
            label="Name"
            variant="outlined"
          />
        </div>
        <div>
          <Dropdown
            value={category}
            items={categories}
            required
            size="small"
            onChange={(value) => setCategory(value)}
            label="Category"
            variant="outlined"
          />
          <TextField
            id="package-price"
            type="number"
            value={price}
            InputProps={{
              inputProps: { min: 0, step: 100 },
              startAdornment: (
                <InputAdornment position="start">R</InputAdornment>
              ),
            }}
            required
            size="small"
            onChange={(e) => setPrice(e.target.value)}
            label="Price"
            variant="outlined"
          />
        </div>
        <div>
          <Dropdown
            value={recurring}
            items={recurringOptions}
            required
            size="small"
            onChange={(value) => setRecurring(value)}
            label="Recurring"
            variant="outlined"
          />

          <DateDropdown
            value={startDate}
            disabled={recurring === "monthly" || recurring === "weekly"}
            required
            size="small"
            onChange={(value) => setStartDate(value)}
            label="Due Month"
            variant="outlined"
          />
        </div>
        <div>
          <Button fullWidth variant="contained" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            fullWidth
            disabled={!canCreateProduct()}
            variant="contained"
            color="primary"
            onClick={() => handleClick()}
          >
            Save
          </Button>
        </div>
      </form>
    </Paper>
  );
}
