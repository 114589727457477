import React from "react";
import ContentHeader from "../../components/ContentHeader";
import ClientsView from "../../components/Clients/ClientsView";

export default function Clients() {
  return (
    <div>
      <ContentHeader title="Clients" />
      <ClientsView />
    </div>
  );
}
