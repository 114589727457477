let subscriptionMap = new Map();

export let getFromCache = async (key, query, snapshotHandler) => {
  let snapshot = await subscribeToChanges(key, query);

  return snapshotHandler(snapshot);
};

let subscribeToChanges = async (key, query) => {
  if (!subscriptionMap.has(key)) {
    query.onSnapshot((snapshot) => {
      console.log("Change in " + key + " detected");
      if (!snapshot.metadata.hasPendingWrites) query.get();
      subscriptionMap.set(key, snapshot);
    });

    return query.get();
  } else {
    return query.get({ source: "cache" });
  }
};
