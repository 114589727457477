import React, { useEffect, useState, useRef } from "react";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import LoadingIndicator from "../LoadingIndicator";
import { Typography } from "@mui/material";
import moment from "moment";
import { claimsState } from "../../state/claimsState";
import { useRecoilState } from "recoil";
import { getTopClientsForMonth } from "../../services/chartsService";
import { useNavigate } from "react-router-dom";

export default function ClientCostChart(props) {
  const { date } = props;
  const monthName = moment(date).format("MMMM");
  const [claims] = useRecoilState(claimsState);
  const [isLoading, setIsloading] = useState(false);
  const [actualCost, setActualCost] = useState([]);
  const [packageCost, setPackageCost] = useState([]);
  const [chartLabels, setChartLabels] = useState([]);
  const [chartColours, setChartColours] = useState([]);
  const history = useNavigate();

  const mountedRef = useRef(false);

  useEffect(() => {
    mountedRef.current = true;
    setIsloading(true);
    var firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    getTopClientsForMonth(
      claims.companyId,
      firstDayOfMonth,
      lastDayOfMonth
    ).then((data) => {
      if (!mountedRef.current) return null;
      setIsloading(false);
      setActualCost(data.actualCost);
      setPackageCost(data.packageCost);
      setChartLabels(data.labels);
      setChartColours(data.colours);
    });

    return () => {
      mountedRef.current = false;
    };
  }, [claims.companyId, date]);

  let onClientClick = (elems) => {
    let client = data.labels[elems[0]._index];

    let filter = {
      filter: { field: "clientName", value: client, display: client },
    };
    history.push("/timesheet", filter);
  };

  var data = {
    labels: chartLabels,
    datasets: [
      {
        label: "Actual Cost",
        data: actualCost,
        backgroundColor: chartColours,
        borderColor: chartColours,
        borderWidth: 2,
      },
      {
        label: "Package Cost",
        data: packageCost,
        backgroundColor: "lightgray",
        borderColor: "lightgray",
        borderWidth: 2,
      },
    ],
  };

  var options = {
    responsive: true,
    //maintainAspectRatio: true,
    legend: {
      display: false,
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            callback: function (label, index, labels) {
              return "R" + label;
            },
          },
        },
      ],
    },
  };

  if (isLoading) {
    return <LoadingIndicator />;
  }

  ChartJS.register(ArcElement, Tooltip, Legend);

  return (
    <div>
      <Typography variant="h6">
        Top 10 Most Expensive Clients For {monthName}
      </Typography>
      <Bar data={data} options={options} onElementsClick={onClientClick} />
    </div>
  );
}
