import React, { useState, useEffect } from "react";
import { TextField } from "@mui/material";
import moment from 'moment';
import { auth } from '../../services/firestore';
import StandardTable from "../StandardTable";

export default function TasklistTimeEntryManager(props) {
  const { timeEntries, onDelete, onUpdate } = props;
  const [entries, setEntries] = useState([]);

  useEffect(() =>{
    if(timeEntries !== undefined)
    {
      setEntries(timeEntries.filter((entry) => { return entry.endTime !== undefined && entry.recordedBy.id === auth.currentUser.uid; }));
    }
  }, [timeEntries])

  let deleteEntry = (data) => {
    
    let entriesToDelete = data.map((entry, index) => {
      return timeEntries.find((e) => e.id === entry.id);
    });

    let tempEntries = timeEntries;
    entriesToDelete.forEach(entry => {
      onDelete(entry);
      tempEntries = timeEntries.filter((e) => e.id !== entry.id);
    });

    setEntries(tempEntries);

  };

  let updateEntryDate = (entry, value) => {
    let date = moment(value);
    let startTime = moment(entry.startTime);
    let endTime = moment(entry.endTime);

    startTime.date(date.date());
    endTime.date(date.date());

    entry.startTime = startTime.toDate();
    entry.endTime = endTime.toDate();
    entry.isDirty = true;
    onUpdate(entry);
  }

  if (entries === undefined || entries.length === 0) {
    return (<></>);
  }

  let columns = [
    {
      id: "endTime",
      Header: "Date",
      accessor: "endTime",
      Cell: ({ cell }) => (
        <TextField
          id="date"
          type="date"
          defaultValue={moment(cell.row.values.endTime).format("YYYY-MM-DD")}
          onChange={(e) => updateEntryDate(cell.row.original, e.target.value)}
          InputProps={{ disableUnderline: true }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      ),
    },
    {
      id: "duration",
      Header: "Duration",
      accessor: "duration",
      Cell: ({ cell }) => (
        <div>{cell.row.values.duration !== undefined ? new Date(cell.row.values.duration).toISOString().substr(11, 8) : ''}</div>
      ),
    },
    
   
  ];

  return ( <StandardTable
    data={entries}
    columns={columns}
    title="Time Entries"
    canEdit={false}
    canAdd={false}
    canDelete={true}
    deleteUserHandler ={deleteEntry}
  />);
}
