import { Grid } from "@mui/material";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { makeStyles } from "tss-react/mui";
import ErrorIndicator from "../../components/ErrorIndicator";
import LoadingIndicator from "../../components/LoadingIndicator";
import { Logo } from "../../images/Images";
import { signIn } from "../../services/authService";
import { auth } from "../../services/firestore";

const useStyles = makeStyles()((theme) => ({
  paper: {
    width: 400,
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing(2)} ${theme.spacing(3)} ${theme.spacing(3)}`,
  },
  avatar: {
    margin: theme.spacing(1),
    width: 192,
    height: 192,
    color: theme.palette.secondary.main,
  },
  form: {
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: `100%`,
  },
  grid: {
    fontSize: "10px",
  },
  gridItem: {
    textAlign: "right",
  },
  logo: {
    width: "250px",
    paddingTop: 40,
    paddingBottom: "20px",
  },
  background: {
    position: "fixed",
    width: "100%",
    height: "100%",
    top: 0,
    backgroundImage: "linear-gradient(45deg,#003756 30%,#000000 80%)",
    zIndex: -200,
  },
  hero: {
    position: "fixed",
    //width: '50%',
    //height: '50%',
    top: "7%",
    left: "55%",
    backgroundColor: "#f2920a",
    zIndex: -100,
  },
}));

const SignIn = () => {
  const { classes } = useStyles();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const clearState = () => {
    setError("");
  };

  function handleSubmit(event) {
    event.preventDefault();
    clearState();
    authenticate();
  }

  const authenticate = async () => {
    setLoading(true);

    await signIn({ email: username, password: password })
      .then((creds) => {
        if (!creds.user.emailVerified) {
          setError({ message: "Your email has not been verified" });
          creds.user.sendEmailVerification();
        }
      })
      .catch((err) => {
        setError(err);
      });

    setLoading(false);
  };

  if (auth.currentUser && auth.currentUser.emailVerified) {
    return <Navigate to="/" />;
  }

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <Paper className={classes.paper} elevation={6}>
      <div className={classes.background}></div>
      <Logo className={classes.logo} />
      <div className={classes.container}>
        <Typography style={{ color: "#003756" }} component="h1" variant="h5">
          Sign In
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit} noValidate>
          <ErrorIndicator message={error.message} />
          <TextField
            value={username}
            onInput={(e) => setUsername(e.target.value)}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="E-mail"
            name="username"
            autoComplete="username"
            autoFocus
          />
          <TextField
            value={password}
            onInput={(e) => setPassword(e.target.value)}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Sign In
          </Button>
          <Grid className={classes.grid} container>
            <Grid item xs={6}>
              <Link to="/forgotpassword">Forgot your password?</Link>
            </Grid>
            <Grid item xs={6} className={classes.gridItem}>
              Don't have an account? <Link to="/signup">Sign up</Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Paper>
  );
};

export default SignIn;
