import React, { useEffect, useState, useRef } from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import LoadingIndicator from "../LoadingIndicator";
import { Typography } from "@mui/material";
import moment from "moment";
import { claimsState } from "../../state/claimsState";
import { useRecoilState } from "recoil";
import { getTaskStatusesForMonth } from "../../services/chartsService";
import { useNavigate } from "react-router-dom";

export default function TaskStatusChart(props) {
  const { date } = props;
  const monthName = moment(date).format("MMMM");
  const [claims] = useRecoilState(claimsState);
  const [isLoading, setIsloading] = useState(false);
  const [chartData, setChartData] = useState([]);
  const history = useNavigate();

  const mountedRef = useRef(false);

  useEffect(() => {
    setIsloading(true);
    mountedRef.current = true;
    getTaskStatusesForMonth(claims.companyId, date).then((data) => {
      if (!mountedRef.current) return null;
      setIsloading(false);
      setChartData(data);
    });

    return () => {
      mountedRef.current = false;
    };
  }, [claims.companyId, date]);

  let onStatusClick = (elems) => {
    let statuses = ["notstarted", "inprogress", "completed"];
    let status = statuses[elems[0]._index];
    let statusDisplay = data.labels[elems[0]._index];

    let filter = {
      filter: { field: "status", value: status, display: statusDisplay },
    };
    history.push("/tasklist", filter);
  };

  var data = {
    labels: ["Not Started", "In Progress", "Completed"],
    datasets: [
      {
        data: chartData,
        backgroundColor: ["#f44336", "#ff9800", "#4caf50"],
        hoverBackgroundColor: ["#f44336", "#ff9800", "#4caf50"],
      },
    ],
  };

  var options = {
   // responsive: true,
    maintainAspectRatio: false,
    legend: {
      position: "bottom",
    },
  };

  if (isLoading) {
    return <LoadingIndicator />;
  }

  ChartJS.register(ArcElement, Tooltip, Legend);
  
  return (
    <div style={{position: "relative", height: "40vh", widows: "80vh", marginBottom: "5%"}}>
      <Typography variant="h6">Task Statuses For {monthName}</Typography>
      <Doughnut data={data} options={options} onElementsClick={onStatusClick} />
    </div>
  );
}
