import React from "react";
import ContentHeader from "../../components/ContentHeader";
import DashboardView from "../../components/Dashboard/DashboardView";

export default function Dashboard() {
  return (
    <div>
      <ContentHeader title="Dashboard" />
      <DashboardView />
    </div>
  );
}
