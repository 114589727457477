import React from 'react'
import ContentHeader from "../../components/ContentHeader";
import CostingsView from '../../components/Costings/CostingsView';

export default function Costings() {


    return (
        <div>
            <ContentHeader title="Costings" />
            <CostingsView/>
        </div>
    );
}