import { Button, Grid, Typography } from "@mui/material";
import { green } from "@mui/material/colors";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { makeStyles } from "tss-react/mui";
import { toCurrency } from "../../helpers/helpers";
import { getCostings } from "../../services/costingsService";
import { claimsState } from "../../state/claimsState";
import LoadingIndicator from "../LoadingIndicator";
import MonthSelector from "../MonthSelector";
import StandardTable from "../StandardTable";

const useStyles = makeStyles()((theme) => ({
  cellRed: {
    color: "#f44336",
    fontWeight: "bold",
  },
  cellOrange: {
    color: "#ff9800",
    fontWeight: "bold",
  },
  cellGreen: {
    color: green[600],
    fontWeight: "bold",
  },
  clientButton: {
    padding: 2,
    fontWeight: "bold",
  },
}));

export default function CostingsView() {
  const {classes} = useStyles();
  const navigate = useNavigate();

  let currrentDate = new Date();
  var firstDayOfMonth = new Date(
    currrentDate.getFullYear(),
    currrentDate.getMonth(),
    1
  );
  var lastDayOfMonth = new Date(
    currrentDate.getFullYear(),
    currrentDate.getMonth() + 1,
    0
  );

  const [fromDate, setFromDate] = useState(firstDayOfMonth);
  const [toDate, setToDate] = useState(lastDayOfMonth);

  const [isLoading, setIsloading] = useState(false);
  const [costings, setCostings] = useState([]);
  const [claims] = useRecoilState(claimsState);

  useEffect(() => {
    setIsloading(true);
    console.log("Getting Costings");
    getCostings(claims.companyId, fromDate, toDate).then((costings) => {
      setCostings(costings);
      setIsloading(false);
    });
  }, [claims.companyId, fromDate, toDate]);

  function ondateChange(date) {
    var firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    setFromDate(firstDayOfMonth);
    setToDate(lastDayOfMonth);
  }

  const sum = function (items, prop) {
    return items.reduce(function (a, b) {
      if (b[prop] !== undefined) return a + b[prop];
      else return a;
    }, 0);
  };

  let checkForOrange = (cost, price) => {
    let perc = 100 - (cost / price) * 100;

    return perc <= 10;
  };

  let onClientClick = (client) => {
    let filter = {
      filter: { field: "clientName", value: client, display: client },
    };
    navigate("/timesheet", { state: filter });
  };

  if (isLoading) {
    return <LoadingIndicator />;
  }

  let secondsToHms = (d) => {
    d = Number(d);
    var h = ("0" + Math.floor(d / 3600)).slice(-2);
    var m = ("0" + Math.floor((d % 3600) / 60)).slice(-2);
    var s = ("0" + Math.floor((d % 3600) % 60)).slice(-2);

    return h + ":" + m + ":" + s;
  };

  const columns = [
    {
      Header: "Client",
      accessor: "client", // accessor is the "key" in the data
      Cell: ({ cell }) => (
        <Button
          className={classes.clientButton}
          onClick={() => {
            onClientClick(cell.row.values.client);
          }}
        >
          {cell.row.values.client}
        </Button>
      ),
    },
    {
      Header: "Package",
      accessor: "packageName",
    },
    {
      Header: "Price",
      accessor: "packagePrice",
      Cell: ({ cell }) => "R " + cell.row.values.packagePrice.toFixed(2),
    },
    {
      Header: "Total Time",
      accessor: "totalTime",
      Cell: ({ cell }) => secondsToHms(cell.row.values.totalTime),
    },
    {
      Header: "Running Costs",
      accessor: "totalCost",
      Cell: ({ cell }) => (
        <span
          className={
            cell.row.values.totalCost > cell.row.values.packagePrice
              ? classes.cellRed
              : checkForOrange(
                  cell.row.values.totalCost,
                  cell.row.values.packagePrice
                )
              ? classes.cellOrange
              : classes.cellGreen
          }
        >
          {toCurrency(cell.row.values.totalCost)}
        </span>
      ),
    },
  ];

  return (
    <React.Fragment>
      <MonthSelector date={fromDate} dateChanged={ondateChange} />
      <StandardTable
        data={costings}
        columns={columns}
        addUserHandler={() => {}}
        title=""
      />
      <Grid container justifyContent="flex-end">
        <Grid item xs={3}>
          <Typography variant="h6">
            {" "}
            Total: {toCurrency(sum(costings, "totalCost"))}
          </Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
